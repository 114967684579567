import styled from 'styled-components';

export const Quantity = styled.div`
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 90px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 2px 5px;
  height: 30px;
  background: #fff;
`;
export const ButtonControl = styled.button`
  background-color: #fff;
  border: none;
  width: 20px;
  height: 20px;
`;
export const InputQuantity = styled.input`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border: none;
  text-align: center;
  width: 100%;
`;

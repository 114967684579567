import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  display: block;
  width: 360px;
  border-right: 1px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  transition: width 0.2s ease;

  height: 100%;

  @media (max-width: 500px) {
    position: absolute;
    width: ${props => (props.open ? '100%' : '0%')};
    max-width: 500px;
    left: 0;
    top: 0;
    gap: 0;
    padding: 0;
    background: #fff;
    z-index: 110;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  @media (max-width: 500px) {
    padding: 16px;
  }
`;
export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #888;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    background-color: #f5f5f5;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  color: #888;

  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 12px;
    color: #fff;
  }
`;
export const CategoryTitle = styled.h3``;
export const CloseButton = styled.button`
  display: none;

  @media (max-width: 500px) {
    display: block;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const FiltersTitle = styled.h3`
  color: #888;
  @media (max-width: 500px) {
    display: none;
  }
`;

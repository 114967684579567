/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';

import { saveCache } from '~/store/modules/pages/actions';
import colors from '~/styles/colors';

import HeaderPage from '~/easy-components/HeaderPage';
import MenuButton from '~/easy-components/MenuButton';
import Title from '~/easy-components/Title';
import Loading from '~/easy-components/Loading';
import HelpButton from '~/applications/CRM/components/HelpButton';
import Page from '~/components/Page';

function SimplePage(
  {
    settings,
    route,
    children,
    isLoading,
    isHeaderPage = true,
    isSideOpen,
    sideWidth,
    title,
    menus,
    side,
    onSideOpen,
    onLoadData,
    onGetDataToSave,
    auxScope,
    helpUrl,
  },
  ref
) {
  const dispatch = useDispatch();
  const [simplePageTitle, setSimplePageTitle] = useState();

  const cachedData = useSelector(redux => {
    return (
      redux.pages.find(
        page =>
          (page.pageId || '').toUpperCase() === (route || '').toUpperCase()
      ) || {}
    ).data;
  });

  useEffect(() => {
    if (onLoadData) {
      if (cachedData) {
        onLoadData(cachedData);
      } else {
        onLoadData();
      }
    }

    return async () => {
      let dataToCache = null;

      if (onGetDataToSave) {
        dataToCache = await onGetDataToSave();
      }

      dispatch(
        saveCache({
          pageId: route,
          data: {
            ...dataToCache,
          },
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => {
    return {
      setTitle: component => {
        if (component) {
          setSimplePageTitle(component);
        }
      },
      title,
    };
  });

  return (
    <>
      <Loading isLoading={isLoading} />
      <Page
        route={route}
        isSidePageOpen={isSideOpen}
        showSide={onSideOpen}
        sideWidth={sideWidth}
        sidePage={side}
      >
        <>
          {isHeaderPage && (
            <HeaderPage
              backgroundColor={colors.headerBg}
              color={colors.headerTitle}
            >
              <Title>{simplePageTitle || title}</Title>

              {menus && menus.length > 0 && (
                <MenuButton
                  menus={menus}
                  settings={settings}
                  auxScope={auxScope}
                  icon={FaEllipsisV}
                  color={colors.headerTitle}
                  size={16}
                  position="bottom right"
                />
              )}
              <HelpButton
                url={settings && settings.helpUrl}
                customUrl={helpUrl}
              />
            </HeaderPage>
          )}
          {children}
        </>
      </Page>
    </>
  );
}

const SimplePageComponent = forwardRef(SimplePage);

SimplePageComponent.propTypes = {
  route: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isSideOpen: PropTypes.bool,
  sideWidth: PropTypes.number,
  onSideOpen: PropTypes.func,
  side: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SimplePageComponent.defaultProps = {
  isLoading: false,
  sideWidth: null,
  isSideOpen: false,
  onSideOpen: null,
  title: null,
  side: null,
};

export default SimplePageComponent;

import { useEffect, useRef, useState } from 'react';

function useStateCache(defaultValue) {
  const valueRef = useRef(defaultValue);

  const componentIsMounted = useRef(true);

  const [, setState] = useState(defaultValue);

  const set = value => {
    if (!componentIsMounted.current) {
      return;
    }

    let newValue = value;

    if (typeof value === 'function') {
      newValue = value(valueRef.current);
    }

    valueRef.current = newValue;

    setState(newValue);
  };

  const get = () => {
    return valueRef.current;
  };

  useEffect(() => {
    componentIsMounted.current = true;

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return [valueRef.current, set, get];
}

export default useStateCache;

/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';

import { FaPlusCircle as AddIcon } from '@react-icons/all-files/fa/FaPlusCircle';

import Modal from '~/easy-components/Modal';
import MobileBoards from '../MobileBoards';

import { Container, Content, Bar, Header, Actions } from './styles';

// eslint-disable-next-line react/prop-types
function Board({
  title,
  mobileTitle,
  width,
  percent,
  onDrop,
  boards,
  renderTitle,
  isBoardTemplate,
  enableAdd,
  actions,
  children,
  propPercent,
  setSelectedKey,
  propBoardKey,
  isMobile,
  board,
  onAdd = () => {},
}) {
  const boardRef = useRef();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onDragOver = useCallback(e => {
    e.preventDefault();
    e.currentTarget.classList.add('board-enter');
  }, []);

  const onDragLeave = useCallback(e => {
    e.preventDefault();
    e.currentTarget.classList.remove('board-enter');
  }, []);

  const handlerDrop = useCallback(
    e => {
      const elementDragged = document.querySelector('.is-dragging');

      e.currentTarget.classList.remove('board-enter');

      onDrop({
        dropzone: e.currentTarget,
        elementDragged,
      });
    },
    [onDrop]
  );

  return (
    <Container
      ref={boardRef}
      width={width}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handlerDrop}
    >
      <Header
        onClick={() => {
          if (isMobile) setIsOpenMenu(true);
        }}
      >
        <section>
          {isBoardTemplate ? (
            renderTitle(title.props.data)
          ) : (
            <>
              <div>{title}</div>
              {percent && <Bar percent={percent} />}
            </>
          )}
        </section>
        <MdKeyboardArrowRight size={22} color="#333" />
      </Header>
      {enableAdd && (
        <Actions>
          <AddIcon
            size={24}
            color="#75b575"
            onClick={() => {
              onAdd({ board });
            }}
          />
        </Actions>
      )}
      {actions}
      <Content className="content">{children}</Content>
      <Modal isOpened={isOpenMenu} direction="right">
        <MobileBoards
          propPercent={propPercent}
          mobileTitle={mobileTitle}
          boards={boards}
          renderTitle={renderTitle}
          onClose={() => setIsOpenMenu(false)}
          setSelectedKey={setSelectedKey}
          propBoardKey={propBoardKey}
        />
      </Modal>
    </Container>
  );
}

Board.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.number,
  percent: PropTypes.number,
  // children: PropTypes.oneOf([PropTypes.element, PropTypes.array]).isRequired,
  onDrop: PropTypes.func,
};

Board.defaultProps = {
  title: null,
  width: 280,
  percent: null,
  onDrop: () => {},
};

export default Board;

import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import { IoFilterSharp } from 'react-icons/io5';
import { Container } from './styles';
import { ModalCatalogContext } from '../../context';

function ButtonFilters() {
  const { openFilters, setOpenFilters } = useContext(ModalCatalogContext);

  function handleOpenFilters() {
    setOpenFilters(!openFilters);
  }
  return (
    <Container onClick={handleOpenFilters}>
      <IoFilterSharp size={24} />
    </Container>
  );
}

export default ButtonFilters;

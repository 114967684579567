import React from 'react';

import Icon from '~/easy-components/Icon';

import { Container, Text } from './styles';

function FinishPage({ message }) {
  return (
    <Container>
      <Icon name="AiFillCheckCircle" size={48} />
      <Text>{message}</Text>
    </Container>
  );
}

export default FinishPage;

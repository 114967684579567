/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-await */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useMemo } from 'react';
import { FaEllipsisV as MenuIcon } from '@react-icons/all-files/fa/FaEllipsisV';
import { GoInfo as InfoIcon } from '@react-icons/all-files/go/GoInfo';
import { FiMaximize2 as ExpandIcon } from '@react-icons/all-files/fi/FiMaximize2';
import { FiMinimize as MinimizeIcon } from '@react-icons/all-files/fi/FiMinimize';

import PageFooter from '~/easy-components/ModalFooter';
import MenuButton from '~/easy-components/MenuButton';
import ModalHandle from '~/easy-components/ModalHandle';
import CompareRender from '~/easy-components/Helpers/compareRender';
import JsonParseFunction from '~/easy-components/Helpers/JsonParse';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import useCompany from '~/hooks/useCompany';
import ErrorBoundary from '~/easy-components/ErrorBoundary';
import { Container, ModalContainer, ModalContent } from './styles';
import Page from '../Page';

import Bar from '../Bar';
import Line from '../Line';
import Pie from '../Pie';
import Kpi from '../Kpi';
import Custom from '../Custom';
import Speedometer from '../Speedometer';
import Funnel from '../Funnel';
import Information from '../Information';
import Table from '../Table';
import Doughnut from '../Doughnut';

const systemColors = [
  '#87CEEB',
  '#66CDAA',
  '#483D8B',
  '#BA55D3',
  '#8FBC8F',
  '#008080',
  '#00FF7F',
  '#00CED1',
  '#8A2BE2',
  '#1E90FF',
];

function Widget({
  t,
  params,
  settings,
  readOnly,
  data,
  onDelete,
  onChange,
  onCtrlSave,
}) {
  const modalRef = useRef();
  const componentModalRef = useRef();
  const widgetRef = useRef();
  const { numberToString } = useCompany();
  const errorRef = useRef(null);

  const widgetSettings = useMemo(() => {
    try {
      return data.widgetSettings ? JsonParseFunction(data.widgetSettings) : {};
    } catch (e) {
      errorRef.current = e.message;
      return {};
    }
  }, [data.widgetSettings]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeEvent = async ({ eventName, run = 'before', ...rest }) => {
    if (widgetSettings.events) {
      const event = widgetSettings.events.find(
        evt =>
          evt.name.toUpperCase() === eventName.toUpperCase() &&
          (evt.run || 'before') === run
      );

      if (event) {
        const dynamicFunction = createAsyncFunctionByString({
          functionString: event.handler,
        });

        return await dynamicFunction({
          data,
          ...rest,
          ...settings.dynamicFunctionProps,
          params,
          executeSql: sql => {
            return settings.dynamicFunctionProps.executeSql(sql, params);
          },
        });
      }

      return null;
    }

    return null;
  };

  useEffect(() => {
    executeEvent({ eventName: 'onShow', run: 'after' });
  }, [data, executeEvent]);

  const getColor = index => {
    if (
      widgetSettings &&
      widgetSettings.colors &&
      widgetSettings.colors.length > index
    ) {
      return widgetSettings.colors[index];
    }

    if (systemColors[index]) {
      return systemColors[index];
    }

    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    const color = `#${randomColor}50`;
    return color;
  };

  const Component = () => {
    if (errorRef.current) {
      return <div>{errorRef.current}</div>;
    }
    switch (data.type) {
      case 'information':
        return (
          <Information
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );

      case 'custom':
        return (
          <Custom
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );

      case 'table':
        return (
          <Table
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );

      case 'bar':
        return (
          <Bar
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
            getColor={getColor}
            numberToString={numberToString}
          />
        );

      case 'line':
        return (
          <Line
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
            getColor={getColor}
            numberToString={numberToString}
            legendPosition={widgetSettings.legendPosition}
          />
        );

      case 'Pie':
        return (
          <Pie
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
            getColor={getColor}
            numberToString={numberToString}
            legendPosition={widgetSettings.legendPosition}
          />
        );

      case 'kpi':
        return (
          <Kpi
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );

      case 'speedometer':
        return (
          <Speedometer
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );

      case 'funnel':
        return (
          <Funnel
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
          />
        );
      case 'doughnut':
        return (
          <Doughnut
            ref={widgetRef}
            data={data}
            widgetSettings={widgetSettings}
            settings={settings}
            executeEvent={executeEvent}
            params={params}
            getColor={getColor}
            numberToString={numberToString}
            legendPosition={widgetSettings.legendPosition}
            showLabels={widgetSettings.showLabels}
          />
        );

      default:
        return <div />;
    }
  };

  const onShowDetail = async e => {
    e.stopPropagation();
    e.preventDefault();
    const widgetData = widgetRef.current ? widgetRef.current.getData() : null;
    await executeEvent({ eventName: 'onShowDetail', data: widgetData });
  };

  return (
    <>
      <Container>
        {(data.title || !readOnly) && (
          <header>
            <h1>{data.title}</h1>
            <div>
              {widgetSettings && widgetSettings.showDetail && (
                <InfoIcon
                  size={24}
                  color="#8abecf"
                  onMouseDown={onShowDetail}
                />
              )}
              {!readOnly && (
                <MenuButton
                  icon={MenuIcon}
                  color="#AAA"
                  position="bottom"
                  menus={[
                    {
                      icon: 'MdOutlineEdit',
                      visible: true,
                      disabled: false,
                      text: 'Editar',
                      handler: () => {
                        modalRef.current.handleOpen();
                      },
                    },
                    {
                      icon: 'BiTrash',
                      color: '#ff7777',
                      visible: true,
                      disabled: false,
                      text: 'Excluir',
                      handler: onDelete,
                    },
                  ]}
                />
              )}
              {data.type !== 'kpi' &&
                data.type !== 'custom' &&
                data.type !== 'speedometer' &&
                data.type !== 'information' && (
                  <ExpandIcon
                    size={18}
                    color="#AAA"
                    position="bottom"
                    style={{ marginLeft: '10px' }}
                    onMouseDown={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      componentModalRef.current.handleOpen();
                    }}
                  />
                )}
            </div>
          </header>
        )}
        <aside>
          <ErrorBoundary>{Component()}</ErrorBoundary>
        </aside>
      </Container>
      <ModalHandle
        ref={componentModalRef}
        contentStyle={{
          style: {
            flex: 1,
          },
        }}
        style={{
          justifyContent: 'normal',
          alignItems: 'normal',
          display: 'flex',
          flex: 1,
          height: '100%',
        }}
      >
        <ModalContainer>
          <PageFooter>
            <MinimizeIcon
              size={18}
              color="#AAA"
              position="bottom"
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onMouseDown={e => {
                e.stopPropagation();
                e.preventDefault();
                componentModalRef.current.handleClose();
              }}
            />
          </PageFooter>
          <ModalContent>{Component()}</ModalContent>
        </ModalContainer>
      </ModalHandle>
      <ModalHandle
        ref={modalRef}
        contentStyle={{
          style: {
            flex: 1,
          },
        }}
        style={{
          justifyContent: 'normal',
          alignItems: 'normal',
          display: 'flex',
          flex: 1,
          height: '100%',
        }}
      >
        <Page
          t={t}
          data={data}
          onConfirm={({ dataChanged }) => {
            onChange({ data: dataChanged });
            modalRef.current.handleClose();
          }}
          onClose={() => modalRef.current.handleClose()}
          onCtrlSave={({ dataChanged }) => {
            onChange({ data: dataChanged });
            modalRef.current.handleClose();
            onCtrlSave();
          }}
        />
      </ModalHandle>
    </>
  );
}

export default CompareRender(Widget, ['data']);

export default {
  primary: '#354a5f',
  secundary: '#0854a0',
  form: '#f9f9f9',
  green: '#89ba89',
  red: '#ff9191',
  gray: '#BBB',
  blue: '#0874a0',
  fontColor: '#666',
  background: '#e3e8ea',
  headerBg: '#496774',
  headerTitle: '#ffffff',
  fieldLabelColor: '#8197a5',
  mainMenuBackground: '#5d7a89',
  mainMenuLabel: '#9ab3bd',
  mainMenuIcons: '#d5dde1',
  mainMenuSelected: '#8197a5', // '#275e75',
  mainActiveTab: '#449dc1',
  yellow: '#e8c981',
  slate: '#E9EDEF',
};

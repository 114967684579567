import request from '../../Request';

class TaxCodeDeterminationService {
  async get({ document, line, documentType }) {
    // eslint-disable-next-line no-unused-vars
    const { DocumentLines, ...rest } = document;

    return request({
      url: `APP/CRM/TaxCodeDeterminations`,
      method: 'POST',
      largeData: {
        document: rest,
        line,
        documentType,
      },
    });
  }

  async recalculateTaxLines(document, documentType) {
    if (document.DocumentLines.length > 0)
      return request({
        url: `APP/CRM/TaxCodeDeterminations`,
        method: 'PUT',
        largeData: {
          document,
          documentType,
        },
      });

    return document.DocumentLines;
  }
}

export default new TaxCodeDeterminationService();

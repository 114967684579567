import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';
import Form from '~/easy-components/Form';

import Field from '~/easy-components/Form/UserField/Field';
import { ContainerInputs } from './styles';
import { ModalCatalogContext } from '../../context';
/**
 *
 * @typedef {Object} catalogCustomFields
 * @property {string} name
 * @property {string} label
 *
 * @param {Object} props
 * @param {catalogCustomFields[]} props.catalogCustomFields
 *
 */
function CustomForm({ catalogCustomFields, handleInputChange, item }, ref) {
  const formRef = useRef();
  const { settings } = useContext(ModalCatalogContext);
  useImperativeHandle(ref, () => {
    return {
      ...formRef.current,
    };
  });

  return (
    <Form ref={formRef} data={item}>
      <ContainerInputs>
        {catalogCustomFields.map(field => (
          <Field
            key={field.name}
            formRef={formRef}
            target={field}
            settings={settings}
            onChange={value => handleInputChange(field.name, value)}
          />
        ))}
      </ContainerInputs>
    </Form>
  );
}

export default forwardRef(CustomForm);

import React, { lazy, Suspense } from 'react';
import { Router, Switch } from 'react-router-dom';

import { Container } from './styles';
import history from './history';
import Route from './route';
import applicationRoutes from '../applications/application.routes';
import { useConnectionAssistant } from '../hooks/useConnectionAssistant';

const CompanySelection = lazy(() => import('../pages/Companies/Selection'));
const NewCompany = lazy(() => import('../pages/Companies/NewCompany'));
const EditCompany = lazy(() => import('../pages/Companies/EditCompany'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const ControlPanel = lazy(() => import('../pages/ControlPanel'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const PageNotAuthorized = lazy(() => import('../pages/PageNotAuthorized'));
const Login = lazy(() => import('../pages/Account/Login'));
const OutSign = lazy(() => import('../pages/Account/OutSign'));
const AccountRemember = lazy(() => import('../pages/Account/AccountRemember'));
const NewAccount = lazy(() => import('../pages/Account/NewAccount'));
const ChangePassword = lazy(() => import('../pages/Account/ChangePassword'));
const PasswordRecovery = lazy(() =>
  import('../pages/Account/PasswordRecovery')
);
const ExpiredPassword = lazy(() => import('../pages/Account/ExpiredPassword'));
const ResetPassword = lazy(() => import('../pages/Account/ResetPassword'));
const ResetPasswordWasNotMe = lazy(() =>
  import('../pages/Account/ResetPasswordWasNotMe')
);
const AccountCreated = lazy(() => import('../pages/Account/AccountCreated'));
const Workspace = lazy(() => import('../pages/Workspace'));
const NewCompanyUser = lazy(() => import('../pages/CompanyUser/New'));
const ApplicationGroups = lazy(() => import('../pages/ApplicationGroups'));
const DataConnector = lazy(() => import('../pages/DataConnector'));
const AccessPolicy = lazy(() => import('../pages/Account/AccessPolicy'));
const CompanyUsers = lazy(() => import('../pages/CompanyUser'));
const UserApplicationGroup = lazy(() =>
  import('../pages/UserApplicationGroup')
);
const Finance = lazy(() => import('../pages/Finance'));
const Connections = lazy(() => import('../pages/Companies/Connections'));
const Queries = lazy(() => import('../pages/Queries'));
const Applications = lazy(() => import('../pages/Applications'));
const AhsServices = lazy(() => import('../pages/AhsServices'));
const ExecuteReport = lazy(() => import('../pages/ExecuteReport'));
const Configurations = lazy(() => import('../pages/Configurations'));
const MainMenu = lazy(() => import('~/components/MainMenu'));
const AlertMessage = lazy(() => import('../pages/AlertMessage'));
const Services = lazy(() => import('../pages/Services'));
const Triggers = lazy(() => import('../pages/Triggers'));
const Resources = lazy(() => import('../pages/Resources'));
const Addons = lazy(() => import('../pages/Addons'));
const AddonsManager = lazy(() => import('../pages/AddonsManager'));
const Controllers = lazy(() => import('../pages/Controllers'));
const SapLink = lazy(() => import('../pages/__Tests/SapLink'));
const Branches = lazy(() => import('../pages/Branches'));

function Routes() {
  const { isOnline, Offline } = useConnectionAssistant();

  // TODO: Trocar os pontos por uma imagem carregando
  return (
    <Suspense fallback={<div>...</div>}>
      <Router history={history}>
        {!isOnline ? (
          Offline()
        ) : (
          <Container>
            <MainMenu />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Switch>
                <Route path="/" exact component={Login} isPrivate={false} />
                <Route
                  path="/Login/:company"
                  component={Login}
                  isPrivate={false}
                />
                <Route path="/Login" component={Login} isPrivate={false} />
                <Route
                  path="/OutSign/:authorizationKey"
                  component={OutSign}
                  isPrivate={false}
                />
                <Route
                  path="/NewAccount"
                  component={NewAccount}
                  isPrivate={false}
                />
                <Route
                  path="/AccessPolicy"
                  component={AccessPolicy}
                  isPrivate={false}
                />
                <Route path="/Finance" component={Finance} />
                <Route path="/Companies" component={CompanySelection} />
                <Route path="/Connections" component={Connections} />
                <Route path="/NewCompany" component={NewCompany} />
                <Route path="/EditCompany" component={EditCompany} />
                <Route path="/ControlPanel" component={ControlPanel} />
                <Route
                  path="/ApplicationGroups"
                  component={ApplicationGroups}
                />
                <Route path="/DataConnector" component={DataConnector} />
                <Route path="/AlertMessage" component={AlertMessage} />

                <Route path="/Queries" component={Queries} />
                <Route path="/Services" component={Services} />
                <Route path="/Resources" component={Resources} />
                <Route path="/Addons" component={Addons} />
                <Route path="/AddonsManager" component={AddonsManager} />
                <Route path="/Triggers" component={Triggers} />
                <Route path="/Applications" component={Applications} />
                <Route path="/Configurations" component={Configurations} />
                <Route path="/Branches" component={Branches} />

                <Route
                  path="/UserApplicationGroup/:id"
                  component={UserApplicationGroup}
                />
                <Route path="/CompanyUsers" component={CompanyUsers} />
                <Route
                  path="/AccountCreated"
                  component={AccountCreated}
                  isPrivate={false}
                />
                <Route
                  path="/AccountRemember"
                  component={AccountRemember}
                  isPrivate={false}
                />
                <Route
                  path="/PasswordRecovery/NotMe/:token"
                  component={ResetPasswordWasNotMe}
                  isPrivate={false}
                />
                <Route
                  path="/ExpiredPassword"
                  component={ExpiredPassword}
                  isShowMainMenu={false}
                />

                <Route
                  path="/PasswordRecovery/:token"
                  component={ResetPassword}
                  isPrivate={false}
                />
                <Route
                  path="/PasswordRecovery"
                  component={PasswordRecovery}
                  isPrivate={false}
                />
                <Route path="/UserProfile" component={UserProfile} />
                <Route path="/ChangePassword" component={ChangePassword} />
                <Route path="/Workspace" component={Workspace} />
                <Route path="/NewCompanyUser" component={NewCompanyUser} />
                <Route path="/EditCompanyUser/:id" component={UserProfile} />
                <Route path="/Controllers" component={Controllers} />

                {applicationRoutes.map((appRoute, idx) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return React.cloneElement(appRoute, { key: idx });
                })}

                <Route
                  path="/PageNotAuthorized"
                  isPrivate={false}
                  component={PageNotAuthorized}
                />
                <Route
                  path="/Test/SapLink"
                  component={SapLink}
                  isPrivate={false}
                />
                <Route path="/AhsServices" component={AhsServices} />
                <Route
                  path="/ExecuteReport/:hash/:executionHash"
                  component={ExecuteReport}
                />
                <Route path="/ExecuteReport/:hash" component={ExecuteReport} />

                <Route path="/" isPrivate={false} component={PageNotFound} />
              </Switch>
            </div>
          </Container>
        )}
      </Router>
    </Suspense>
  );
}

export default Routes;

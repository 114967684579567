import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  background-color: #e9edef;
  overflow: hidden;
  display: flex;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const ProductsView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

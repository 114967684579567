/* eslint-disable no-restricted-syntax */
/* eslint-disable no-new-func */
/* eslint-disable func-names */

import { getFunctions } from '../easy-components/HandlerEvent/helpers';

async function sendEvent({
  run = 'before',
  eventName,
  params,
  data,
  ...props
}) {
  if (params) {
    const { settings } = params;

    if (settings) {
      if (settings.events) {
        const dynamicFunctions = getFunctions({
          events: settings.events,
          settings,
          eventName,
          run,
        });

        let rawData = data;

        for (const dynamicFunction of dynamicFunctions) {
          const dynamicFunctionProps =
            settings && settings.dynamicFunctionProps;

          // eslint-disable-next-line no-await-in-loop
          const response = await dynamicFunction({
            ...dynamicFunctionProps,
            ...params,
            ...props,
            data: rawData,
          });

          rawData = response ?? rawData;
        }

        return rawData;
      }
    }
  }

  return data;
}

export default sendEvent;

/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { MdKeyboardArrowLeft } from '@react-icons/all-files/md/MdKeyboardArrowLeft';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { RiFilterLine as FilterOnIcon } from '@react-icons/all-files/ri/RiFilterLine';
import { RiFilterOffLine as FilterOffIcon } from '@react-icons/all-files/ri/RiFilterOffLine';
import DebounceEvent from '~/easy-components/DebounceEvent';
import SystemButton from '~/easy-components/Button';
import useStateCache from '~/hooks/useStateCache';
import useLocale from '~/hooks/useLocale';
import useUiDesigner from '~/hooks/useUiDesigner';
import {
  Page,
  Container,
  SearchPanel,
  SearchPanelContent,
  Content,
  Empty,
  Item,
  Button,
  AuxFilter,
} from './styles';
import AuxFilterModal from './AuxFilterModal';
import Loading from '../Loading';
import Switch from '../Switch';

function ListPage(
  {
    backgroundColor,
    lastUpdatedDate,
    onSearch,
    renderItem,
    onSelect,
    onNew,
    onError,
    onClose,
    isShowClose,
    auxFilters,
    showSearchPanel = true,
    isClickable = true,
    isShowMaskLoading = true,
    settings,
    queryCode,
    isUserPage,
    disableList = false,
  },
  ref
) {
  const { showContextMenu } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'listPage',
    settings,
    title: null,
    contextProps: { queryCode, isUserPage },
  });

  const inputRef = useRef(null);
  const modalFilterRef = useRef(null);
  const filterRef = ref;

  const t = useLocale('_Global');

  const [data, setData, getData] = useStateCache([]);

  const [filterParams, setFilterParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const refresh = useCallback(
    async value => {
      try {
        if (!disableList) {
          setIsLoading(true && isShowMaskLoading);
          const response = await onSearch(
            value || inputRef.current.value,
            filterParams
          );
          setData(response);
          setIsLoading(false);
        }
      } catch (ex) {
        setIsLoading(false);
        onError(ex);
      }
    },
    [isShowMaskLoading, disableList, onSearch, filterParams, setData, onError]
  );

  useEffect(() => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      refresh(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedDate, filterParams]);

  function onClear() {
    inputRef.current.value = '';
    refresh('');
  }

  useImperativeHandle(filterRef, () => {
    return {
      refresh,
      target: inputRef.current,
      removeItems: (identifier = {}) => {
        const onlyItemsOutOfIdentifier = item => {
          for (
            let entryIndex = 0;
            entryIndex < Object.entries(identifier).length;
            entryIndex++
          ) {
            const [key, value] = Object.entries(identifier)[entryIndex];

            if (item[key] !== value) {
              return true;
            }
          }

          return false;
        };

        setData(oldData => oldData.filter(onlyItemsOutOfIdentifier));
      },
      addItem: item => {
        setData(oldData => {
          const newData = [...oldData];

          newData.unshift(item);

          return newData;
        });
      },
      getData,
      setList: list => {
        setData(list);
      },
    };
  });

  return (
    <>
      <Page>
        <SearchPanel>
          <SearchPanelContent visible={showSearchPanel}>
            {auxFilters && auxFilters.length > 0 && (
              <>
                <AuxFilter
                  onClick={evt => {
                    evt.stopPropagation();
                    evt.preventDefault();

                    if (filterParams) {
                      modalFilterRef.current.show(filterParams);
                    } else {
                      const params = {};

                      // eslint-disable-next-line no-restricted-syntax
                      for (const field of auxFilters) {
                        Object.keys(field).forEach(prop => {
                          if (prop === 'defaultValue') {
                            params[field.name] = field[prop];
                          }
                        });
                      }

                      modalFilterRef.current.show(params);
                    }
                  }}
                >
                  {filterParams ? (
                    <FilterOnIcon size={18} color="#fff" />
                  ) : (
                    <FilterOffIcon size={18} color="#fff" />
                  )}
                </AuxFilter>
                <AuxFilterModal
                  ref={modalFilterRef}
                  fields={auxFilters}
                  settings={settings}
                  onConfirm={params => {
                    setFilterParams(params);
                  }}
                />
              </>
            )}
            <input
              ref={inputRef}
              placeholder="Pesquisar..."
              onChange={DebounceEvent(e => refresh(e.target.value))}
              style={{
                flex: 1,
                height: '44px',
                paddingLeft: '20px',
                border: '0',
              }}
            />
            {inputRef.current && inputRef.current.value && (
              <Button
                onClick={onClear}
                style={{ width: '30px', height: '40px' }}
              >
                <MdClose size={18} color="#AAA" />
              </Button>
            )}
            <div>
              {isShowClose && (
                <Switch
                  mobile={<span />}
                  computer={
                    <Button
                      onClick={onClose}
                      style={{ width: '30px', height: '40px' }}
                    >
                      <MdKeyboardArrowLeft size={22} color="#444" />
                    </Button>
                  }
                />
              )}
            </div>
          </SearchPanelContent>
          <Switch
            treatTabletAsMobile
            breakpoint="1080px"
            mobile={
              <>
                <SystemButton
                  onClick={onClose}
                  style={{ marginLeft: '10px' }}
                  buttonType="Emphasized"
                  type="button"
                >
                  {t('Close')}
                </SystemButton>
                <SystemButton
                  onClick={async () => {
                    await onNew();
                    await onClose();
                  }}
                  style={{ marginLeft: '10px' }}
                  buttonType="Emphasized"
                  type="button"
                >
                  {t('New')}
                </SystemButton>
              </>
            }
            computer={<span />}
          />
        </SearchPanel>
        <Container clickable={isClickable}>
          <Loading isLoading={isLoading} />
          <Content
            backgroundColor={backgroundColor}
            onContextMenu={event => {
              showContextMenu({ event });
            }}
          >
            {data.length === 0 && !isLoading && (
              <Empty>{t('message.NoRecordFound')}</Empty>
            )}
            {data.length > 0 &&
              data.map((d, idx) => (
                <Item
                  key={`list-${idx}`}
                  onClick={() => {
                    onSelect(d, idx);
                  }}
                >
                  {renderItem(d, idx)}
                </Item>
              ))}
          </Content>
        </Container>
      </Page>
    </>
  );
}

export default forwardRef(ListPage);

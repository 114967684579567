/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable import/no-cycle */
import RequestService from './RequestService';
import CompanyService from './CompanyService';
import UserApplicationGroupService from './UserApplicationGroupService';

class CompanyUserService {
  async list(filter = '', auxFilters) {
    let filters = '';

    for (const prop in auxFilters) {
      if (auxFilters[prop] !== undefined && auxFilters[prop] !== null) {
        filters += `&${prop}=${auxFilters[prop]}`;
      }
    }

    const response = await RequestService.request({
      url: `company_users?$filter=${filter}${filters}`,
      method: 'GET',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async getPasswordPolicies() {
    const token = await CompanyService.getCompanyToken();

    const response = await RequestService.request({
      url: `APP/CRM/profile/password_policies`,
      method: 'GET',
      token,
    });

    return response;
  }

  async get(userId) {
    const token = await CompanyService.getCompanyToken();

    const response = await RequestService.request({
      url: `company_users/users/${userId}`,
      method: 'GET',
      token,
    });

    const applicationGroups = await UserApplicationGroupService.list(userId);
    delete response.user.password;
    return { ...response, applicationGroups };
  }

  async getApplications(userId) {
    const applicationGroups = await UserApplicationGroupService.list(userId);
    return { applicationGroups };
  }

  async update(id, data) {
    await RequestService.request({
      url: `company_users/users/${id}`,
      method: 'PUT',
      data,
      token: await CompanyService.getCompanyToken(),
    });

    return this.get(id);
  }

  async create(data) {
    const response = await RequestService.request({
      url: `company_users/users`,
      method: 'POST',
      data,
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async verify(data) {
    const response = await RequestService.request({
      url: `company_users/users/verify`,
      method: 'POST',
      data,
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async delete(id) {
    const response = await RequestService.request({
      url: `company_users/users/${id}`,
      method: 'delete',
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async downloadUsers() {
    const token = await CompanyService.getCompanyToken();

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/files/users/download/${token}/companyUsers`
    );
  }

  async changeUserStatus({ userId, status }) {
    const response = await RequestService.request({
      url: `company_users/changeUserStatus/${userId}`,
      method: 'PUT',
      data: {
        status,
      },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async copyRules({ rulesList, usersCopyTo, selectedUser }) {
    const response = await RequestService.request({
      url: `company_users/copyRules`,
      method: 'POST',
      largeData: {
        rulesList,
        usersCopyTo,
        selectedUser,
      },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }

  async attachToCompanies({ userId, companiesToAttach, userEmail }) {
    const response = await RequestService.request({
      url: `company_users/attachToCompanies`,
      method: 'POST',
      timeout: 5 * 60 * 1000, // 5 minutes
      largeData: {
        userId,
        userEmail,
        companiesToAttach,
      },
      token: await CompanyService.getCompanyToken(),
    });

    return response;
  }
}

export default new CompanyUserService();

import styled, { css } from 'styled-components';
import { isMobile } from '~/easy-components/DeviceDetect';

export const SearchMobileInput = styled.input`
  height: 45px;
  background-color: #fff;
  border: 0;
  border-top: 2px solid #f0f0f0;
  padding: 0 15px;

  &::placeholder {
    color: #aaa;
  }
`;

export const Resume = styled.div`
  background-color: #f1f5f7;
  padding: 5px 10px;
  border-top: 1px solid #f0f0f0;
  font-size: 0.9rem;
  color: #728591;
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  background-color: #fff;
  transition: all 0.2s;

  .csv-link {
    background-color: #353c44;
  }

  &.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    top: 0;
    left: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;

  > h1 {
    height: 70px !important;
  }

  .ka {
    flex: 1;
  }

  /*
  .ka-table-wrapper {
    overflow-y: unset !important;
  }

  .ka-cell,
  .ka-thead-cell {
    padding: unset;
  }
  */

  .ka-cell-text {
    justify-content: flex-start !important;
    text-align: left;

    > div {
      justify-content: flex-start !important;
      text-align: left;
      line-height: 18px;
    }
  }

  .ka-thead-cell-height {
    /* box-sizing: border-box; */
    height: unset;
  }

  .ka-thead-row:nth-child(2) .ka-thead-fixed {
    top: ${props => {
      switch (props.cellDensity) {
        case 'minimum':
          return '30px';

        case 'compact':
          return '35px';

        case 'default':
          return '45px';

        default:
          return '45px';
      }
    }};
  }

  > div {
    width: 100%;
    height: 100%;
    ${() =>
      !isMobile &&
      css`
        display: flex;
      `};
  }

  table {
    min-width: 100%;
    /**
    * Colocar o display como block torna impossível a coluna de se expandir até
    * o final da tela 
    */
    display: table;
    white-space: nowrap;
    flex: 1;
    min-width: 100%;
  }

  thead {
    width: 100%;
  }

  td,
  th {
    /* width: 100%; */
    /* padding: 8px 20px; */
    text-align: left;
    line-height: 29px;
    color: #656565;
  }

  th {
  }

  input {
    width: 100%;
    padding: 8px;

    border-radius: 0;
    border: 1px solid #f0f0f0;
  }

  .ka-table {
    width: unset;
    tbody {
      tr {
        transition: background 0.2s;
        border: none;
        border-bottom: 1px solid #f0f0f0 !important;
      }

      tr:hover {
        background: #e9e9e9 !important;
      }

      tr:nth-child(even) {
        background: #f7f7f7;
      }

      tr.persist-row-hover {
        background: #e9e9e9 !important;
      }
    }
  }

  .ka-input {
  }

  .ka-filter-row-cell {
    padding: 0;
    background-color: #f7f7f7;

    input {
      background-color: #fff;
    }
  }

  .ka-row-selected {
    background-color: #deeff6 !important;
  }

  .ka-group-text {
    word-break: break-word;
    white-space: pre-wrap;
  }

  .ka-summary-row {
    border: none !important;
  }
`;

export const FakeInput = styled.div`
  padding: ${isMobile ? '0' : '8px'};
  background-color: ${props => (props.readOnly ? '#e6e6e6' : '#fff')};
  border: ${isMobile ? 'none' : '1px solid rgb(224, 224, 224)'};
  cursor: text;
  min-height: 34px;
  width: 100%;
  font-size: 0.9rem;
  min-width: ${props => (props.minWidth ? props.minWidth : 'unset')};
  text-align: ${props =>
    props.inputType === 'inputNumber' ? 'right' : 'left'};

  span {
    font-size: 0.9rem;
    line-height: 1;
  }
`;

export const Toolbar = styled.div`
  background-color: #fff;
  color: #353c44;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  height: 50px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  button {
    border: none;
    background-color: transparent;
    margin-right: 10px;
  }
`;

export const FooterBar = styled.div`
  background-color: #fff;
  color: #353c44;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  height: 50px;
  border-top: 1px solid #dedede;

  button {
    margin-right: 10px;
  }

  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const Linker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 500px) {
      white-space: normal;
      text-overflow: ellipsis;
      color: #999;
      font-size: 0.9rem;
    }
  }
`;

export const Input = styled.input`
  width: 100% !important;
  padding: 8px;
  border: 1px solid #ddd !important;
  background-color: ${props => (props.readOnly ? '#fafafa' : '#fff')};
  color: #555;

  text-align: ${props =>
    props.inputType === 'inputNumber' ? 'right' : 'left'};

  @media (max-width: 768px) {
    font-size: 0.9rem;
    border: none !important;
    padding: 4px 0;
    border-radius: 0;
  }
`;

export const DisplayCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 500px) {
      white-space: normal;
      text-overflow: ellipsis;
      color: #999;
      font-size: 0.9rem;
    }
  }
`;

export const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
`;

export const Cell = styled.div`
  justify-content: ${props => (isMobile ? 'flex-start' : props.align)};
  display: ${props => (props.direction === 'row' ? 'flex' : 'block')};

  > div {
    justify-content: ${props => (isMobile ? 'flex-start' : props.align)};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AppendButton = styled.div`
  margin-left: 0.3rem;
  cursor: pointer;
`;

export const AddLineButton = styled.button`
  margin-left: 15px;
  cursor: pointer;

  div {
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }
`;

export const PDFButton = styled.button`
  cursor: pointer;

  div {
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }
`;

export const CSVLinkWrapper = styled.div`
  display: inline-block;
  cursor: pointer;

  div {
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }
`;

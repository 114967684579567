import {
  isMobile as isPhone,
  isIPad13,
  isTablet,
  isSafari,
  isDesktop,
  isAndroid,
} from 'react-device-detect';

const isMobile = isIPad13 || isTablet ? false : isPhone;

export { isMobile, isSafari, isDesktop, isAndroid, isTablet };

/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { isMobile } from '~/easy-components/DeviceDetect';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircleFill } from 'react-icons/bs';
import Icon from '~/easy-components/Icon';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import {
  FieldContainer,
  Title,
  Input,
  PanelInput,
  IconButton,
  InputValueIcon,
} from './styles';
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';

const getInputDefaultValue = ({ unformDefaultValue, fieldDefaultValue }) => {
  if (unformDefaultValue !== null && unformDefaultValue !== undefined)
    return unformDefaultValue;

  if (typeof fieldDefaultValue === 'object') return '';

  return fieldDefaultValue;
};

/**
 * @typedef {Object} Props
 * @prop {string} [label]
 * @prop {import('react').MutableRefObject<import('@unform/core').FormHandles | undefined>} [formRef]
 * @prop {import('react').MutableRefObject<import('@unform/core').FormHandles | undefined>} [mainFormRef]
 * @prop {object} [settings]
 * @prop {string | number} [labelWidth]
 * @prop {bool} [readOnly]
 * @prop {(params: any) => void} [onChange]
 * @prop {any} [fieldDefaultValue]
 * @prop {object} [auxScope]
 * @prop {boolean} [disableUnform=false]
 */

/**
 * @param {Props & import('react').InputHTMLAttributes} props
 */
function InputIcon({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  onChange,
  fieldDefaultValue,
  auxScope,
  hasEmptyValue = false,
  labelPosition = 'left',
  disableUnform = false,
  required = false,
  isUserField,
  customTabName,
  ...rest
}) {
  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const changed = useRef(false);

  const [iconName, setIconName] = useState('');

  const inputRef = useRef(null);

  const fieldTitle = selfField.title || label;

  const {
    fieldName,
    defaultValue: unformDefaultValue,
    registerField,
    error,
  } = useField(name);

  const { isHidden, isReadOnly, isRequired } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const defaultValue = getInputDefaultValue({
    unformDefaultValue,
    fieldDefaultValue,
  });

  useEffect(() => {
    if (disableUnform) return;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (el, v) => {
        const textValue = v === null || v === undefined ? '' : v;

        el.value = textValue;

        setIconName(textValue);
      },
      getValue: el => {
        let returnValue = null;
        if (hasEmptyValue) {
          returnValue = el.value;
        } else {
          returnValue = el.value === '' ? null : el.value;
        }

        return returnValue;
      },
    });
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: params.element,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });

    return response || params.value;
  };

  async function onBlur(params) {
    params.persist();

    if (changed.current || params.target.changed) {
      changed.current = false;

      let val = params.target.value;

      const beforeValue = await handlerOnChange({
        ...params,
        run: 'before',
        element: params,
      });

      if (beforeValue !== undefined) val = beforeValue;

      params.target.value = val;

      await onChange(params);

      val = params.target.value;

      const afterValue = await handlerOnChange({
        ...params,
        run: 'after',
        element: params,
      });
      if (afterValue !== undefined) val = afterValue;

      inputRef.current.value = val;

      setIconName(val);
    }
  }

  const onChanged = async e => {
    await onBlur(e);
  };

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.setSelectionRange(0, text.length);
  }

  useEffect(() => {
    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener) {
        inputRef.current.removeEventListener('changed', onChanged);
        inputRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  const nativeOnChange = () => {
    changed.current = true;
  };

  const onLinkClick = e => {
    e.preventDefault();
    e.stopPropagation();

    window.open('https://react-icons.github.io/react-icons/');
  };

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        labelPosition={labelPosition}
        readOnly={isReadOnly}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelPosition={labelPosition}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <IconButton data-tip="React Icons" data-for="reactIcons">
                  <BsInfoCircleFill size={22} onClick={onLinkClick} />
                </IconButton>
                <ReactTooltip
                  id="reactIcons"
                  place="top"
                  type="dark"
                  effect="solid"
                  className="toolbarTooltip"
                />
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}
        <PanelInput>
          <div className={isReadOnly ? 'disabled' : ''}>
            <Input
              ref={inputRef}
              defaultValue={defaultValue}
              {...selfField}
              {...rest}
              hidden={false}
              onBlur={onBlur}
              onChange={nativeOnChange}
              readOnly={isReadOnly}
            />
            <InputValueIcon>
              <Icon name={iconName} size={18} color="#aaa" />
            </InputValueIcon>
          </div>
        </PanelInput>
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
        auxScope={auxScope}
      />
    </>
  );
}

InputIcon.propTypes = {
  formRef: PropTypes.shape(),
  onChange: PropTypes.func,
  baseName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  settings: PropTypes.shape(),
  labelWidth: PropTypes.any,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mainFormRef: PropTypes.shape(),
};

InputIcon.defaultProps = {
  formRef: null,
  baseName: null,
  settings: null,
  onChange: val => {
    return val;
  },
  label: null,
  labelWidth: null,
  readOnly: false,
  hidden: false,
  mainFormRef: {},
};

export default InputIcon;

import styled from 'styled-components';

export const BoxProducts = styled.button`
  position: relative;
  transition: filter ease 0.2s;
  color: #888;
  background-color: transparent;

  border: none;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const ContentQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 14px;
  height: 14px;
  padding: 2px;
  background: #fff;
  border-radius: 50%;
  color: #000;
  right: -2px;
  bottom: -2px;
  font-size: 0.5rem;
`;

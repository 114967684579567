/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, memo, useMemo } from 'react';
import { useField } from '@unform/core';
import { format } from 'date-fns';

import { isMobile } from '~/easy-components/DeviceDetect';
import { MdOutlineFlashOn as FlashIcon } from 'react-icons/md';
import useCompany from '~/hooks/useCompany';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import {
  FieldContainer,
  Title,
  Input,
  PanelInput,
  ButtonIcon,
} from '../styles';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';

function DateElement({
  baseName,
  name,
  label,
  hidden,
  formRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  fieldDefaultValue = null,
  returnFormat = null,
  mainFormRef = {},
  onChange = () => {},
  auxScope,
  labelPosition = 'left',
  required = false,
  isUserField,
  customTabName,
  ...rest
}) {
  const { stringToDate, dateToString } = useCompany();

  const dateValueRef = useRef();
  const charPermitted = '0123456789/';
  const changed = useRef(false);
  const inputRef = useRef(null);

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const fieldTitle = selfField.title || label;

  delete selfField.type;

  const getInputDefaultValue = ({
    unformDefaultValue,
    fieldDefaultValue: fieldDefaultValue2,
  }) => {
    if (dateValueRef.current === null) return null;

    if (unformDefaultValue !== null && unformDefaultValue !== undefined)
      return unformDefaultValue;

    if (typeof fieldDefaultValue2 === 'object') return '';

    return fieldDefaultValue2;
  };

  const {
    fieldName,
    defaultValue: unformDefaultValue,
    registerField,
    error,
  } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  const defaultValue = getInputDefaultValue({
    unformDefaultValue,
    fieldDefaultValue,
  });

  function getReturnDate() {
    if (dateValueRef.current) {
      if (!(dateValueRef.current instanceof Date)) {
        return dateValueRef.current;
      }
      const formattedDate = returnFormat
        ? format(dateValueRef.current, returnFormat)
        : dateValueRef.current;

      return formattedDate;
    }
    return null;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
        dateValueRef.current = null;
      },
      setValue: (el, v) => {
        const textValue = v;

        /* if (v === undefined) {
          textValue = v || fieldDefaultVal<ue || defaultValue || null;
        } */

        const newDate = stringToDate(textValue);

        dateValueRef.current = newDate;

        el.value = dateToString(newDate);
      },
      getValue: () => {
        const date = getReturnDate();
        return date;
      },
    });

    dateValueRef.current = defaultValue;

    const defaultValueFormatted = dateToString(dateValueRef.current);

    inputRef.current.value = defaultValueFormatted;
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });

    return response || params.value;
  };

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.setSelectionRange(0, text.length);
  }

  const defaultValueFormatted = useMemo(() => {
    const newDate = stringToDate(defaultValue);

    if (!dateValueRef.current && defaultValue) {
      dateValueRef.current = newDate;
    }

    const outDate = dateToString(newDate);
    return outDate;
  }, [defaultValue]);

  async function handlerOnSetCurrentValue({ run, currentDate }) {
    const dynamicFunctionProps = settings
      ? settings.dynamicFunctionProps
      : null;
    return sendEvent({
      settings,
      eventName: 'onSetCurrentValue',
      run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope: {
        ...dynamicFunctionProps,
        currentDate,
      },
    });
  }

  async function handleBlur() {
    if (changed.current || inputRef.current.changed) {
      changed.current = false;

      let val = dateValueRef.current;

      const beforeValue = await handlerOnChange({
        value: val,
        run: 'before',
      });

      if (beforeValue !== undefined) {
        val = stringToDate(beforeValue);
        dateValueRef.current = beforeValue;
        inputRef.current.value = dateToString(val);
      }

      await onChange(val);

      await handlerOnChange({
        value: val,
        run: 'after',
      });
    }
  }

  async function onBlur() {
    try {
      const newDate = stringToDate(inputRef.current.value);

      dateValueRef.current = newDate;
      inputRef.current.value = dateToString(newDate);

      await handleBlur();
    } catch (e) {
      inputRef.current.value = '';
      if (onChange) onChange();
    }
  }

  const onChanged = async e => {
    e.persist();
    await onBlur(e);
  };

  async function onSetCurrentDate() {
    const currentDate = stringToDate(dateToString(new Date()));

    const eventResponse = await handlerOnSetCurrentValue({
      run: 'before',
      currentDate,
    });

    dateValueRef.current = eventResponse || currentDate;
    inputRef.current.value = dateToString(eventResponse || currentDate);

    changed.current = true;

    await handlerOnSetCurrentValue({ run: 'after', currentDate });

    await handleBlur();
  }

  useEffect(() => {
    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener) {
        inputRef.current.removeEventListener('changed', onChanged);
        inputRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        readOnly={isReadOnly}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        labelPosition={labelPosition}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelPosition={labelPosition}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}

        <PanelInput readOnly={isReadOnly}>
          <div>
            <Input
              ref={inputRef}
              defaultValue={defaultValueFormatted}
              {...selfField}
              {...rest}
              hidden={false}
              readOnly={isReadOnly}
              onBlur={onBlur}
              onChange={() => {
                changed.current = true;
              }}
              onKeyUp={() => {
                if (
                  charPermitted.indexOf(
                    inputRef.current.value.charAt(
                      inputRef.current.value.length - 1
                    )
                  ) === -1
                ) {
                  const newDate = stringToDate(dateToString(new Date()));
                  dateValueRef.current = newDate;
                  inputRef.current.value = dateToString(newDate);
                }
              }}
              autoComplete="off"
            />

            {isMobile &&
              !isReadOnly &&
              !(selfField.disabled || false) &&
              !(selfField.readOnly || false) && (
                <ButtonIcon
                  onClick={onSetCurrentDate}
                  style={{ width: '30px', height: '40px' }}
                >
                  <FlashIcon size={22} color="#a7cee7 " />
                </ButtonIcon>
              )}
          </div>
        </PanelInput>
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
        auxScope={auxScope}
      />
    </>
  );
}

export default memo(DateElement);

/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { isMobile } from '~/easy-components/DeviceDetect';
import { deselectRow } from 'ka-table/actionCreators';

import Icon from '~/easy-components/Icon';
import Modal from '~/easy-components/Modal';
import FullScreenViewer from './FullScreenViewer';

import { PanelInput, TextArea, UpdateIcon } from './styles';
import { PageContext } from '../../index';
import dynamicFunction from '../../dynamicFunction';
import validateAndSelectRow from '../../CustomActions/validateAndSelectRow';
import useInputNavigation from '../../hooks/useInputNavigation';

function InputTextArea({
  column,
  rowKeyValue,
  value,
  style,
  getSelectedData,
  rowData,
  onRefresh,
  cellProps,
  readOnly,
  items,
  rawItems,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const {
    dispatch,
    auxScope,
    setRowFieldValue,
    removeLine,
    tableProps,
    refreshTable,
  } = useContext(PageContext);

  const [isShowFullScreen, setIsShowFullScreen] = useState(false);

  const iconRef = useRef();
  const inputRef = useRef();
  const inputChanged = useRef(true);

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: inputRef,
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value =
        value === null || value === undefined ? '' : value;
    }
  }, [value]);

  useEffect(() => {
    if (isFocused && !inputChanged.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  });

  const inputReadOnly =
    readOnly || (column.settings && column.settings.readOnly);

  let iconName = 'AiFillEdit';
  if (inputReadOnly) iconName = 'AiOutlineExpandAlt';

  async function changeCellValue() {
    if (inputReadOnly) return false;

    if (!inputChanged.current) return false;

    const editorValue = inputRef.current && inputRef.current.value;
    setRowFieldValue(rowKeyValue, column.key, editorValue, false);

    if (column.settings && column.settings.onChange) {
      await dynamicFunction({
        functionString: column.settings.onChange,
        dispatch,
        params: {
          ...auxScope,
          getSelectedData,
          column,
          line: rowData,
          value,
          rowKeyValue,
          refresh: onRefresh,
          currentValue: editorValue,
          items,
          rawItems,
          setFieldValue: (columnKey, newValue, forceUpdate) => {
            setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
          },
          setRowFieldValue,
          refreshTable,
          selectRow: ({ force = false }) => {
            dispatch(
              validateAndSelectRow({
                callingFromCell: cellProps,
                forceSelection: force,
              })
            );
          },
          deselectRow: () => {
            dispatch(deselectRow(rowKeyValue));
          },
          removeLine,
          reportSettings,
        },
      });
    }
    inputChanged.current = false;
    refreshTable();
    return true;
  }

  const nativeOnChange = () => {
    inputChanged.current = true;
  };

  return (
    <PanelInput hideBorder={false}>
      <div className={inputReadOnly ? 'disabled' : ''}>
        <TextArea
          ref={inputRef}
          rows="1"
          style={style}
          disabled={column.settings.disabled || false}
          onClick={e => {
            e.stopPropagation();
            if (isMobile) {
              inputRef.current.focus();
            }
          }}
          readOnly={inputReadOnly}
          inputNavigationOnFocus={inputNavigationOnFocus}
          onBlur={changeCellValue}
          onChange={nativeOnChange}
          onKeyDown={e => {
            inputNavigationOnKeyDown(e);
            inputChanged.current = true;
          }}
        />

        <UpdateIcon
          ref={iconRef}
          className="hoverable"
          readOnly={inputReadOnly}
          onClick={() => {
            setIsShowFullScreen(true);
          }}
        >
          <Icon name={iconName} size={14} color="#aaa " isClickable />
        </UpdateIcon>

        <Modal isOpened={isShowFullScreen}>
          <FullScreenViewer
            inputRef={inputRef}
            name={column.settings.name}
            title={column.settings.title}
            onClose={() => {
              setIsShowFullScreen(false);
              changeCellValue();
            }}
            defaultValue={column.settings.defaultValue}
            onChange={nativeOnChange}
            readOnly={inputReadOnly}
          />
        </Modal>
      </div>
    </PanelInput>
  );
}

export default InputTextArea;

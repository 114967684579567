/* eslint-disable react/prop-types */
import React from 'react';
import PropType from 'prop-types';

import Icon from '~/easy-components/Icon';
import DocumentStatus, { getStatusInfo } from '../DocumentStatus';
import { Container, Content, Line, Status } from './styles';

function Item({ data, selected, t, tMenus }) {
  const status = getStatusInfo(data.status, t);

  const { type } = data;

  let iconName = null;

  if (type === 'internal') iconName = 'IoCubeOutline';
  if (type === 'external') iconName = 'FiExternalLink';
  if (type === 'custom') iconName = 'AiOutlineFileText';
  if (type === 'udo') iconName = 'SiSap';
  if (type === 'userTable') iconName = 'HiOutlineTableCells';
  if (type === 'wizard') iconName = 'FiList';

  return (
    <Container selected={selected} status={data.Status}>
      <Status color={status.color} />
      <Icon name={iconName} size={32} color="#777" />
      <Content>
        <Line>
          <h1>{data.name}</h1>
        </Line>
        <Line>
          <div>{data.menu && tMenus(data.menu.name)}</div>
          <DocumentStatus status={data.status} t={t} />
        </Line>
      </Content>
    </Container>
  );
}

Item.propTypes = {
  data: PropType.shape().isRequired,
  selected: PropType.bool.isRequired,
};

export default Item;

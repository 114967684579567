import styled from 'styled-components';

export const Container = styled.button`
  display: none;
  background-color: transparent;
  @media (max-width: 500px) {
    display: block;
    position: relative;
    transition: filter ease 0.2s;
    color: #888;
    border: none;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import HeaderPage from '~/easy-components/HeaderPage';
// eslint-disable-next-line import/no-cycle
import Button from '~/easy-components/Button';
import colors from '~/styles/colors';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import useLocale from '~/hooks/useLocale';
import ModalHandle from '../ModalHandle';

import { Container, Content, ProductsView } from './styles';
import { ModalCatalogProvider } from './context';
// eslint-disable-next-line import/no-cycle
import Filters from './Components/Filters';
import Products from './Components/Products';
import ChosenItems from './Components/ChosenItems';
import HeaderInput from './Components/HeaderInput';
import TemplateJSX from '../TemplateJSX';
import ButtonConfirmItems from './Components/ConfirmItems';

/**
 * @typedef {Object} Settings
 * @property {React.ReactNode} modalJsx
 * @property {catalogCustomFields[]} catalogCustomFields
 *
 * @typedef {Object} catalogCustomFields
 * @property {string} name
 * @property {string} type
 * @property {string} label
 */

/**
 * @typedef {Object} ModalCatalogProps
 * @property {Settings} settings
 */

/**
 * @param {ModalCatalogProps} props
 */

function ModalCatalog({ settings }, ref) {
  const modalRef = useRef();
  const t = useLocale('_Global');
  const [state, setState] = useState({
    titleModel: t('SelectedItems'),
    titleCategory: t('Category'),
    title: '',
    isShow: false,
    items: [],
    filters: [],
    // eslint-disable-next-line no-unused-vars
    onLoadData: ({ filter }) => Promise.resolve([]),
    onGetFilters: () => Promise.resolve([]),
    itemKeyField: 'ItemCode',
    onConfirm: () => {},
    onClose: () => modalRef.current.handleClose(),
    formData: {},
  });
  const { modalJsx, catalogCustomFields } = settings;
  /**
   * @typedef {Partial<Omit<typeof state, 'isShow'>>} ModalCatalogProps
   */

  useImperativeHandle(ref, () => {
    return {
      show: async showProps => {
        const items = await showProps.onLoadData({ filter: '' });
        const filters = await showProps.onGetFilters();

        setState({
          ...state,
          ...showProps,
          filters,
          items,
          isShow: true,
        });

        modalRef.current.handleOpen();
      },
      close: () => {
        setState({
          ...state,
          isShow: false,
        });

        modalRef.current.handleClose();
      },
    };
  });

  function handlerClose(e) {
    e.stopPropagation();

    e.preventDefault();

    setState({
      ...state,
      isShow: false,
    });
    state.onClose();
  }

  return (
    <ModalHandle
      ref={modalRef}
      contentStyle={{ style: { width: '98%', height: '98%' } }}
    >
      <ModalCatalogProvider
        initialItems={state.items}
        loadData={state.onLoadData}
        currency={state.formData.DocCurId}
        settings={settings}
      >
        {modalJsx ? (
          <TemplateJSX jsxString={modalJsx} auxScope={{ ...settings, state }} />
        ) : (
          <Container>
            <HeaderPage
              backgroundColor={colors.headerBg}
              color={colors.headerTitle}
              hideMainMenu
            >
              {state.title}
            </HeaderPage>
            <Content>
              <Filters
                t={t}
                data={state.filters}
                getFilters={state.onGetFilters}
                title={state.titleCategory}
              />
              <ProductsView>
                <HeaderInput t={t} />

                <Products
                  itemKeyField={state.itemKeyField}
                  catalogCustomFields={catalogCustomFields}
                />
              </ProductsView>
              <ChosenItems
                title={state.titleModel}
                onConfirm={state.onConfirm}
                onClose={handlerClose}
              />
            </Content>

            <FooterPage>
              <FlexSpace />
              <ButtonConfirmItems
                onConfirm={state.onConfirm}
                onClose={handlerClose}
                t={t}
              />
              <Button type="button" onClick={handlerClose}>
                {t('Close')}
              </Button>
            </FooterPage>
          </Container>
        )}
      </ModalCatalogProvider>
    </ModalHandle>
  );
}

export default forwardRef(ModalCatalog);

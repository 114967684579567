/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';

import { deselectRow } from 'ka-table/actionCreators';

import { format } from 'date-fns';
import useCompany from '~/hooks/useCompany';
import { isMobile } from '~/easy-components/DeviceDetect';
import { Input } from '../styles';

import validateAndSelectRow from '../CustomActions/validateAndSelectRow';
import dynamicFunction from '../dynamicFunction';
import { PageContext } from '../index';
import useInputNavigation from '../hooks/useInputNavigation';

function InputDate({
  column,
  rowKeyValue,
  value,
  dispatch,
  readOnly,
  items,
  rawItems,
  getSelectedData,
  rowData,
  onRefresh,
  cellProps,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const { dateToString, stringToDate } = useCompany();
  const {
    auxScope,
    setRowFieldValue,
    removeLine,
    tableProps,
    refreshTable,
  } = useContext(PageContext);

  const inputRef = useRef();
  const inputChanged = useRef(false);

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: inputRef,
  });

  const charPermitted = '0123456789/';
  const formatDateValue = useCallback(
    newValue => {
      if (newValue === null) return null;

      return dateToString(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [editorValue, setValue] = useState(formatDateValue(value));

  useEffect(() => {
    setValue(formatDateValue(value));
  }, [formatDateValue, value]);

  useEffect(() => {
    if (isFocused && !inputChanged.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  });

  return (
    <Input
      ref={inputRef}
      value={editorValue}
      inputType="inputNumber"
      disabled={column.settings.disabled || false}
      readonly={readOnly}
      onClick={e => {
        e.stopPropagation();

        if (isMobile) {
          inputRef.current.focus();
        }
      }}
      onFocus={inputNavigationOnFocus}
      onChange={event => {
        inputChanged.current = true;
        setValue(event.currentTarget.value);
      }}
      onBlur={async event => {
        if (!inputChanged.current) return false;

        if (readOnly) return false;

        const input = event.target;

        const date = input.value ? stringToDate(input.value) : null;

        const newValue = date ? new Date(format(date, 'yyyy-MM-dd')) : null;
        setRowFieldValue(rowKeyValue, column.key, newValue, false);

        if (column.settings && column.settings.onChange) {
          await dynamicFunction({
            functionString: column.settings.onChange,
            dispatch,
            params: {
              ...auxScope,
              items,
              rawItems,
              getSelectedData,
              column,
              line: rowData,
              value,
              rowKeyValue,
              refresh: onRefresh,
              currentValue: newValue,
              refreshTable,
              setFieldValue: (columnKey, newValueProp, forceUpdate) => {
                setRowFieldValue(
                  rowKeyValue,
                  columnKey,
                  newValueProp,
                  forceUpdate
                );
              },
              setRowFieldValue,
              selectRow: ({ force = false }) => {
                dispatch(
                  validateAndSelectRow({
                    callingFromCell: cellProps,
                    forceSelection: force,
                  })
                );
              },
              deselectRow: () => {
                dispatch(deselectRow(rowKeyValue));
              },
              removeLine,
              reportSettings,
            },
          });
        }

        inputChanged.current = false;
        refreshTable();
        return true;
      }}
      onKeyUp={() => {
        const lastChar =
          editorValue !== null
            ? editorValue.charAt(editorValue.length - 1)
            : charPermitted[0];
        if (charPermitted.indexOf(lastChar) === -1) {
          setValue(dateToString(new Date()));
        }
      }}
      onKeyDown={e => {
        inputNavigationOnKeyDown(e);
      }}
    />
  );
}

export default InputDate;

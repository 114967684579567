// @ts-check

import React, { createContext, useEffect, useState } from 'react';

/**
 * @typedef {Object} Context
 * @property {Record<string, string[]>} filters
 * @property {React.Dispatch<React.SetStateAction<Record<string, string[]>>>} setFilters
 * @property {Object[]} allItems
 * @property {Object[]} filteredItems
 * @property {React.Dispatch<React.SetStateAction<Object[]>>} setItems
 * @property {Object[]} selectedItems
 * @property {React.Dispatch<React.SetStateAction<Object[]>>} setSelectedItems
 * @property {boolean} openChosenItems
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenChosenItems
 * @property {(filter: string) => Promise<void>} onLoadData
 * @property {boolean} openFilters
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenFilters
 * @property {string} currency
 * @property {Object} settings
 

 */

/** @type {Context} */
const initialState = {
  filters: {},
  setFilters: () => {},
  allItems: [],
  filteredItems: [],
  setItems: () => {},
  selectedItems: [],
  setSelectedItems: () => [],
  openChosenItems: false,
  setOpenChosenItems: () => {},
  onLoadData: () => Promise.resolve(),
  openFilters: false,
  setOpenFilters: () => {},
  currency: 'R$',
  settings: {},
};

const ModalCatalogContext = createContext(initialState);

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {Object[]} props.initialItems
 * @param {Function} props.loadData
 * @param {string} props.currency
 * @param {Object} props.settings
 

 */
const ModalCatalogProvider = ({
  children,
  initialItems,
  loadData,
  currency,
  settings,
}) => {
  const [filters, setFilters] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [openChosenItems, setOpenChosenItems] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [items, setItems] = useState(initialItems);

  const filterEntries = Object.entries(filters);
  const isFilterNotEmpty = filterEntries.some(([, value]) => value.length > 0);

  let filteredItems = items;

  if (isFilterNotEmpty) {
    filteredItems = items.filter(item => {
      const filtered = filterEntries.every(entry => {
        const [category, filter] = entry;

        if (filter.length === 0) {
          return true;
        }

        const value = item[category];

        const isValueIncluded = filter.includes(value);

        return isValueIncluded;
      });

      return filtered;
    });
  }

  async function onLoadData(filter) {
    const newItems = await loadData({ filter });
    setItems(newItems);
  }

  useEffect(() => {
    if (selectedItems.length === 0) {
      setOpenChosenItems(false);
    }
  }, [selectedItems]);

  return (
    <ModalCatalogContext.Provider
      value={{
        filters,
        setFilters,
        allItems: items,
        filteredItems,
        selectedItems,
        setSelectedItems,
        openChosenItems,
        setOpenChosenItems,
        onLoadData,
        setOpenFilters,
        setItems,
        openFilters,
        currency,
        settings,
      }}
    >
      {children}
    </ModalCatalogContext.Provider>
  );
};

export { ModalCatalogContext, ModalCatalogProvider };

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }

    h1 {
      color: #afbcbe;
      font-weight: 500;
    }
  }

  aside {
    flex: 1;
    display: flex;
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #ff0;
  flex-direction: column;
  height: 100%;
`;

export const ModalContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  background-color: #f0f0f0;
  flex-direction: row;
  overflow: auto;
`;

import React from 'react';
import FormPage from '../FormPage';
import TablePage from '../TablePage';

// import { Container } from './styles';

function PageManager({ type, ...stepProps }) {
  if (type === 'form') {
    return <FormPage {...stepProps} />;
  }

  if (type === 'table') {
    return <TablePage {...stepProps} />;
  }

  return null;
}

export default PageManager;

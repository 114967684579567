/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
// eslint-disable-next-line import/no-cycle
import Kanban from '~/easy-components/Kanban';
import TreatError from '~/easy-components/TreatError';
import {
  createAsyncFunctionByString,
  createSyncFunctionByString,
} from '~/easy-components/AsyncFunctionString';
import QueryService from '~/services/QueryService';
import CardTemplate from '~/easy-components/CardTemplate';
import { Container, Summary } from './styles';
import Error from './Error';
import Loader from './Loader';
import SearchTitle from '../../pages/SalesOpportunitiesKanban/components/Title';

function KanbanPage({ t, settings, reportSettings, params, simplePageRef }) {
  const [kanban, setKanban] = useState({
    boards: [],
    cards: [],
  });
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const paramsRef = useRef(null);

  const refresh = useCallback(async () => {
    try {
      if (reportSettings.sqlBoards) {
        try {
          const reportParams = {
            ...params,
            filter,
          };

          paramsRef.current = reportParams;

          const [boards, cards] = await Promise.all([
            QueryService.execute(1, reportSettings.sqlBoards, reportParams),
            QueryService.execute(1, reportSettings.sqlCards, reportParams),
          ]);

          setKanban({
            boards: [],
            cards: [],
          });

          await executeEvent({
            eventName: 'onLoad',
            run: 'before',
            data: {
              data: {
                boards,
                cards,
              },
              settings,
            },
          });
          setKanban({
            boards,
            cards,
          });

          await executeEvent({
            eventName: 'onLoad',
            run: 'after',
            data: {
              data: {
                boards,
                cards,
              },
              settings,
            },
          });
        } catch (e) {
          const errorDescription = TreatError.getDescription(e);
          setError(errorDescription);
        }
      }
    } catch (e) {
      TreatError.showError(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKanban, reportSettings, filter]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (reportSettings.showSearch && simplePageRef && simplePageRef.current) {
      simplePageRef.current.setTitle(
        <SearchTitle
          title={simplePageRef.current.title}
          onSearch={value => {
            setFilter(value);
          }}
        />
      );
    }
  }, [reportSettings]);

  const cardTemplateFunction = useMemo(() => {
    try {
      const dynamicFunction = createSyncFunctionByString({
        functionString: reportSettings.cardTemplate,
      });
      return dynamicFunction;
    } catch (e) {
      const errorDesc = TreatError.getDescription(e);
      setError(errorDesc);
      return null;
    }
  }, [reportSettings]);

  const boardTemplateFunction = useMemo(() => {
    try {
      const dynamicFunction = createSyncFunctionByString({
        functionString: reportSettings.boardTemplate,
      });

      return dynamicFunction;
    } catch (e) {
      const errorDesc = TreatError.getDescription(e);
      setError(errorDesc);
      return null;
    }
  }, [reportSettings]);

  const cardButtons = useMemo(() => {
    if (reportSettings.cardButtons) {
      const buttons = reportSettings.cardButtons.map(button => {
        return {
          ...button,
          handler: async ({ card, board }) => {
            const dynamicFunction = createAsyncFunctionByString({
              functionString: button.handler,
            });

            await dynamicFunction({
              ...settings.dynamicFunctionProps,
              data: {
                card,
                board,
              },
            });
          },
        };
      });
      return buttons;
    }

    return null;
  }, [settings.cardButtons, settings.dynamicFunctionProps]);

  const executeEvent = async ({ eventName, run = 'before', data, ...rest }) => {
    if (reportSettings.events) {
      const event = reportSettings.events.find(
        evt =>
          evt.name.toUpperCase() === eventName.toUpperCase() &&
          (evt.run || 'before') === run
      );

      if (event) {
        const dynamicFunction = createAsyncFunctionByString({
          functionString: event.handler,
        });

        return dynamicFunction({
          data: {
            ...data,
            params: paramsRef.current,
          },
          refresh,
          ...rest,
          ...settings.dynamicFunctionProps,
        });
      }

      return null;
    }

    return null;
  };

  async function onAdd({ board }) {
    try {
      await executeEvent({
        eventName: 'onAdd',
        run: 'before',
        data: { board, settings },
      });
      await executeEvent({
        eventName: 'onAdd',
        run: 'after',
        data: { board, settings },
      });
    } catch (e) {
      TreatError.showError(e);
    }
  }

  async function onDrop({ elementDragged, ...rest }) {
    try {
      elementDragged.classList.add('processing');

      await executeEvent({
        eventName: 'onDrop',
        run: 'after',
        data: { elementDragged, ...rest },
      });
    } catch (e) {
      TreatError.showError(e);
    } finally {
      elementDragged.classList.remove('processing');
      await refresh();
    }
  }

  if (
    !reportSettings.sqlBoards ||
    reportSettings === null ||
    reportSettings === undefined
  ) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container>
      <Kanban
        mobileTitle={t('Kanban')}
        propBoardKey="Stage"
        propPercent="Percent"
        propElementKey={reportSettings.keyField}
        propElementLinkBoard="Stage"
        boards={kanban.boards}
        elements={kanban.cards}
        elementTitle="CardName"
        elementStatusColor="Color"
        isReadOnly={false}
        enableAdd={reportSettings.enableAdd}
        onAdd={onAdd}
        onDrop={onDrop}
        cardButtons={cardButtons}
        renderTitle={board => {
          if (!board) return null;

          try {
            const boardTemplate = boardTemplateFunction({
              ...settings.dynamicFunctionProps,
              data: board,
            });

            return (
              <CardTemplate
                key={board[reportSettings.keyField]}
                template={boardTemplate}
                data={board}
                style={{
                  padding: 0,
                  backgroundColor: 'transparent',
                }}
              />
            );
          } catch (e) {
            const errorDesc = TreatError.getDescription(e);
            return <Error>{errorDesc}</Error>;
          }
        }}
        isCardTemplate={!!reportSettings.cardTemplate}
        isBoardTemplate={!!reportSettings.boardTemplate}
        renderElement={element => {
          try {
            const cardTemplate = cardTemplateFunction({
              ...settings.dynamicFunctionProps,
              data: element,
            });

            return (
              <CardTemplate
                key={element[reportSettings.keyField]}
                template={cardTemplate}
                data={element}
                style={{
                  padding: 0,
                  background: 'transparent',
                }}
              />
            );
          } catch (e) {
            const errorDesc = TreatError.getDescription(e);
            return <Error>{errorDesc}</Error>;
          }
        }}
        onClick={async element => {
          await executeEvent({
            eventName: 'onClick',
            run: 'after',
            data: {
              element,
            },
          });
        }}
        refresh={refresh}
      />
      {reportSettings.showSummary && (
        <Summary>{`${kanban.cards.length} ${t('Record')}`}</Summary>
      )}
    </Container>
  );
}

export default KanbanPage;

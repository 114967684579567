import styled, { css } from 'styled-components';
import { isMobile } from '~/easy-components/DeviceDetect';

const mobile = css`
  flex: 1;
  border-left: none;
`;

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  width: 350px;
  border-left: 1px solid #ddd;
  background-color: #f7f7f7;
  overflow: auto;

  ${isMobile && mobile}

  > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  > div {
    display: flex;
    flex-direction: row;

    textarea {
      background-color: #fff;
      flex: 1;
      border: none;
      padding: 10px;
      color: #333;

      &::placeholder {
        color: #999;
      }
    }
  }
`;

export const MessageText = styled.div`
  background-color: #fff;
  border-top: 1px solid #ddd;
  height: 61px;
  padding: 5px;
  display: ${props => (props.isVisible ? 'flex' : 'none')} !important;

  button {
    border: none;
    background-color: transparent;
    color: #fff;
    padding: 10px;
  }
`;

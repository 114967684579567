import styled from 'styled-components';
import { isMobile } from '~/easy-components/DeviceDetect';
import { colors } from '~/easy-components/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

export const ReportPage = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  overflow: auto;
`;
export const PanelSide = styled.div`
  width: 300;
  overflow: auto;
  display: flex;
`;

export const Loading = styled.div`
  flex: 1;
  display: ${props => (props.isLoading ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  height: ${!isMobile ? '94%' : '100%'};
  background: #dcdcdcc7;
  z-index: 1;

  @media (max-width: 500px) {
    position: fixed;
  }

  h1 {
    color: #8197a5;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
  }

  h2 {
    color: #496774;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
  }

  h3 {
    color: #496774;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
  }

  @keyframes gira {
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: gira 8s linear infinite;
    margin-bottom: 20px;
  }
`;

export const ErrorScreen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: #496774;
    font-size: 1.4rem;
    font-weight: 400;
  }

  span {
    margin-top: 10px;
    color: #dd6774;
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 44px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;

  @media (max-width: 500px) {
    background-color: #f7f7f7;
  }

  button {
    margin-left: 10px;
  }
`;

export const BackButton = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.main};
  cursor: pointer;
`;

export const MessageFile = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #496774;
  font-size: 1.3rem;
  font-weight: 400;
`;

// @ts-check

import React, { useContext } from 'react';
import InputTable from '~/easy-components/Form/InputTable';

// import { Container } from './styles';
import QueryService from '~/services/QueryService';
import TableMountService from '~/applications/CRM/services/TableMountService';
import { TreatError } from '~/easy-components';
import { useDispatch } from 'react-redux';
import { JsonPageContext } from '../../context';

/**
 * @typedef {Object} TablePageProps
 * @property {string} name
 * @property {object} settings
 * @property {React.MutableRefObject<import('@unform/core').FormHandles>} formRef
 * @property {object} tableSettings
 * @property {object} auxScope
 */

/**
 * @param {TablePageProps} props
 */
function TablePage({ name, settings, formRef, tableSettings, auxScope }) {
  const { showLoading, t } = useContext(JsonPageContext);
  const dispatch = useDispatch();
  const callQueryCode = ({ filter, queryCode, sql, rowKeyValue, rowData }) => {
    const dataToReplace = {
      ...rowData,
      filter,
      rowKeyValue,
    };

    if (queryCode) {
      return QueryService.executeByCode(queryCode, dataToReplace);
    }

    if (sql) {
      return QueryService.execute(1, sql, dataToReplace);
    }

    return [];
  };

  const mountTableSettings = async () => {
    try {
      const data = formRef?.current?.getFieldValue(name) || [];

      const newTableSettings = await TableMountService.execute({
        data,
        settings: tableSettings,
      });

      return newTableSettings;
    } catch (error) {
      TreatError.showError(error);
      return null;
    }
  };

  return (
    <InputTable
      key={name}
      // @ts-ignore
      name={name}
      formRef={formRef}
      t={t}
      settings={settings}
      appDispatch={dispatch}
      showLoading={showLoading}
      onOpenInputLinkerInLine={callQueryCode}
      onGetTableSettings={mountTableSettings}
      auxScope={auxScope}
    />
  );
}

export default TablePage;

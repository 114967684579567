import styled, { css } from 'styled-components';
import { isMobile } from '~/easy-components/DeviceDetect';

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

export const ListElement = styled.div`
  background-color: ${prop => (prop.selected ? '#deeff6' : '#fff')};
  color: #555;

  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  opacity: ${props => props.opacity || 1};

  ${!isMobile &&
    css`
      &:hover {
        background-color: #deeff6;
      }

      :active {
        background-color: #deeff6;
      }
    `}
`;

export const Close = styled.button`
  z-index: 10;
  background-color: #449dc1;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogsButton = styled.button`
  border: none;
  background-color: transparent !important;
`;

/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { isMobile } from '~/easy-components/DeviceDetect';
import useStateCache from '~/hooks/useStateCache';
import { sendData } from '~/store/modules/log/actions';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useLocale from '~/hooks/useLocale';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import QueryService from '../../../services/QueryService';
import PageContext from './context';
import Modal from '../../Modal';
import Switch from '../../Switch';
import TreatError from '../../TreatError';
import SelectItem from '../../SelectItem';
import { sendEvent } from '../../HandlerEvent';
import UserField from '../UserField';
import { FieldContainer, Title } from '../styles';
import { PanelInput, SearchIcon } from './styles';
import MobileView from './MobileView';
import Detail from './MobileView/Detail';
import Elements from './Elements';

function TagElement({
  baseName,
  name: valueField,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  method: clientMethod,
  sql,
  onChange,
  fixedData,
  fieldDefaultValue,
  propFieldValue = 'value',
  propFieldLabel = 'label',
  renderItem,
  separator = ',',
  auxScope,
  canAddNew = false,
  canEdit = false,
  canDelete = false,
  onEdit,
  onDelete,
  required = false,
  isUserField,
  customTabName,
  ...rest
}) {
  const t = useLocale('_Global');

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    baseName,
    name: valueField,
    title: label,
    isUserField,
    customTabName,
  });

  const [isShowSearchViewer, setIsShowSearchViewer] = useState(false);
  const [options, setOptions] = useState([]);

  const valueRef = useRef(null);

  const fieldTitle = selfField.title || label;

  const {
    fieldName: valueFieldName,
    defaultValue: valueDefaultValue = fieldDefaultValue || null,
    registerField: valueRegisterField,
    error,
  } = useField(valueField);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef: valueRef,
    getLogData: () => {
      return {
        baseName,
        name: valueFieldName,
        value: valueRef.current.value,
      };
    },
  });

  const defaultSplitted =
    valueDefaultValue !== null && valueDefaultValue !== undefined
      ? valueDefaultValue.split(separator)
      : [];

  const [selectedValues, setSelectedValues, getSelectedValues] = useStateCache(
    defaultSplitted
  );

  useEffect(() => {
    valueRegisterField({
      name: valueFieldName,
      ref: valueRef.current,
      path: 'value',
      setValue: (el, v) => {
        let textValue = v === '' ? null : v;

        if (v !== null && v !== '' && typeof v === 'object') {
          setOptions(v.options || []);
          textValue = v.values || null;
        }

        const splitted =
          textValue !== null && textValue !== undefined
            ? textValue.split(separator)
            : null;
        setSelectedValues(splitted || []);

        el.value = textValue;
      },
      getValue: () => {
        const values = getSelectedValues().join(separator);
        return values;
      },
    });
  }, []);

  const execute = async () => {
    if (fixedData) {
      setOptions(fixedData);
    } else if (sql || sql === '') {
      if (sql !== '') {
        const newSql = await handlerEvent('onExecuteSql', sql, 'before');
        QueryService.execute(1, newSql).then(response => {
          setOptions(response);
        });
      }
    } else {
      method().then(response => {
        setOptions(response);
      });
    }
  };

  useEffect(() => {
    execute();
  }, []);

  function handleRenderItem(data, selected) {
    return (
      <SelectItem
        isShowSelection
        auxInfo={data.auxInfo || data.AuxInfo}
        header={data[propFieldLabel]}
        selected={selected}
      />
    );
  }

  const handlerEvent = async (eventName, params, run) => {
    const response = await sendEvent({
      settings,
      eventName,
      params,
      run,
      formRef,
      mainFormRef,
      element: valueRef.current,
      events: selfField.events,
      auxScope,
    });

    return response || params;
  };

  async function handleChange(data) {
    try {
      const newValue = await handlerEvent('onchange', data, 'before');

      await onChange(newValue, valueRef);

      await handlerEvent('onchange', newValue, 'after');

      setSelectedValues(newValue);
    } catch (e) {
      TreatError.showError(e);
    }
  }

  async function onSelect(data) {
    setIsShowSearchViewer(false);
    handleChange(data);
  }

  async function method(props) {
    if (clientMethod) {
      const clientResponse = await clientMethod(props);
      return clientResponse;
    }

    return [];
  }

  function onRemove(value) {
    const newSelectedValues = selectedValues.filter(opt => opt !== value);
    setSelectedValues(newSelectedValues);
    onChange({ list: newSelectedValues, data: null });
  }

  try {
    return (
      <>
        <PageContext.Provider
          value={{
            settings,
            setOptions,
            onRemove,
          }}
        >
          <input
            ref={valueRef}
            id={valueFieldName}
            defaultValue={valueDefaultValue}
            hidden
          />

          <FieldContainer
            hidden={isHidden}
            viewMode={!hidden || isUserField ? viewMode : 'normal'}
            onContextMenu={event => showContextMenu({ event })}
            readOnly={isReadOnly}
            style={
              isMobile
                ? {
                    ...(selfField && selfField.style ? selfField.style : {}),
                    ...(rest && rest.style ? rest.style : {}),
                  }
                : {}
            }
          >
            {label && (
              <Title
                highlight={isUserField && viewMode === 'design'}
                labelWidth={
                  (selfField && selfField.labelWidth) || labelWidth || 130
                }
              >
                <div className="title">
                  <Switch
                    mobile={
                      <div className="title">
                        <div>
                          {fieldTitle}
                          {isRequired && <b>*</b>}
                        </div>
                      </div>
                    }
                    computer={
                      <>
                        <div className="title">
                          <div className="required-field">
                            {fieldTitle}
                            {isRequired && <b>*</b>}
                          </div>
                        </div>
                      </>
                    }
                  />

                  <div className="title-icon">
                    <CustomEvent
                      settings={settings}
                      selfField={selfField}
                      inputRef={valueRef}
                      formRef={formRef}
                      mainFormRef={mainFormRef}
                      auxScope={auxScope}
                    />
                  </div>
                  <HasEventComponent
                    selfField={selfField}
                    viewMode={viewMode}
                  />
                </div>
                {error && <div className="error">{error}</div>}
              </Title>
            )}

            <Switch
              mobile={
                <>
                  <MobileView
                    t={t}
                    title={label}
                    text=""
                    fixedData={options}
                    method={null}
                    renderItem={renderItem || handleRenderItem}
                    readOnly={isReadOnly}
                    propFieldLabel={propFieldLabel}
                    propFieldValue={propFieldValue}
                    selValues={selectedValues}
                    onConfirm={async data => {
                      await handleChange(data);
                    }}
                  >
                    <Elements
                      baseName={baseName}
                      onRemove={onRemove}
                      options={options}
                      propFieldValue={propFieldValue}
                      readOnly={isReadOnly}
                      selectedValues={selectedValues}
                      sendData={sendData}
                      separator={separator}
                      showLog={false}
                      valueFieldName={valueFieldName}
                    />
                  </MobileView>
                </>
              }
              computer={
                <PanelInput readOnly={isReadOnly || selfField.disabled}>
                  <div>
                    <Elements
                      baseName={baseName}
                      onRemove={onRemove}
                      options={options}
                      propFieldLabel={propFieldLabel}
                      propFieldValue={propFieldValue}
                      readOnly={isReadOnly}
                      selectedValues={selectedValues}
                      sendData={sendData}
                      separator={separator}
                      valueFieldName={valueFieldName}
                    />
                    {!isReadOnly && !(selfField.disabled || false) && (
                      <SearchIcon
                        onClick={() => {
                          setIsShowSearchViewer(true);
                        }}
                      >
                        <FaPlus size={12} color="#aaaaaa" />
                      </SearchIcon>
                    )}
                  </div>

                  <Modal isOpened={isShowSearchViewer}>
                    {isShowSearchViewer ? (
                      <Detail
                        t={t}
                        propFieldLabel={propFieldLabel}
                        propFieldValue={propFieldValue}
                        selValues={selectedValues}
                        onClose={() => {
                          setIsShowSearchViewer(false);
                        }}
                        onConfirm={onSelect}
                        title={label}
                        text=""
                        fixedData={options}
                        renderItem={renderItem || handleRenderItem}
                        canAddNew={canAddNew}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        onEdit={onEdit}
                        onDelete={onDelete}
                      />
                    ) : (
                      <></>
                    )}
                  </Modal>
                </PanelInput>
              }
            />
          </FieldContainer>

          <UserField
            baseName={baseName}
            fieldName={valueFieldName}
            formRef={formRef}
            mainFormRef={mainFormRef}
            settings={settings}
            // readOnly={isReadOnly}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
          />
        </PageContext.Provider>
      </>
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
    return null;
  }
}

TagElement.prototypes = {
  onChange: PropTypes.func,
  renderItem: PropTypes.func,
  mainFormRef: PropTypes.shape(),
};

TagElement.defaultProps = {
  mainFormRef: {},
  onChange: () => {},
};

export default TagElement;

/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useContext, useRef, useEffect } from 'react';

import { deselectRow } from 'ka-table/actionCreators';

import { isMobile } from '~/easy-components/DeviceDetect';
import { Input } from '../styles';

import { PageContext } from '../index';

import dynamicFunction from '../dynamicFunction';
import validateAndSelectRow from '../CustomActions/validateAndSelectRow';
import useInputNavigation from '../hooks/useInputNavigation';

function InputText({
  column,
  rowKeyValue,
  value,
  style,
  getSelectedData,
  rowData,
  onRefresh,
  cellProps,
  readOnly,
  items,
  rawItems,
  isFocused,
  onAddNewLine,
  reportSettings,
}) {
  const {
    dispatch,
    auxScope,
    setRowFieldValue,
    removeLine,
    tableProps,
    refreshTable,
  } = useContext(PageContext);
  const inputRef = useRef();
  const inputChanged = useRef(true);

  const {
    inputNavigationOnKeyDown,
    inputNavigationOnFocus,
  } = useInputNavigation({
    columnKey: column.key,
    rowKeyValue,
    tableProps,
    isFocused,
    dispatch,
    onAddNewLine,
    currentInputRef: inputRef,
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value =
        value === null || value === undefined ? '' : value;
    }
  }, [value]);

  useEffect(() => {
    if (isFocused && !inputChanged.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  });

  const inputReadOnly =
    readOnly || (column.settings && column.settings.readOnly);

  return (
    <Input
      ref={inputRef}
      style={style}
      disabled={column.settings.disabled || false}
      onClick={e => {
        e.stopPropagation();

        if (isMobile) {
          inputRef.current.focus();
        }
      }}
      readOnly={inputReadOnly}
      inputNavigationOnFocus={inputNavigationOnFocus}
      onBlur={async () => {
        if (inputReadOnly) return false;

        if (!inputChanged.current) return false;

        const editorValue = inputRef.current && inputRef.current.value;
        setRowFieldValue(rowKeyValue, column.key, editorValue, false);

        if (column.settings && column.settings.onChange) {
          await dynamicFunction({
            functionString: column.settings.onChange,
            dispatch,
            params: {
              ...auxScope,
              getSelectedData,
              column,
              line: rowData,
              value,
              rowKeyValue,
              refresh: onRefresh,
              currentValue: editorValue,
              items,
              rawItems,
              setFieldValue: (columnKey, newValue, forceUpdate) => {
                setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
              },
              setRowFieldValue,
              refreshTable,
              selectRow: ({ force = false }) => {
                dispatch(
                  validateAndSelectRow({
                    callingFromCell: cellProps,
                    forceSelection: force,
                  })
                );
              },
              deselectRow: () => {
                dispatch(deselectRow(rowKeyValue));
              },
              removeLine,
              reportSettings,
            },
          });
        }
        inputChanged.current = false;
        refreshTable();
        return true;
      }}
      onKeyDown={e => {
        inputNavigationOnKeyDown(e);
        inputChanged.current = true;
      }}
    />
  );
}

export default InputText;

import React, {
  useContext,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import useLocale from '~/hooks/useLocale';
import useHelpers from '~/hooks/useHelpers';

import ClientEventHandler from '~/applications/ClientEventHandler';
import { createAsyncFunctionByString } from '~/easy-components/AsyncFunctionString';
import { Button, Loading } from '~/easy-components';

import WizardPage from '../../Wizard';
import { JsonPageContext } from '../context';
import { JsonPageWizardProvider } from './context';
import PageManager from './PageManager';
import FinishPage from './FinishPage';

function Wizard() {
  /** @type {{settings: any}} */
  const {
    settings: applicationsSettings,
    showLoading: contextShowLoading,
  } = useContext(JsonPageContext);

  const t = useLocale('_Global');

  const loadingRef = useRef(null);

  const wizardRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const settings = useMemo(() => applicationsSettings, []);

  const showLoading = useCallback(isLoading => {
    if (isLoading) {
      return loadingRef.current?.show();
    }

    return loadingRef.current?.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultData = useMemo(() => settings.defaultData || {}, [settings]);

  const { process: helperProcess } = useHelpers();
  const { pages = [] } = settings;

  const process = useCallback(
    async callback => {
      await helperProcess(callback);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showExecutionPage = useCallback(() => {
    wizardRef.current.showExecutionPage();
  }, []);

  const wizardPages = pages.map(pageSetting => {
    return {
      id: pageSetting.name,
      title: pageSetting.title,
      icon: pageSetting.icon,
      isVisible: pageSetting.isVisible,
      onChange: ({ data, formRef }) => {
        process(() =>
          ClientEventHandler({
            eventName: 'onNextPage',
            run: 'before',
            data,
            params: {
              settings: {
                ...settings,
                ...pageSetting,
              },
              formRef,
              form: formRef?.current,
              showLoading,
              showExecutionPage,
            },
          })
        );

        setTimeout(() => {
          process(() =>
            ClientEventHandler({
              eventName: 'onNextPage',
              run: 'after',
              data,
              params: {
                settings: {
                  ...settings,
                  ...pageSetting,
                },
                formRef,
                form: formRef?.current,
                showLoading,
                showExecutionPage,
              },
            })
          );
        }, 500);
      },
      onValidate: ({ data, formRef }) => {
        return ClientEventHandler({
          eventName: 'onValidate',
          run: 'before',
          data,
          params: {
            settings: {
              ...settings,
              ...pageSetting,
            },
            formRef,
            form: formRef?.current,
            showLoading,
            showExecutionPage,
          },
        });
      },
      content: ({ formRef }) => {
        return (
          <PageManager
            type={pageSetting.type}
            formRef={formRef}
            settings={settings}
            tableSettings={pageSetting.settings}
            auxScope={{
              ...settings.dynamicFunctionProps,
              showExecutionPage,
            }}
            {...pageSetting}
          />
        );
      },
    };
  });

  const setPageVisible = useCallback(
    (pageName, visible) => {
      const pageIndex = wizardPages.findIndex(page => page.id === pageName);

      if (visible) {
        return wizardRef.current?.setPageVisible(pageIndex);
      }

      return wizardRef.current?.setPageInvisible(pageIndex);
    },
    [wizardPages]
  );

  const isPageVisible = useCallback(
    pageName => {
      const pageIndex = wizardPages.findIndex(page => page.id === pageName);

      return wizardRef.current?.isPageVisible(pageIndex);
    },
    [wizardPages]
  );

  const executeFunction = useCallback(
    async (functionToInvoke, params) => {
      const func = createAsyncFunctionByString({
        functionString: functionToInvoke || '',
      });

      await func({
        ...settings.dynamicFunctionProps,
        setPageVisible,
        isPageVisible,
        showExecutionPage,
        showLoading: contextShowLoading,
        ...params,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPageVisible, setPageVisible, settings.dynamicFunctionProps]
  );

  const callOnFinishFunction = useCallback(
    async ({ data, formRef }) => {
      await executeFunction(settings.onFinish, { data, formRef });
    },
    [executeFunction, settings]
  );

  const callEnterPageEvent = useCallback(
    async (run, { formRef, pageIndex }) => {
      const pageSettings = pages[pageIndex];

      let auxScope = {};

      if (pageSettings.type === 'table') {
        auxScope = {
          setTableData: newData => {
            formRef.current.setFieldValue(pageSettings.name, newData);
          },
          getTableData: () => {
            return formRef.current.getFieldValue(pageSettings.name);
          },
          setTableSettings: newSettings => {
            const inputTableRef = formRef.current.getFieldRef(
              pageSettings.name
            );

            if (!inputTableRef) {
              return;
            }

            inputTableRef.setSettings(newSettings);
          },
        };
      }

      await ClientEventHandler({
        eventName: 'onEnterPage',
        run,
        data: formRef?.current?.getData() || null,
        params: {
          settings: {
            ...settings,
            ...pageSettings,
          },
          formRef,
          form: formRef?.current,
          pageIndex,
          page: pageSettings,
          setPageVisible,
          isPageVisible,
          showLoading,
          showExecutionPage,
          ...auxScope,
        },
      });
    },
    [
      isPageVisible,
      pages,
      setPageVisible,
      settings,
      showExecutionPage,
      showLoading,
    ]
  );

  const callOnBeforeRenderEvent = useCallback(
    async ({ formRef, pageIndex }) => {
      if (pageIndex !== 0) {
        return;
      }

      await ClientEventHandler({
        eventName: 'onRender',
        run: 'before',
        data: formRef?.current?.getData() || null,
        params: {
          settings,
          formRef,
          form: formRef?.current,
          setPageVisible,
          isPageVisible,
          showLoading,
          showExecutionPage,
        },
      });
    },
    [isPageVisible, setPageVisible, settings, showExecutionPage, showLoading]
  );

  const callOnAfterRenderEvent = useCallback(
    async ({ formRef, pageIndex }) => {
      if (pageIndex !== 0) {
        return;
      }

      await ClientEventHandler({
        eventName: 'onRender',
        run: 'after',
        data: formRef?.current?.getData() || null,
        params: {
          settings,
          formRef,
          form: formRef?.current,
          setPageVisible,
          isPageVisible,
          showLoading,
          showExecutionPage,
        },
      });
    },
    [isPageVisible, setPageVisible, settings, showExecutionPage, showLoading]
  );

  const callOnBeforeEnterPage = useCallback(
    async ({ formRef, pageIndex, page }) => {
      await callEnterPageEvent('before', { formRef, pageIndex, page });
    },
    [callEnterPageEvent]
  );

  const callOnAfterEnterPage = useCallback(
    async ({ formRef, pageIndex, page }) => {
      await callEnterPageEvent('after', { formRef, pageIndex, page });
    },
    [callEnterPageEvent]
  );

  useEffect(() => {
    if (!wizardRef.current) {
      return;
    }

    wizardRef.current.refreshList();
  }, []);

  const executionPage = () => (
    <FinishPage
      message={settings.executionPageMsg || t('SystemMessageSuccess')}
    />
  );

  return (
    <JsonPageWizardProvider
      callEnterPageEvent={callEnterPageEvent}
      executeFunction={executeFunction}
      wizardPages={wizardPages || []}
    >
      <WizardPage
        ref={wizardRef}
        keyField="id"
        settings={settings}
        showLoading={showLoading}
        defaultData={defaultData}
        previousButtonLabel={settings.navigation?.previousButton?.label}
        nextButtonLabel={settings.navigation?.nextButton?.label}
        finishButtonLabel={settings.navigation?.finishButton?.label}
        onFinish={callOnFinishFunction}
        pages={wizardPages || []}
        onBeforeRender={callOnBeforeRenderEvent}
        onAfterRender={callOnAfterRenderEvent}
        onBeforeEnterPage={callOnBeforeEnterPage}
        onAfterEnterPage={callOnAfterEnterPage}
        executionPage={executionPage}
        executionFooterButtons={() => (
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('New')}
          </Button>
        )}
      />
      <Loading ref={loadingRef} />
    </JsonPageWizardProvider>
  );
}

export default Wizard;

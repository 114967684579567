import React, { useContext, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-cycle
import ImageViewer from '~/easy-components/ImageViewer';
import AuxInfo from '~/easy-components/AuxInfo';
import NoImage from '~/assets/no-image.png';
import useCompany from '~/hooks/useCompany';

import { isMobile } from 'react-device-detect';
import {
  Container,
  Content,
  ContentView,
  Description,
  InfoProducts,
  Title,
  Value,
  Checkbox,
  BoxSet,
  FirstLine,
  SecondLine,
  Trace,
  DeclareFields,
  Essential,
  Capsule,
} from './styles';
import { ModalCatalogContext } from '../../context';
import QuantitySelector from '../QuantitySelector';
import CustomForm from '../CustomForm';

/**
 * @typedef {Object} ProductProps
 * @property {string} imageSrc
 * @property {string} title
 * @property {string} description
 * @property {string} value
 
 
 */

/**
 * @param {ProductProps} props
 */

function Product({ item, catalogCustomFields }) {
  const customFormRef = useRef();

  const {
    selectedItems,
    setSelectedItems,
    currency,
    allItems,
    setItems,
  } = useContext(ModalCatalogContext);
  const isSelectedItem = selectedItems.some(
    si => si.ItemCode === item.ItemCode
  );

  const selectedItem = isSelectedItem
    ? selectedItems.find(si => si.ItemCode === item.ItemCode)
    : null;

  function selectItem(quantity = 1) {
    let customFormData = {};
    if (catalogCustomFields) {
      customFormData = customFormRef.current.getData();
    }
    const updatedItems = [...selectedItems];
    updatedItems.push({
      ...item,
      ...customFormData,
      Quantity: quantity,
    });

    setSelectedItems(updatedItems);
  }

  function removeItem() {
    const newItems = selectedItems.filter(si => si.ItemCode !== item.ItemCode);
    setSelectedItems(newItems);
  }

  function changeQuantity(quantity) {
    const newItems = allItems.map(i => {
      if (i.ItemCode === item.ItemCode) {
        return {
          ...i,
          Quantity: quantity,
        };
      }

      return i;
    });

    setItems(newItems);

    const isItemInSelectedItems = selectedItems.some(
      si => si.ItemCode === item.ItemCode
    );

    if (isItemInSelectedItems && quantity === 0) {
      removeItem();
      return;
    }

    if (!isItemInSelectedItems && quantity > 0) {
      selectItem(quantity);
    }

    if (isItemInSelectedItems && quantity > 0) {
      const updatedItems = selectedItems.map(si => {
        if (si.ItemCode === item.ItemCode) {
          return {
            ...si,
            Quantity: quantity,
          };
        }

        return si;
      });

      setSelectedItems(updatedItems);
    }
  }

  function changeUnitPrice(unitPrice) {
    const newItems = allItems.map(i => {
      if (i.ItemCode === item.ItemCode) {
        return {
          ...i,
          UnitPrice: unitPrice,
        };
      }

      return i;
    });

    setItems(newItems);

    const isItemInSelectedItems = selectedItems.some(
      si => si.ItemCode === item.ItemCode
    );

    if (!isItemInSelectedItems) {
      return;
    }

    const updatedItems = selectedItems.map(si => {
      if (si.ItemCode === item.ItemCode) {
        return {
          ...si,
          UnitPrice: unitPrice,
        };
      }

      return si;
    });

    setSelectedItems(updatedItems);
  }

  function handleInputChange(fieldName, value) {
    if (fieldName === 'Quantity') {
      changeQuantity(value);
      return;
    }

    if (fieldName === 'UnitPrice') {
      changeUnitPrice(value);
      return;
    }

    if (isSelectedItem) {
      const updatedItems = selectedItems.map(si => {
        if (si.ItemCode === item.ItemCode) {
          return {
            ...si,
            [fieldName]: value,
          };
        }

        return si;
      });

      setSelectedItems(updatedItems);
    }
  }

  useEffect(() => {
    if (!customFormRef.current) {
      return;
    }
    const hasQuantityCustomField = catalogCustomFields.some(
      field => field.name === 'Quantity'
    );

    let quantityCustomFieldValue = 0;

    if (hasQuantityCustomField) {
      quantityCustomFieldValue =
        customFormRef.current.getFieldValue('Quantity') || 0;
    }

    const isItemInSelectedItems = selectedItems.some(
      si => si.ItemCode === item.ItemCode
    );

    if (!isItemInSelectedItems && quantityCustomFieldValue > 0) {
      customFormRef.current.setFieldValue('Quantity', 0);
    }
  }, [catalogCustomFields, item.ItemCode, selectedItems]);

  const { numberToString } = useCompany();
  return (
    <Container>
      <Capsule setItem={isSelectedItem}>
        <Content>
          <ContentView>
            <ImageViewer
              src={`${item.U_AHS_Image || NoImage}`}
              width="8rem"
              height="8rem"
            />
            <InfoProducts setItem={isSelectedItem}>
              <FirstLine>
                <Essential>
                  <Title setItem={isSelectedItem}>{item.ItemName}</Title>
                  <Description>
                    {item.Category} / {item.ItemCode}
                  </Description>
                  <Value setItem={isSelectedItem}>
                    {` ${currency} ${numberToString(
                      item.UnitPrice || 0,
                      'price'
                    )}`}
                  </Value>
                </Essential>
                <Trace>
                  <AuxInfo text={item.AuxInfo || item.auxInfo} />
                </Trace>
              </FirstLine>
              <SecondLine>
                {catalogCustomFields && catalogCustomFields.length > 0 ? (
                  <DeclareFields>
                    {!isMobile && (
                      <CustomForm
                        ref={customFormRef}
                        catalogCustomFields={catalogCustomFields}
                        handleInputChange={handleInputChange}
                        item={{ ...item, ...(selectedItem || {}) }}
                      />
                    )}
                  </DeclareFields>
                ) : (
                  <QuantitySelector
                    item={item}
                    catalogCustomFields={catalogCustomFields}
                  />
                )}
              </SecondLine>
            </InfoProducts>
          </ContentView>

          <BoxSet>
            <Checkbox
              type="checkbox"
              checked={selectedItems.some(si => si.ItemCode === item.ItemCode)}
              onChange={e => {
                if (e.target.checked) {
                  selectItem();
                  return;
                }
                removeItem();
              }}
            />
          </BoxSet>
        </Content>
        {isMobile && catalogCustomFields && catalogCustomFields.length > 0 && (
          <DeclareFields>
            <CustomForm
              ref={customFormRef}
              catalogCustomFields={catalogCustomFields}
              handleInputChange={handleInputChange}
              item={{ ...item, ...(selectedItem || {}) }}
            />
          </DeclareFields>
        )}
      </Capsule>
    </Container>
  );
}

export default Product;

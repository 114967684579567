import React, { useContext } from 'react';
import { ModalCatalogContext } from '../../context';

const { Quantity, ButtonControl, InputQuantity } = require('./styles');

/**
 * @param {Object} props
 * @param {Object} props.item
 */

function QuantitySelector({ item }) {
  const { selectedItems, setSelectedItems } = useContext(ModalCatalogContext);

  const selectedItem = selectedItems.find(i => i.ItemCode === item.ItemCode);

  function handleDecrease() {
    let updatedItems = [...selectedItems];

    if (!selectedItem) {
      return;
    }

    if (selectedItem) {
      updatedItems = updatedItems.map(i =>
        i.ItemCode === item.ItemCode ? { ...i, Quantity: i.Quantity - 1 } : i
      );
    }

    updatedItems = updatedItems.filter(i => i.Quantity > 0);

    setSelectedItems(updatedItems);
  }

  function handleIncrease() {
    let updatedItems = [...selectedItems];

    if (!selectedItem) {
      updatedItems.push({ ...item, Quantity: 1 });
    }

    if (selectedItem) {
      updatedItems = updatedItems.map(i =>
        i.ItemCode === item.ItemCode ? { ...i, Quantity: i.Quantity + 1 } : i
      );
    }

    setSelectedItems(updatedItems);
  }

  function handleQuantityChange(e) {
    const value = Number(e.target.value);
    let updatedItems = [...selectedItems];

    if (value >= 0) {
      if (selectedItem) {
        updatedItems = updatedItems.map(i =>
          i.ItemCode === item.ItemCode ? { ...i, Quantity: value } : i
        );
      } else if (value > 0) {
        updatedItems.push({ ...item, Quantity: value });
      }

      setSelectedItems(updatedItems);
    }
  }
  return (
    <Quantity className="quantity-content">
      <ButtonControl onClick={() => handleDecrease()}>-</ButtonControl>
      <InputQuantity
        name="quantity"
        type="text"
        inputMode="numeric"
        min="0"
        value={selectedItem ? selectedItem.Quantity : 0}
        onChange={handleQuantityChange}
      />
      <ButtonControl onClick={() => handleIncrease()}>+</ButtonControl>
    </Quantity>
  );
}

export default QuantitySelector;

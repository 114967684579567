/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React, { useContext } from 'react';

import { deselectRow } from 'ka-table/actionCreators';
import { isMobile } from '~/easy-components/DeviceDetect';
import { DisplayCell, Cell, AppendButton, FakeInput } from '../styles';
import Icon from '../../Icon';
import dynamicFunction from '../dynamicFunction';
import { PageContext } from '../index';
import validateAndSelectRow from '../CustomActions/validateAndSelectRow';
import Symbol from '../Components/Symbol';

import useCompany from '~/hooks/useCompany';

export function formatNumber(value, digits = 2) {
  if (value === null || value === undefined) return '';

  const newValue = new Intl.NumberFormat('id', {
    minimumFractionDigits: digits,
  }).format(+value);

  return newValue;
}

function CustomCells({
  value,
  isModal,
  column,
  dispatch,
  rowData,
  rowKeyValue,
  onRefresh,
  style,
  cellProps,
  isFakeInput,
  readOnly,
  items,
  rawItems,
}) {
  const { numberToString } = useCompany();

  const hasAppendButton =
    !isMobile &&
    column &&
    column.settings &&
    column.settings.appendButton &&
    column.settings.appendButton.icon;

  const appendButton = hasAppendButton ? column.settings.appendButton : {};
  // eslint-disable-next-line no-unused-vars
  const { onClick, icon, ...iconProps } = appendButton;

  const {
    auxScope,
    setRowFieldValue,
    removeLine,
    t,
    refreshTable,
  } = useContext(PageContext);

  const symbol = column && column.settings && column.settings.symbol;

  const minWidth = symbol && '100px';

  const Content = () => (
    <>
      <span>
        {numberToString(
          value,
          column.settings.numberType ? column.settings.numberType : 'value',
          column.settings.digits
        )}
      </span>
      {hasAppendButton && (
        <AppendButton
          onClick={async () => {
            if (appendButton && appendButton.onClick) {
              await dynamicFunction({
                functionString: appendButton.onClick,
                dispatch,
                params: {
                  ...auxScope,
                  column,
                  line: rowData,
                  items,
                  rawItems,
                  value,
                  rowKeyValue,
                  refresh: onRefresh,
                  refreshTable,
                  setFieldValue: (columnKey, newValue, forceUpdate) => {
                    setRowFieldValue(
                      rowKeyValue,
                      columnKey,
                      newValue,
                      forceUpdate
                    );
                  },
                  setRowFieldValue,
                  selectRow: ({ force = false }) => {
                    dispatch(
                      validateAndSelectRow({
                        callingFromCell: cellProps,
                        forceSelection: force,
                      })
                    );
                  },
                  deselectRow: () => {
                    dispatch(deselectRow(rowKeyValue));
                  },
                  removeLine,
                },
              });
            }
          }}
        >
          <Icon name={appendButton.icon} {...iconProps} />
        </AppendButton>
      )}
    </>
  );

  return (
    <Cell
      align={symbol ? 'space-between' : 'flex-end'}
      direction={symbol ? 'row' : 'column'}
    >
      {value !== 0 && value !== null && symbol && !isFakeInput && (
        <Symbol
          title={
            column &&
            column.settings &&
            (column.settings.title ||
              t(
                column.settings.translateKey || column.settings.name,
                column.settings.translateRoute
              ))
          }
          enableChange={false}
          symbol={symbol}
          onSearch={() => {}}
          onSelected={() => {}}
        />
      )}
      <DisplayCell
        style={{
          justifyContent: isModal ? 'flex-start' : 'flex-end',
          alignItems: 'center',
          minWidth,
          ...style,
        }}
      >
        {isFakeInput ? (
          <FakeInput
            readOnly={readOnly}
            style={{
              display: 'flex',
              justifyContent: symbol ? 'space-between' : 'flex-end',
              alignItems: 'center',
              minWidth,
              ...style,
            }}
          >
            {value !== 0 && value !== null && symbol && (
              <Symbol
                title={
                  column &&
                  column.settings &&
                  (column.settings.title ||
                    t(
                      column.settings.translateKey || column.settings.name,
                      column.settings.translateRoute
                    ))
                }
                enableChange={false}
                symbol={symbol}
                onSearch={() => {}}
                onSelected={() => {}}
              />
            )}
            <Content />
          </FakeInput>
        ) : (
          <Content />
        )}
      </DisplayCell>
    </Cell>
  );
}

export default CustomCells;

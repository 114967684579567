import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: ${props => (props.open ? '360px' : '0')};
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;

  top: 0;
  background-color: white;
  transition: width 0.2s ease;
  overflow: hidden;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 500px) {
    position: absolute;
    width: ${props => (props.open ? '100%' : '0')};
    right: 0;
    top: 0;
  }
`;
export const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 16px;
  height: 68px;
  border-bottom: 1px solid #e9edef;
`;
export const TitleModel = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: #888;
  @media (max-width: 500px) {
    font-size: 12px;
    color: #fff;
  }
`;

export const CloseButton = styled.button`
  display: none;

  @media (max-width: 500px) {
    display: block;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

export const HeaderProduct = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;
export const ButtonTrash = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #888;
  margin-left: auto;
  width: 20px;
  height: 20px;
`;
export const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9edef;

  gap: 1rem;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: #888;
`;
export const Info = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: #888;
`;

export const Description = styled.h3`
  font-size: 1rem;
  font-weight: 400;
`;

export const FooterPrice = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const Price = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const NoItems = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
`;

export const Total = styled.h3`
  background-color: ${colors.slate};
  padding: 20px;
`;

export const TotalTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #888;
`;

export const TotalValue = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: ${colors.primary};
`;

import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
`;

export const Checkbox = styled.input`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 20px;
  overflow: hidden;
  border-radius: 5px;
`;

import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import Product from '../Product';

import { ProductsGrid } from './styles';
import { ModalCatalogContext } from '../../context';

/**
 * @param {Object} props
 * @param {string} itemKeyField
 */
function Products({ itemKeyField, catalogCustomFields }) {
  const { filteredItems } = useContext(ModalCatalogContext);

  return (
    <ProductsGrid>
      {filteredItems.map(item => (
        <Product
          key={item[itemKeyField]}
          item={item}
          catalogCustomFields={catalogCustomFields}
        />
      ))}
    </ProductsGrid>
  );
}

export default Products;

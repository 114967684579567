// @ts-nocheck

import React, { useContext } from 'react';
import HeaderPage from '~/easy-components/HeaderPage';
import colors from '~/styles/colors';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
// eslint-disable-next-line import/no-cycle
import { Button } from '~/easy-components';
import { isMobile } from 'react-device-detect';
import Filter from './Filter';

import {
  Container,
  CategoryContainer,
  CategoryTitle,
  Content,
  HeaderTitle,
} from './styles';
import { ModalCatalogContext } from '../../context';

/**
 * @typedef {Object} FiltersProps
 * @property {{filter: string, category: string, categoryTitle: string}[]} data
 * @property {import('i18next').TFunction} t
 */

/**
 * @typedef {Object} CategoryGroup
 * @property {string} category
 * @property {string} categoryTitle
 * @property {string[]} filters
 */

/**
 * Agrupa os filtros por categoria.
 *
 * @param {FiltersProps['data']} data
 *
 * @returns {CategoryGroup[]}
 */
function groupFilters(data) {
  const groups = data.reduce((acc, { filter, category, categoryTitle }) => {
    const group = acc.find(g => g.category === category);

    if (group) {
      group.filters.push(filter);
    } else {
      acc.push({ category, categoryTitle, filters: [filter] });
    }

    return acc;
  }, []);

  return groups;
}

/**
 * @param {FiltersProps} props
 */
function Filters({ data, t }) {
  const groups = groupFilters(data);

  const { setFilters, filters, setOpenFilters, openFilters } = useContext(
    ModalCatalogContext
  );

  /**
   * @param {string} category
   * @param {string} filter
   * @param {boolean} checked
   */
  function handleFilterChange(category, filter, checked) {
    setFilters(prev => {
      const newState = { ...prev };

      if (!newState[category]) {
        newState[category] = [];
      }

      if (checked) {
        return {
          ...newState,
          [category]: [...newState[category], filter],
        };
      }

      return {
        ...newState,
        [category]: newState[category].filter(f => f !== filter),
      };
    });
  }
  function handlerClose() {
    setOpenFilters(false);
  }

  return (
    <Container open={openFilters}>
      <HeaderPage
        backgroundColor={isMobile ? colors.headerBg : colors.slate}
        color={colors.headerTitle}
        hideMainMenu
        viewMode="default"
      >
        <HeaderTitle>{t('Filters').toUpperCase()}</HeaderTitle>
      </HeaderPage>

      <Content>
        {groups.map(
          ({ category, categoryTitle, filters: filtersInCategory }) => (
            <CategoryContainer key={category}>
              <CategoryTitle>{categoryTitle}</CategoryTitle>

              {filtersInCategory.map(filter => {
                const isChecked = filters[category]?.includes(filter) || false;

                return (
                  <Filter
                    key={filter}
                    title={filter}
                    isChecked={isChecked}
                    onFilterChange={checked => {
                      handleFilterChange(category, filter, checked);
                    }}
                  />
                );
              })}
            </CategoryContainer>
          )
        )}
      </Content>
      {isMobile && (
        <FooterPage>
          <FlexSpace />

          <Button type="button" onClick={handlerClose}>
            {t('Close')}
          </Button>
        </FooterPage>
      )}
    </Container>
  );
}

export default Filters;

/* eslint-disable import/no-cycle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/prop-types */
import axios from 'axios';
import * as dateFns from 'date-fns';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  createContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isSafari, isMobile } from '~/easy-components/DeviceDetect';
import { Decimal } from 'decimal.js';
import ClientEventHandler from '~/applications/ClientEventHandler';
import { showPage, showReport } from '~/applications/ControlPage';
import DocumentService from '~/applications/CRM/services/DocumentService';
import TaxCodeService from '~/applications/CRM/services/TaxCodeService';
import TaxCodeDeterminationService from '~/applications/CRM/services/TaxCodeDeterminationService';
import request from '~/applications/Request';
import Loading from '~/easy-components/Loading';
import TreatError from '~/easy-components/TreatError';
import DisplayModal from '~/easy-components/DisplayModal';
import FileModal from '~/easy-components/FileModal';
import { fireEvent } from '~/easy-components/Form/sharedFunctions';
import ModalFields from '~/easy-components/ModalFields';
import ModalCamera from '~/easy-components/ModalCamera';
import TemplatesModal from '~/easy-components/ModalTemplates';
import ModalMap from '~/easy-components/ModalMap';
import PromptModal from '~/easy-components/PromptModal';
import useCompany from '~/hooks/useCompany';
import history from '~/routes/history';
import ApplicationService from '~/services/ApplicationService';
import AttachmentService from '~/services/AttachmentService';
import ConfigurationsService from '~/services/ConfigurationsService';
import ControllerService from '~/services/ControllerService';
import GeoLocationService from '~/services/GeoLocationService';
import QueryService from '~/services/QueryService';
import { setLoading } from '~/store/modules/loading/actions';
import useLocale from '~/hooks/useLocale';
import BatchNumberSelection from '~/applications/CRM/components/BatchNumberSelection';
import BinLocationSelection from '~/applications/CRM/components/BinLocationSelection';
import ModalCapture from '~/easy-components/ModalCapture';
import { getDocumentLabelFromCode } from '~/enum/DocumentLabels';
import { server_url } from '~/config/application';
import createGlobalFunctions from '~/easy-components/Helpers/createGlobalFunctions';
import normalizeData from '~/easy-components/Helpers/normalizeData';
import normalizeFieldLabel from '~/easy-components/Helpers/normalizeFieldLabel';
import normalizeFieldValue from '~/easy-components/Helpers/normalizeFieldValue';
import isEmptyField from '~/easy-components/Helpers/isEmptyField';
import ModalEmailTemplates from '~/applications/CRM/components/ModalEmailTemplates';
import ApplicationModal from '~/hooks/useUiDesigner/Modals/ApplicationModal';
import QueryModal from '~/hooks/useUiDesigner/Modals/QueryModal';
import EventModal from '~/hooks/useUiDesigner/Modals/EventModal';
import UserfieldModal from '~/hooks/useUiDesigner/Modals/UserfieldModal';
import ComponentModal from '~/hooks/useUiDesigner/Modals/ComponentModal';
import ListModal from '~/hooks/useUiDesigner/Modals/ListModal';
import PopupSettings from '~/applications/CRM/components/PopupSettings';
import { updateSettings as updateSettingsAction } from '~/store/modules/uiDesign/actions';
import Log from '~/components/Log';
import ReportModal from '~/hooks/useUiDesigner/Modals/ReportModal';
import UserPageModal from '~/hooks/useUiDesigner/Modals/UserPageModal';
import EditorModal from '~/hooks/useUiDesigner/Modals/EditorModal';
import SqlModal from '~/hooks/useUiDesigner/Modals/SqlModal';
import { confirmAlert } from 'react-confirm-alert';
import getCoordinates from '~/easy-components/Helpers/mapUrlParser';
import getUtilDayDate from '~/easy-components/Helpers/getUtilDayDate';
import ModalCatalog from '~/easy-components/ModalCatalog';
import { Content } from './styles';

// eslint-disable-next-line no-new-func
const AsyncFunction = new Function(
  `return Object.getPrototypeOf(async function(){}).constructor`
)();

export const PageContext = createContext();

export default function App({ Component, hash, route, location, ...rest }) {
  const dispatch = useDispatch();
  const t = useLocale('_Global');

  const isAppMounted = useRef(false);

  let mainRoute = rest.computedMatch.url.toUpperCase();

  const queryParams = {};

  if (location.search) {
    mainRoute = (route + window.location.search).toUpperCase();

    const urlParams = new URLSearchParams(location.search);

    for (const [key, value] of urlParams) {
      queryParams[key] = value;
    }
  }

  const {
    dateToString,
    stringToDate,
    numberToString,
    stringToNumber,
  } = useCompany();

  const modalFieldsRef = useRef();
  const modalMapRef = useRef();
  const displayModalRef = useRef();
  const fileModalRef = useRef();
  const modalBatchRef = useRef();
  const modalBinLocationRef = useRef();
  const modalCaptureRef = useRef();
  const modalPromptRef = useRef();
  const modalCameraRef = useRef();
  const templatesModalRef = useRef();
  const modalEmailTemplatesRef = useRef();
  const modalApplicationsRef = useRef();
  const modalQueryRef = useRef();
  const modalEventRef = useRef();
  const modalUserfieldRef = useRef();
  const modalComponentRef = useRef();
  const popupSettingsRef = useRef();
  const modalListRef = useRef();
  const modalCatalogRef = useRef();
  const logRef = useRef();
  const modalReportRef = useRef();
  const modalUserPageRef = useRef();
  const modalEditorRef = useRef();
  const modalSqlRef = useRef();

  const showLoading = status => {
    return dispatch(setLoading(status));
  };
  const showConfirmDialog = props => {
    confirmAlert({
      title: props.title,
      message: props.message,
      closeOnClickOutside: props.closeOutside,
      buttons: props.buttons,
    });
  };

  const getApplicationSettings = async ({
    queryParameters = {},
    defaultSettings,
    pageRoute,
    pageHash,
  }) => {
    async function createControllers(settings) {
      const { controllers } = settings;

      if (controllers) {
        for (const prop in controllers) {
          const controllerFunction = new AsyncFunction(
            'scope',
            controllers[prop]
          );
          controllers[prop] = await controllerFunction();
        }

        settings.controllers = controllers;
      }
    }

    const routeSettings = queryParameters.from
      ? queryParameters.from
      : pageRoute;

    const hashSettings = queryParameters.hash ? queryParameters.hash : pageHash;

    const pageConfigurations = await ApplicationService.getByRoute(
      routeSettings,
      hashSettings
    );

    const settings = { ...defaultSettings, ...pageConfigurations };

    // TODO: verificar como fazer para não ter a necessidade de ter o defaultSettings nesse momento,
    // quando estamos a página dentro do modal, novo componente para abrir qualquer página em um modal,
    // ele não funciona.

    if (settings.dynamicFunctionProps) {
      settings.dynamicFunctionProps.settings = {
        globalFunctions: settings.globalFunctions,
      };
    }

    await createControllers(settings);

    createGlobalFunctions(settings);

    return settings;
  };

  const defaultSettings = {
    fields: [],
    userFields: [],
    defaultData: {},
    userFieldsInfo: {},
    readOnly: false,
    displayModalRef,
    modalEmailTemplatesRef,
    fileModalRef,
    modalBatchRef,
    modalBinLocationRef,
    modalCaptureRef,
    modalCatalogRef,
    dynamicFunctionProps: {
      dispatch,
      JSON: {
        parse: json => {
          const newJson = json
            .replace(/\t/g, ' ')
            .replace(/\n/g, ' ')
            .replace(/\r/g, ' ');
          return JSON.parse(newJson);
        },
        stringify: data => {
          return JSON.stringify(data);
        },
      },
      logRef,
      axios,
      toast,
      dateFns,
      Decimal,
      dateToString,
      stringToDate,
      numberToString,
      stringToNumber,
      externalApi: async axiosSettings => {
        return request({
          url: 'ExternalApi',
          method: 'POST',
          data: axiosSettings,
          timeout: axiosSettings.timeout,
        });
      },
      fireEvent,
      t,
      getConfigurationField: async (configurationCode, field) => {
        const response = await ConfigurationsService.getField({
          configurationCode,
          property: field,
        });

        return response;
      },
      documentService: DocumentService,
      taxCodeService: TaxCodeService,
      taxCodeDeterminationService: TaxCodeDeterminationService,
      showModalFields: props => {
        modalFieldsRef.current.show(props);
      },
      showModalApplication: props => {
        modalApplicationsRef.current.show(props);
      },
      showModalQuery: props => {
        modalQueryRef.current.show(props);
      },
      showModalReport: props => {
        modalReportRef.current.show(props);
      },
      showModalUserPage: props => {
        modalUserPageRef.current.show(props);
      },
      showModalEditor: props => {
        modalEditorRef.current.show(props);
      },
      showModalSql: props => {
        modalSqlRef.current.show(props);
      },
      showModalEvent: props => {
        modalEventRef.current.show(props);
      },
      showModalUserfield: props => {
        modalUserfieldRef.current.show(props);
      },
      showModalComponent: props => {
        modalComponentRef.current.show(props);
      },
      showModalList: props => {
        modalListRef.current.show(props);
      },
      showModalCamera: props => {
        modalCameraRef.current.show(props);
      },
      showModalMap: props => {
        modalMapRef.current.show(props);
      },
      treatError: (err, setErrors, type) => {
        TreatError.showError(err, setErrors, type);
      },
      showError: message => {
        throw new Error(message);
      },
      executeQuery: QueryService.execute,
      executeScalar: async sql => {
        const responseSql = await QueryService.execute(1, sql);
        if (responseSql.length > 0) return responseSql[0];
        return {};
      },
      executeSql: async (sql, ...args) => {
        const responseSql = await QueryService.execute(1, sql, ...args);
        return responseSql;
      },
      executeQueryByCode: async (queryCode, params) => {
        const responseSql = await QueryService.executeByCode(queryCode, params);
        return responseSql;
      },
      normalizeData,
      normalizeFieldLabel,
      normalizeFieldValue,
      getCoordinates: url => {
        return getCoordinates(url);
      },
      isEmptyField,
      serviceLayer: async dataToSend => {
        const response = await request({
          largeData: dataToSend,
          method: 'POST',
          url: 'SL',
        });

        return response;
      },
      executeBatch: async operations => {
        const response = await request({
          largeData: operations,
          method: 'POST',
          url: 'ExecuteBatch',
        });

        return response;
      },
      request,
      showPage: (pageRoute, externalData, options) => {
        showPage({ route: pageRoute, externalData, dispatch, ...options });
      },
      showReport: (reportCode, reportData) => {
        showReport({ reportCode, externalData: reportData, dispatch });
      },
      getSettings: async () => {
        const settings = await request({ url: 'ownersettings' });
        return settings;
      },
      getController: async controllerName => {
        const controllerCode = await ControllerService.getByName(
          controllerName
        );

        const controllerFunction = new AsyncFunction('scope', controllerCode);
        const controllerFound = await controllerFunction();
        return controllerFound;
      },
      getGeoLocation: async () => {
        const geoController = await GeoLocationService.getLocation({ t });

        return geoController;
      },
      showLoading,
      translations: [],
      getDownloadToken: ({ fileName, sourcePath }) => {
        const attachmentService = new AttachmentService();
        return attachmentService.getToken({ fileName, sourcePath });
      },
      getDocumentLabel: code => {
        return getDocumentLabelFromCode(code);
      },
      showFileModal: props => fileModalRef.current.show(props),
      closeFileModal: props => fileModalRef.current.handleClose(props),
      showDisplayModal: props => displayModalRef.current.show(props),
      showModalCatalog: props => modalCatalogRef.current.show(props),
      closeModalCatalog: () => modalCatalogRef.current.close(),
      showConfirmDialog: props => showConfirmDialog(props),
      showTemplatesModal: props => templatesModalRef.current.show(props),
      closeDisplayModal: () => displayModalRef.current.handleClose(),
      showBatchModal: props => modalBatchRef.current.show(props),
      /* showEmailTemplatesModal: props =>
        modalEmailTemplatesRef.current.show(props), */
      showModalEmailTemplates: props =>
        modalEmailTemplatesRef.current.show(props),
      showBinLocationModal: props => modalBinLocationRef.current.show(props),
      showModalCapture: props => modalCaptureRef.current.show(props),
      closeModalCapture: () => modalCaptureRef.current.hide(),
      isSafari,
      isMobile,
      server_url,
      prompt: props => {
        modalPromptRef.current.show(props);
      },
      sleep: delay => new Promise(resolve => setTimeout(resolve, delay)),
      showContextMenu: props => {
        const { event } = props;

        props.event.preventDefault();
        props.event.stopPropagation();

        popupSettingsRef.current.show({
          ...props,
          x: event.clientX,
          y: event.clientY,
        });
      },
      getApplicationSettings,
      getUtilDayDate: async (year, month, utilDayCount, holidays) => {
        async function getSapHolidays() {
          const sql = `
            select
              t0."StrDate",
              t0."EndDate"
            from HLD1 t0
            inner join OADM t1 on t1."HldCode" = t0."HldCode"
          `;
          const sapHolidays = await QueryService.execute(1, sql);

          const newHolidays = sapHolidays
            .map(holiday => {
              const startDate = new Date(holiday.StrDate);
              const endDate = new Date(holiday.EndDate);

              const checkHolidays = [];
              while (startDate <= endDate) {
                checkHolidays.push(startDate.toISOString().split('T')[0]);
                startDate.setDate(startDate.getDate() + 1);
              }

              return checkHolidays;
            })
            .flat();

          return newHolidays;
        }

        let systemHolidays = holidays;

        if (!systemHolidays || systemHolidays.length <= 0) {
          systemHolidays = await getSapHolidays();
        }

        return getUtilDayDate(year, month, utilDayCount, systemHolidays);
      },
    },
  };

  const [appRoute, setRoute] = useState();

  const [appSettings, setAppSettings] = useState(defaultSettings);

  // eslint-disable-next-line consistent-return
  const load = useCallback(async () => {
    if (appRoute !== mainRoute) {
      const ignoreRoutes = [
        'COMPANIES',
        'EMPLOYEES',
        'CONTROLPANEL',
        // 'COMPANYUSERS',
      ];

      if (!ignoreRoutes.includes(route)) {
        try {
          /* const routeSettings = queryParams.from ? queryParams.from : route;
          const hashSettings = queryParams.hash ? queryParams.hash : hash;

          const pageConfigurations = await ApplicationService.getByRoute(
            routeSettings,
            hashSettings
          );

          const settings = { ...defaultSettings, ...pageConfigurations };

          settings.dynamicFunctionProps.settings = {
            globalFunctions: settings.globalFunctions,
          };

          await createControllers(settings);

          createGlobalFunctions(settings); */

          const newSettings = await getApplicationSettings({
            queryParameters: queryParams,
            pageRoute: route,
            pageHash: hash,
            defaultSettings,
          });

          if (isAppMounted.current) {
            setAppSettings(newSettings);

            setRoute(mainRoute);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            history.push('/PageNotAuthorized');
          } else {
            TreatError.showError(error);
            history.goBack();
          }
        }
      } else if (isAppMounted.current) {
        setRoute(mainRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoute, mainRoute]);

  useEffect(() => {
    isAppMounted.current = true;
    load();

    return () => {
      isAppMounted.current = false;
    };
  }, [load]);

  useEffect(() => {
    let pageId = appSettings._route;

    if (appSettings._route) {
      if (appSettings._hashSettings) {
        pageId = `${pageId}/${appSettings._hashSettings}`;
      }

      dispatch(
        updateSettingsAction({
          pageId,
          settings: appSettings,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings]);

  const CurrentComponent = memo(
    () => (
      <Component
        settings={appSettings}
        enableMentions={appSettings.enableMentions}
        isReadOnly={appSettings.readOnly}
        dispatch={dispatch}
        showPage={showPage}
        showReport={showReport}
        showLoading={showLoading}
        clientEvent={ClientEventHandler}
        showModalMap={defaultSettings.dynamicFunctionProps.showModalMap}
        showModalCamera={defaultSettings.dynamicFunctionProps.showModalCamera}
        showModalFields={defaultSettings.dynamicFunctionProps.showModalFields}
        showModalCatalog={defaultSettings.dynamicFunctionProps.showModalCatalog}
        showDisplayModal={defaultSettings.dynamicFunctionProps.showDisplayModal}
        showTemplatesModal={
          defaultSettings.dynamicFunctionProps.showTemplatesModal
        }
        showFileModal={defaultSettings.dynamicFunctionProps.showFileModal}
        showBatchModal={defaultSettings.dynamicFunctionProps.showBatchModal}
        getGeoLocation={defaultSettings.dynamicFunctionProps.getGeoLocation}
        showBinLocationModal={
          defaultSettings.dynamicFunctionProps.showBinLocationModal
        }
        showModalCapture={defaultSettings.dynamicFunctionProps.showModalCapture}
        showEmailTemplatesModal={
          defaultSettings.dynamicFunctionProps.showEmailTemplatesModal
        }
        showModalApplication={
          defaultSettings.dynamicFunctionProps.showModalApplication
        }
        queryParams={queryParams}
        {...rest}
      />
    ),
    [appSettings, dispatch, rest]
  );

  return (
    <PageContext.Provider
      value={{
        ...defaultSettings,
        logRef,
        // settings: appSettings,
      }}
    >
      <Content>
        {appRoute !== mainRoute ? (
          <Route>
            <Loading isLoading />
          </Route>
        ) : (
          <Route>
            <CurrentComponent />
          </Route>
        )}
        <ModalFields ref={modalFieldsRef} />
        <ModalMap ref={modalMapRef} />
        <DisplayModal
          ref={displayModalRef}
          settings={appSettings}
          showLoading={showLoading}
        />
        <FileModal ref={fileModalRef} />
        <BatchNumberSelection ref={modalBatchRef} />
        <BinLocationSelection ref={modalBinLocationRef} />
        <ModalCapture ref={modalCaptureRef} />
        <PromptModal ref={modalPromptRef} />
        <ModalCamera ref={modalCameraRef} />
        <TemplatesModal ref={templatesModalRef} />
        <ModalEmailTemplates
          ref={modalEmailTemplatesRef}
          dynamicFunctionProps={defaultSettings.dynamicFunctionProps}
        />
        <ApplicationModal ref={modalApplicationsRef} />
        <QueryModal ref={modalQueryRef} />
        <ReportModal ref={modalReportRef} />
        <UserPageModal ref={modalUserPageRef} />
        <EditorModal ref={modalEditorRef} />
        <SqlModal ref={modalSqlRef} />
        <EventModal ref={modalEventRef} />
        <UserfieldModal ref={modalUserfieldRef} />
        <ComponentModal ref={modalComponentRef} />
        <PopupSettings ref={popupSettingsRef} />
        <ListModal ref={modalListRef} />
        <ModalCatalog ref={modalCatalogRef} settings={appSettings} />
      </Content>
      <Log ref={logRef} hidden={appRoute !== mainRoute} />
    </PageContext.Provider>
  );
}

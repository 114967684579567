/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-new-func */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { isMobile } from '~/easy-components/DeviceDetect';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import { FieldContainer, Title } from '../styles';
import { Input } from './styles';
// eslint-disable-next-line import/no-cycle
import UserField from '../UserField';
import { sendEvent } from '../../HandlerEvent';

function MaskField({
  baseName,
  name,
  label,
  hidden,
  formRef,
  mainFormRef,
  settings,
  labelWidth,
  readOnly: readOnlyProp,
  onChange,
  auxScope,
  converter,
  required = false,
  labelPosition = 'left',
  isUserField,
  customTabName,
  ...rest
}) {
  /**
   *  Se passarmos essas props, elas serão colocadas como atributos no elemento
   *  dom e o react colocará um warning no console, então removeremosmos elas do rest.
   */
  delete rest.showLink;
  delete rest.fieldDefaultValue;
  delete rest.fullScreen;

  const changed = useRef(false);

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId: settings ? settings._route : '',
    componentType: 'field',
    settings,
    baseName,
    name,
    title: label,
    isUserField,
    customTabName,
  });

  const inputRef = useRef(null);

  const fieldTitle = selfField.title || label;

  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const { isHidden, isRequired, isReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    getLogData: () => {
      return {
        baseName,
        name: fieldName,
        value: inputRef.current.value,
      };
    },
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
      },
      setValue: (el, v) => {
        const newValue = converter ? converter(v) : null;
        el.setInputValue(newValue || v || '');
      },
      getValue: el => {
        return el.value === '' ? null : el.value;
      },
    });
  }, []);

  const handlerOnChange = async params => {
    const response = await sendEvent({
      settings,
      eventName: 'onchange',
      params,
      run: params.run,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
    });

    return response || params.value;
  };

  async function onBlur(params) {
    params.persist();

    if (changed.current || params.target.changed) {
      changed.current = false;

      let val = params.target.value;

      const beforeValue = await handlerOnChange({ ...params, run: 'before' });
      if (beforeValue !== undefined) val = beforeValue;

      params.target.value = val;

      await onChange(params);

      val = params.target.value;

      const afterValue = await handlerOnChange({ ...params, run: 'after' });
      if (afterValue !== undefined) val = afterValue;

      inputRef.current.value = val;
    }
  }

  const onChanged = async e => {
    await onBlur(e);
  };

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.getInputDOMNode().setSelectionRange(0, text.length);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.getInputDOMNode().addEventListener('changed', onChanged);
      inputRef.current.getInputDOMNode().addEventListener('focus', onFocus);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current
          .getInputDOMNode()
          .removeEventListener('changed', onChanged);

        inputRef.current
          .getInputDOMNode()
          .removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  return (
    <>
      <FieldContainer
        hidden={isHidden}
        viewMode={!hidden || isUserField ? viewMode : 'normal'}
        onContextMenu={event => showContextMenu({ event })}
        readOnly={isReadOnly}
        labelPosition={labelPosition}
        style={
          isMobile
            ? {
                ...(selfField && selfField.style ? selfField.style : {}),
                ...(rest && rest.style ? rest.style : {}),
              }
            : {}
        }
      >
        {fieldTitle && (
          <Title
            highlight={isUserField && viewMode === 'design'}
            labelWidth={
              (selfField && selfField.labelWidth) || labelWidth || 130
            }
            labelPosition={labelPosition}
          >
            <div className="title">
              <div className="required-field">
                {fieldTitle}
                {isRequired && <b>*</b>}
              </div>
              <div className="title-icon">
                <CustomEvent
                  settings={settings}
                  selfField={selfField}
                  inputRef={inputRef}
                  formRef={formRef}
                  mainFormRef={mainFormRef}
                  auxScope={auxScope}
                />
              </div>
              <HasEventComponent selfField={selfField} viewMode={viewMode} />
            </div>
            {error && <div className="error">{error}</div>}
          </Title>
        )}

        <Input
          ref={inputRef}
          defaultValue={defaultValue || ''}
          {...selfField}
          {...rest}
          hidden={false}
          onBlur={onBlur}
          onChange={() => {
            changed.current = true;
          }}
          readOnly={isReadOnly}
        />
      </FieldContainer>

      <UserField
        baseName={baseName}
        fieldName={fieldName}
        formRef={formRef}
        mainFormRef={mainFormRef}
        settings={settings}
        labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
      />
    </>
  );
}

MaskField.propTypes = {
  formRef: PropTypes.shape(),
  onChange: PropTypes.func,
  baseName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  settings: PropTypes.shape(),
  labelWidth: PropTypes.any,
  readOnly: PropTypes.bool,
  mainFormRef: PropTypes.shape(),
};

MaskField.defaultProps = {
  formRef: null,
  baseName: null,
  settings: null,
  onChange: val => {
    return val;
  },
  label: null,
  labelWidth: null,
  readOnly: false,
  hidden: false,
  mainFormRef: {},
};

export default memo(MaskField);

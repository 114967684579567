import React, { useContext } from 'react';
import { BsTrash3 } from 'react-icons/bs';
import HeaderPage from '~/easy-components/HeaderPage';
import colors from '~/styles/colors';
import FooterPage from '~/easy-components/FooterPage';
import FlexSpace from '~/easy-components/FlexSpace';
import useLocale from '~/hooks/useLocale';
// eslint-disable-next-line import/no-cycle
import { Button } from '~/easy-components';

import { isMobile } from 'react-device-detect';
import useCompany from '~/hooks/useCompany';
import {
  Container,
  Content,
  Product,
  Title,
  FooterPrice,
  Price,
  ButtonTrash,
  HeaderProduct,
  TitleModel,
  NoItems,
  Header,
  TotalTitle,
  Total,
  TotalValue,
  Info,
} from './styles';
import { ModalCatalogContext } from '../../context';
import QuantitySelector from '../QuantitySelector';
import ButtonConfirmItems from '../ConfirmItems';

/**
 * @typedef {Object} ChosenProducts
 * @param {string} title
 */

/**
 * @param {ChosenItems} props
 */

function ChosenItems({ title, onConfirm, onClose }) {
  const {
    setOpenChosenItems,
    openChosenItems,
    selectedItems,
    setSelectedItems,
    currency,
  } = useContext(ModalCatalogContext);

  const t = useLocale('_Global');
  const t2 = useLocale('SalesOrders');
  const tError = useLocale('RequestForQuotationsWizard');

  const { numberToString } = useCompany();

  function handlerClose() {
    setOpenChosenItems(false);
  }
  return (
    <Container open={openChosenItems}>
      {isMobile ? (
        <HeaderPage
          height={500}
          backgroundColor={isMobile ? colors.headerBg : colors.slate}
          color={colors.headerTitle}
          hideMainMenu
        >
          <TitleModel>{title.toUpperCase()}</TitleModel>
        </HeaderPage>
      ) : (
        <Header>
          <TitleModel>{title.toUpperCase()}</TitleModel>
        </Header>
      )}
      <Content>
        {selectedItems.length === 0 && (
          <NoItems>{tError('error.NoItemsSelected')}</NoItems>
        )}
        {selectedItems.map(item => {
          const totalByItem = Number(item.UnitPrice) * Number(item.Quantity);

          return (
            <Product key={item.ItemCode}>
              <HeaderProduct>
                <div>
                  <Title>{item.ItemName}</Title>
                  <Info>
                    {item.Category} / {item.ItemCode}
                  </Info>
                </div>
                <ButtonTrash
                  onClick={() => {
                    const newItems = selectedItems.filter(
                      si => si.ItemCode !== item.ItemCode
                    );
                    setSelectedItems(newItems);
                  }}
                >
                  <BsTrash3 />{' '}
                </ButtonTrash>
              </HeaderProduct>

              <FooterPrice>
                <QuantitySelector item={item} />
                <Price>
                  {currency} {numberToString(totalByItem, 'value')}
                </Price>
              </FooterPrice>
            </Product>
          );
        })}
      </Content>

      <Total>
        <TotalTitle>{t2('_Total')}</TotalTitle>
        <TotalValue>
          {currency}{' '}
          {numberToString(
            selectedItems.reduce(
              (acc, item) => acc + item.Quantity * item.UnitPrice,
              0
            ),
            'value'
          )}
        </TotalValue>
      </Total>

      {isMobile && (
        <FooterPage>
          <FlexSpace />
          <ButtonConfirmItems onConfirm={onConfirm} onClose={onClose} t={t} />
          <Button type="button" onClick={handlerClose}>
            {t('Close')}
          </Button>
        </FooterPage>
      )}
    </Container>
  );
}

export default ChosenItems;

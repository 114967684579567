// @ts-check

import { openRowEditors, setFocused } from 'ka-table/actionCreators';
import { isMobile } from '~/easy-components/DeviceDetect';

/**
 *
 * @param {string} columnKey
 * @returns {(col: import('ka-table/Models/Column').Column) => boolean}
 */
const byColumnKey = columnKey => col => col.key === columnKey;

/**
 *
 * @param {string} rowKeyField
 * @param {string} rowKeyValue
 * @returns {(row: object) => boolean}
 */
const byRowKeyValue = (rowKeyField, rowKeyValue) => {
  return row => row[rowKeyField] && row[rowKeyField] === rowKeyValue;
};

/**
 * @typedef {import('ka-table/Models/Column').Column & { settings?: {type: string, [key: string]: any}}} EasyColumn
 * @param {number} index
 * @returns {(col: EasyColumn, index: number) => boolean}
 */
const inputAfterIndex = index => (column, loopIndex) => {
  const isAfter = loopIndex > index;

  const type = (column.settings && column.settings.type) || '';

  const isInput = type.startsWith('input');

  const isVisible = column.visible;

  return isAfter && isInput && isVisible;
};
/**
 * Rotaciona os itens do array ate que o input esteja no canto
 * @param {EasyColumn[]} columns
 * @param {number} inputIndex
 * @param {"right" | "left"} direction
 */
const rotateInput = (columns, inputIndex, direction) => {
  let currentInputIndex = inputIndex;

  const currentList = [...columns].map((col, i) => ({
    originalIndex: i,
    column: col,
  }));

  const end = direction === 'right' ? currentList.length - 1 : 0;
  const addMethod = direction === 'right' ? 'unshift' : 'push';
  const removeMethod = direction === 'right' ? 'pop' : 'shift';

  while (currentInputIndex !== end) {
    currentList[addMethod](currentList[removeMethod]());
    currentInputIndex += direction === 'right' ? 1 : -1;
  }

  if (direction === 'right') return [...currentList].reverse();

  return currentList;
};

/**
 * @param {EasyColumn[]} columns
 * @param {number} index
 * @param {"left" | "right"} [direction]
 */
const findNextInputColumn = (columns, index, direction = 'right') => {
  const rotationDirection = direction === 'right' ? 'left' : 'right';

  const sortedList = rotateInput(columns, index, rotationDirection);

  const sortedIndex = sortedList
    .map(l => l.column)
    .findIndex(inputAfterIndex(0));

  if (sortedIndex === -1) return -1;

  const { originalIndex } = sortedList[sortedIndex];

  return originalIndex;
};

/**
 * @param {object} params
 * @param {import('ka-table').ITableProps} params.tableProps
 * @param {{columnKey: string, rowKeyValue: string}} params.actualInput
 * @param {"right" | "left"} [params.direction]
 * @param {(action: any, forceUpdate?: boolean) => void} params.dispatch
 */
const moveFocusedToNextInput = ({
  tableProps,
  actualInput,
  direction = 'right',
  dispatch,
}) => {
  const { columnKey, rowKeyValue } = actualInput;

  const { columns, data = [], rowKeyField } = tableProps;

  const columnIndex = columns.findIndex(byColumnKey(columnKey));

  if (columnIndex === -1) return;

  const rowIndex = data.findIndex(byRowKeyValue(rowKeyField, rowKeyValue));

  if (rowIndex === -1) return;

  const nextColumnIndex = findNextInputColumn(columns, columnIndex, direction);

  if (nextColumnIndex === -1) return;

  let hasNextRow = !!data[rowIndex + 1];

  if (direction === 'left') hasNextRow = !!data[rowIndex - 1];

  let reachEndOfColumns = nextColumnIndex <= columnIndex;

  if (direction === 'left') reachEndOfColumns = nextColumnIndex >= columnIndex;

  const isRight = direction === 'right';

  let nextRowKeyIndex = rowIndex;

  if (reachEndOfColumns && hasNextRow && isRight) nextRowKeyIndex++;

  if (reachEndOfColumns && hasNextRow && !isRight) nextRowKeyIndex--;

  if (reachEndOfColumns && !hasNextRow) return;

  const newRowKeyValue = data[nextRowKeyIndex][rowKeyField];
  const newColumnKey = columns[nextColumnIndex].key;

  dispatch(
    setFocused({
      cell: { rowKeyValue: newRowKeyValue, columnKey: newColumnKey },
    })
  );
};

/**
 * @param {string} rowKeyValue
 * @param {import('ka-table').ITableProps} tableProps
 * @param {(action: any, forceUpdate?: boolean) => void} dispatch
 * @param {(currentData?: Record<string, any>[]) => void} onAddNewLine
 * @param {string} columnKey
 * @param {any} newValue
 */
const focusOnNextRowInput = (
  rowKeyValue,
  tableProps,
  dispatch,
  onAddNewLine,
  columnKey,
  newValue
) => {
  const { columns } = tableProps;

  const firstInputColumn = columns.find(
    column =>
      // @ts-ignore
      column.settings &&
      // @ts-ignore
      column.settings.type &&
      // @ts-ignore
      column.settings.type.startsWith('input') &&
      column.visible
  );

  if (!firstInputColumn) return;

  const tablePropsRowKeyField = tableProps.rowKeyField;

  const currentRowIndex = tableProps.data.findIndex(
    row => row[tablePropsRowKeyField] === rowKeyValue
  );

  if (currentRowIndex === -1) return;

  const rowsNumber = tableProps.data.length;

  let nextRowKeyValue;

  if (currentRowIndex === rowsNumber - 1) {
    onAddNewLine(
      (tableProps.data || []).map(row => {
        if (row[tableProps.rowKeyField] !== rowKeyValue) return row;

        return {
          ...row,
          [columnKey]: newValue,
        };
      })
    );
    nextRowKeyValue = rowsNumber + 1;
  } else {
    const tablePropsData = tableProps.data;

    const nextRowIndex = currentRowIndex + 1;

    const nextRow = tablePropsData[nextRowIndex];

    nextRowKeyValue = nextRow[tablePropsRowKeyField];
  }

  dispatch(
    setFocused({
      cell: { columnKey: firstInputColumn.key, rowKeyValue: nextRowKeyValue },
    }),
    false
  );
  dispatch(openRowEditors(nextRowKeyValue));
};

const ENTER = 13;
const ARROW_UP = 38;
const TAB = 9;
/**
 * @param {Object} params
 * @param {string} params.columnKey
 * @param {string} params.rowKeyValue
 * @param {import('ka-table').ITableProps} params.tableProps
 * @param {(action: any, forceUpdate?: boolean) => void} params.dispatch
 * @param {(currentData?: Record<string, any>[]) => void} params.onAddNewLine
 * @param {React.MutableRefObject<null | HTMLInputElement>} params.currentInputRef
 *
 * @typedef {Object} InputNavigationReturn
 * @property {(event: object, rowKeyValue: string, columnKey: string) => void} inputNavigationOnKeyDown
 * @property {(event: object) => void} inputNavigationOnFocus
 *
 *
 * @return {InputNavigationReturn}
 */
const useInputNavigation = ({
  columnKey,
  rowKeyValue,
  tableProps,
  dispatch,
  onAddNewLine,
  currentInputRef,
}) => {
  const moveDown = e => {
    const rowIndex = tableProps.data.findIndex(
      row => row[tableProps.rowKeyField] === rowKeyValue
    );
    let newRowKeyValue;

    if (rowIndex + 1 === tableProps.data.length) {
      onAddNewLine(
        (tableProps.data || []).map(row => {
          if (row[tableProps.rowKeyField] !== rowKeyValue) return row;

          return {
            ...row,
            [columnKey]: e.target.value,
          };
        })
      );
      newRowKeyValue = tableProps.data.length + 1;
    } else {
      newRowKeyValue = tableProps.data[rowIndex + 1][tableProps.rowKeyField];
    }

    dispatch(
      setFocused({ cell: { columnKey, rowKeyValue: newRowKeyValue } }),
      false
    );

    dispatch(openRowEditors(newRowKeyValue));
  };

  const moveUp = () => {
    const body = document.querySelector('body');

    body.click();

    const rowIndex = tableProps.data.findIndex(
      row => row[tableProps.rowKeyField] === rowKeyValue
    );

    if (rowIndex === 0) return;

    const newRowKeyValue =
      tableProps.data[rowIndex - 1][tableProps.rowKeyField];

    dispatch(
      setFocused({ cell: { columnKey, rowKeyValue: newRowKeyValue } }),
      false
    );
    dispatch(openRowEditors(newRowKeyValue));
  };

  return {
    inputNavigationOnKeyDown: e => {
      const { keyCode, shiftKey, ctrlKey } = e;

      const isKeyboardShortcut =
        (keyCode === ENTER && ctrlKey) ||
        (keyCode === ENTER && shiftKey) ||
        keyCode === ARROW_UP ||
        keyCode === ENTER ||
        (keyCode === TAB && shiftKey) ||
        keyCode === TAB;

      if (isKeyboardShortcut && currentInputRef.current) {
        if (
          typeof currentInputRef.current === 'object' &&
          // @ts-ignore
          currentInputRef.current.getInputDOMNode
        ) {
          // @ts-ignore
          currentInputRef.current.getInputDOMNode().blur();
        } else {
          currentInputRef.current.blur();
        }
      }

      if (isMobile && isKeyboardShortcut) {
        e.preventDefault();
        e.stopPropagation();

        if (
          typeof currentInputRef.current === 'object' &&
          // @ts-ignore
          currentInputRef.current.getInputDOMNode
        ) {
          // @ts-ignore
          currentInputRef.current.getInputDOMNode().blur();
        } else {
          currentInputRef.current.blur();
        }
        return (() => {})();
      }

      if (keyCode === ENTER && ctrlKey) {
        return focusOnNextRowInput(
          rowKeyValue,
          tableProps,
          dispatch,
          onAddNewLine,
          columnKey,
          e.target.value
        );
      }

      if ((keyCode === ENTER && shiftKey) || keyCode === ARROW_UP) {
        return moveUp();
      }

      if (keyCode === ENTER) {
        return moveDown(e);
      }

      if (keyCode === TAB && shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        return moveFocusedToNextInput({
          actualInput: {
            columnKey,
            rowKeyValue,
          },
          tableProps,
          direction: 'left',
          dispatch,
        });
      }

      if (keyCode === TAB) {
        e.preventDefault();
        e.stopPropagation();

        return moveFocusedToNextInput({
          actualInput: {
            columnKey,
            rowKeyValue,
          },
          tableProps,
          dispatch,
        });
      }

      return null;
    },
    inputNavigationOnFocus: () => {
      const focused = tableProps.focused || {};

      const focusedCell = focused.cell || { columnKey: '', rowKeyValue: '' };

      if (
        focusedCell.columnKey !== columnKey ||
        focusedCell.rowKeyValue !== rowKeyValue
      ) {
        dispatch(setFocused({ cell: { columnKey, rowKeyValue } }), true);
      }
    },
  };
};

export default useInputNavigation;

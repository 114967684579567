import { isSafari } from '~/easy-components/DeviceDetect';

export default function({ url, fileName }) {
  if (isSafari) {
    const a = document.createElement('a');

    a.style = 'display: none';

    a.href = `${process.env.REACT_APP_SERVER_URL}/${url}`;

    a.download = fileName;

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
  } else window.open(`${process.env.REACT_APP_SERVER_URL}/${url}`, '_blank');
}

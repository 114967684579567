/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { isMobile } from '~/easy-components/DeviceDetect';
import { Decimal } from 'decimal.js';

import Toggle from '~/easy-components/Toggle';
import Toast from '~/easy-components/Toast';
import DebounceEvent from '~/easy-components/DebounceEvent';
import LinkIcon from '~/easy-components/LinkIcon';
import AuxInfo from '~/easy-components/AuxInfo';

import useCompany from '~/hooks/useCompany';
import BatchNumberDetailsService from '~/applications/CRM/services/BatchNumberDetailsService';
import BinLocationSelection from '~/applications/CRM/components/BinLocationSelection';
import { HeaderCell, ColumnCell } from '../styles';
import { Container, BatchSearch, BatchLine, STLNumber } from './styles';

function BatchesList(
  {
    isReadOnly,
    selectedItemData,
    lineIndex,
    itemLinesRef,
    refreshPage,
    t,
    showBinPosition,
    confirmSelectedBinLocations,
  },
  ref
) {
  const { numberToString, stringToNumber } = useCompany();

  const charPermitted = '0123456789,.';

  const [batches, setBatches] = useState([]);
  const [binActivatFrom, setBinActivatFrom] = useState('N');
  const [binActivatTo, setBinActivatTo] = useState('N');
  const inputRef = useRef(null);
  const firstLoad = useRef(true);
  const allBatches = useRef([]);
  const modalBinLocationRef = useRef();

  const [showBatch, setShowBatch] = useState(false);
  const quantityInputsRefs = useRef();
  const STLNumbers = useRef([]);
  quantityInputsRefs.current = [];

  const warehouseFromCodeRef = useRef();
  const warehouseToCodeRef = useRef();
  const QuantityNecessaryRef = useRef(0);

  const refreshCommitQty = useCallback(
    batchesLines => {
      batchesLines.forEach(batchLine => {
        const batchItem = selectedItemData.BatchNumbers.find(
          batch => batch.BatchNumber == batchLine.BatchNumber
        );

        const result =
          batchLine.CommitQty - (batchItem ? batchItem._CacheQuantity || 0 : 0);

        batchLine.CommitQty = result;
      });

      return batchesLines;
    },
    [selectedItemData]
  );

  const warehouseInfo = useCallback(async whsCode => {
    const response = await BatchNumberDetailsService.getWarehouseInfo({
      whsCode,
    });
    return response[0].BinActivat;
  }, []);

  const refresh = useCallback(
    async filter => {
      if (selectedItemData) {
        try {
          QuantityNecessaryRef.current = selectedItemData.Quantity;

          const response = await BatchNumberDetailsService.getBatchQuantities({
            itemCode: selectedItemData.ItemCode,
            whsCode: selectedItemData.WarehouseCode,
            filter: filter || inputRef.current.value,
          });

          const batchesRefreshed = refreshCommitQty(response);

          if (firstLoad.current) {
            allBatches.current = batchesRefreshed;
            firstLoad.current = false;
          }

          warehouseFromCodeRef.current = selectedItemData.WarehouseCode;
          const binActiveFrom = await warehouseInfo(
            selectedItemData.WarehouseCode
          );
          setBinActivatFrom(binActiveFrom);

          if (selectedItemData._ToWarehouseCode) {
            warehouseToCodeRef.current = selectedItemData.WarehouseCode;

            const binActiveTo = await warehouseInfo(
              selectedItemData._ToWarehouseCode
            );
            setBinActivatTo(binActiveTo);
          }
          setBatches(batchesRefreshed);
        } catch (error) {
          Toast.warn(t('message.NoBatchesFound'));
          setBatches([]);
        }
      }
    },
    [selectedItemData, refreshCommitQty, warehouseInfo, t]
  );

  useEffect(() => {
    refresh();
  }, [refresh, refreshCommitQty, selectedItemData]);

  useEffect(() => {
    firstLoad.current = true;
  }, [lineIndex]);

  function calculateBatchDataDecimal(item, itemLine) {
    const batchItem = itemLinesRef.current[lineIndex].BatchNumbers.find(
      batch => batch.BatchNumber == item.BatchNumber
    );

    const batchQuantity = new Decimal(batchItem ? batchItem.Quantity : 0);
    const itemQuantity = new Decimal(item.Quantity);
    const commitQty = new Decimal(item.CommitQty);

    const batchAvaiable = itemQuantity.minus(batchQuantity);
    let batchReleased = batchAvaiable.minus(commitQty);

    if (batchReleased.lessThan(0)) batchReleased = new Decimal(0);

    const maxQuantity = itemQuantity.minus(commitQty);

    return {
      batchItem,
      batchAvaiable,
      commitQty,
      batchReleased: batchReleased.div(itemLine.UnitQuantity),
      maxQuantity,
      batchQuantity,
    };
  }

  function getSelectedTotal() {
    let selectedTotal = new Decimal(0);

    allBatches.current.forEach(batchData => {
      const batchItem = itemLinesRef.current[lineIndex].BatchNumbers.find(
        batch => batch.BatchNumber == batchData.BatchNumber
      );

      const batchQuantity = batchItem ? batchItem.Quantity : 0;

      const batchQuantityDec = new Decimal(batchQuantity);
      selectedTotal = selectedTotal.plus(batchQuantityDec);
    });

    return selectedTotal.toNumber();
  }

  useImperativeHandle(ref, () => {
    return {
      automaticSelection: async () => {
        if (lineIndex !== null) {
          const selectedTotal = getSelectedTotal();

          const itemLine = itemLinesRef.current[lineIndex];

          let necessaryTotalDec = new Decimal(0);
          const selectedTotalDec = new Decimal(selectedTotal);
          const lineQuantityDec = new Decimal(itemLine.Quantity);

          necessaryTotalDec = lineQuantityDec.minus(selectedTotalDec);

          const newBatches = [];

          for (let i = 0; i < batches.length; i++) {
            const item = batches[i];

            const { BatchNumber, _CacheQuantity } = item;

            const currentBatch = {
              BatchNumber,
              _CacheQuantity,
            };

            const {
              batchItem,
              batchReleased,
              batchQuantity,
            } = calculateBatchDataDecimal(item, itemLine);

            const batchInfo = batchItem || currentBatch;

            if (necessaryTotalDec.greaterThan(0)) {
              if (batchReleased.greaterThanOrEqualTo(0)) {
                if (batchReleased.greaterThanOrEqualTo(necessaryTotalDec)) {
                  const resultQuantity = necessaryTotalDec.plus(batchQuantity);

                  newBatches.push({
                    ...batchInfo,
                    Quantity: resultQuantity.toNumber(),
                  });
                } else {
                  const resultQuantity = batchReleased.plus(batchQuantity);

                  newBatches.push({
                    ...batchInfo,
                    Quantity: resultQuantity.toNumber(),
                  });
                }

                necessaryTotalDec = necessaryTotalDec.minus(batchReleased);
              }
            } else if (batchQuantity.greaterThan(0)) {
              newBatches.push({
                ...batchInfo,
                Quantity: batchQuantity.toNumber(),
              });
            }
          }

          itemLinesRef.current[lineIndex].BatchNumbers = newBatches;
          itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations = [];

          refreshPage(new Date());
        } else {
          Toast.warn(t('message.SelectItem'));
        }
      },
      updateSTLNumbers: idx => {
        // eslint-disable-next-line no-use-before-define
        const stlNumbersValues = getSTLNumbers({ idx });

        STLNumbers.current.forEach(stl => {
          if (stl.index === idx) {
            stl.numberSTLFrom = stlNumbersValues.numberSTLFrom;
            stl.fontColorSTLFrom = stlNumbersValues.fontColorSTLFrom;
            stl.fontColorSTLTo = stlNumbersValues.fontColorSTLTo;
            stl.numberSTLTo = stlNumbersValues.numberSTLTo;
          }
        });
        refreshPage(new Date());
      },
      clear: () => {
        if (lineIndex !== null) {
          itemLinesRef.current[lineIndex].BatchNumbers.forEach(batch => {
            batch.Quantity = 0;
          });

          itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations = [];

          STLNumbers.current.forEach(stl => {
            stl.numberSTLFrom = 0;
            stl.numberSTLTo = 0;
            stl.fontColorSTLFrom = 'red';
            stl.fontColorSTLTo = 'red';
          });

          refreshPage(new Date());
        } else {
          Toast.warn(t('message.SelectItem'));
        }
      },
    };
  });

  const setBatchNumbersLine = useCallback(
    (batch, maxQuantity, currentBatchTotal) => {
      const line = itemLinesRef.current[lineIndex];
      const batchNumbers = line.BatchNumbers || [];

      const batchFoundIdx = batchNumbers.findIndex(
        b => b.BatchNumber === batch.BatchNumber
      );

      const lineQuantityDec = new Decimal(line.Quantity);
      const batchQuantityDec = new Decimal(batch.Quantity);
      const maxQuantityDec = new Decimal(maxQuantity);
      const currentBatchTotalDec = new Decimal(currentBatchTotal);

      if (currentBatchTotalDec.lessThanOrEqualTo(lineQuantityDec)) {
        if (batchQuantityDec.lessThanOrEqualTo(maxQuantityDec)) {
          const hasQuantity =
            batch.Quantity !== '' &&
            batch.Quantity !== null &&
            batch.Quantity != '0';

          if (batchFoundIdx >= 0) {
            if (hasQuantity) {
              batchNumbers[batchFoundIdx].Quantity = batch.Quantity;
            } else {
              batchNumbers[batchFoundIdx].Quantity = 0;
            }
          } else if (hasQuantity) {
            batchNumbers.push(batch);
          }

          itemLinesRef.current[lineIndex].BatchNumbers = batchNumbers;
          itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations = [];
        } else {
          Toast.warn(t('message.ValueExceedsMaximumQuantityItem'));

          batchNumbers[batchFoundIdx].Quantity = maxQuantity;
        }
      } else {
        Toast.warn(t('message.ValueExceedsMaximumQuantityItem'));

        const resultQuantityDec = batchQuantityDec.minus(
          currentBatchTotalDec.minus(lineQuantityDec)
        );

        if (resultQuantityDec.greaterThan(maxQuantityDec)) {
          batchNumbers[batchFoundIdx].Quantity = maxQuantity;
        } else {
          batchNumbers[batchFoundIdx].Quantity = resultQuantityDec.toNumber();
        }
      }
    },
    [itemLinesRef, lineIndex, t]
  );

  function refreshLineQuantity({ item, quantity, itemLine }) {
    const { BatchNumber, _CacheQuantity } = item;

    const batch = {
      BatchNumber,
      Quantity: quantity,
      _CacheQuantity,
    };

    const batchInformation = itemLinesRef.current[lineIndex].BatchNumbers.find(
      lineBatch => lineBatch.BatchNumber === item.BatchNumber
    );

    if (!batchInformation) {
      itemLinesRef.current[lineIndex].BatchNumbers.push(batch);
    }

    let currentBatchTotalDec = new Decimal(0);

    itemLinesRef.current[lineIndex].BatchNumbers.forEach(btch => {
      const batchQuantity = new Decimal(btch.Quantity);

      currentBatchTotalDec = currentBatchTotalDec.plus(batchQuantity);
    });

    let maxQuantityDec = new Decimal(item.Quantity - item.CommitQty);
    maxQuantityDec = maxQuantityDec.div(itemLine.UnitQuantity);

    itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations = [];

    setBatchNumbersLine(
      batch,
      maxQuantityDec.toNumber(),
      currentBatchTotalDec.toNumber()
    );
  }

  const handleFocus = e => e.target.select();

  function handleOnBlur(e, item, itemLine) {
    const qtd = stringToNumber(e.target.value);

    refreshLineQuantity({
      item,
      quantity: qtd,
      itemLine,
    });

    refreshPage(new Date());
  }

  const handleRefs = (el, quantity) => {
    if (el && !quantityInputsRefs.current.includes(el)) {
      el.value = quantity;
      quantityInputsRefs.current.push(el);
    }
  };

  const getSTLNumbers = ({ idx }) => {
    const stockTransferLinesBinAllocations = itemLinesRef.current[lineIndex]
      .StockTransferLinesBinAllocations
      ? itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations
      : [];

    const newStockTransferLinesFrom = stockTransferLinesBinAllocations.filter(
      s => s.SerialAndBatchNumbersBaseLine == idx && s._direction === 'from'
    );

    let numberSTLFrom = 0;
    newStockTransferLinesFrom.forEach(STLFrom => {
      numberSTLFrom += STLFrom.Quantity;
    });

    const newStockTransferLinesTo = stockTransferLinesBinAllocations.filter(
      s => s.SerialAndBatchNumbersBaseLine == idx && s._direction === 'to'
    );

    let numberSTLTo = 0;
    newStockTransferLinesTo.forEach(STLTo => {
      numberSTLTo += STLTo.Quantity;
    });

    const fontColorSTLFrom = numberSTLFrom > 0 ? 'green' : 'red';

    const fontColorSTLTo = numberSTLTo > 0 ? 'green' : 'red';

    const lineStockTransferNumber = {
      index: idx,
      name: `STL_${idx}`,
      numberSTLFrom,
      fontColorSTLFrom,
      fontColorSTLTo,
      numberSTLTo,
    };

    return lineStockTransferNumber;
  };

  function selectTotalLine(itemData) {
    const selectedTotal = getSelectedTotal();

    const itemLine = itemLinesRef.current[lineIndex];

    const necessaryTotal = itemLine.Quantity - selectedTotal;

    let necessaryTotalDec = new Decimal(0);
    const selectedTotalDec = new Decimal(selectedTotal);
    const lineQuantityDec = new Decimal(itemLine.Quantity);

    necessaryTotalDec = lineQuantityDec.minus(selectedTotalDec);

    if (necessaryTotal >= 0) {
      const newBatches = [];

      for (let i = 0; i < allBatches.current.length; i++) {
        const item = allBatches.current[i];

        const clickedItem = allBatches.current.find(
          batch => batch.BatchNumber === itemData.BatchNumber
        );

        const { BatchNumber, _CacheQuantity } = item;

        const currentBatch = {
          BatchNumber,
          _CacheQuantity,
        };

        const {
          batchItem,
          batchReleased,
          batchQuantity,
        } = calculateBatchDataDecimal(item, itemLine);

        const batchInfo = batchItem || currentBatch;

        if (necessaryTotalDec.greaterThanOrEqualTo(0)) {
          if (clickedItem.BatchNumber === batchInfo.BatchNumber) {
            if (batchReleased.lessThanOrEqualTo(0)) {
              newBatches.push({
                ...batchInfo,
                Quantity: 0,
              });
            } else {
              if (batchReleased.greaterThanOrEqualTo(necessaryTotal)) {
                const resultQuantity = necessaryTotalDec.plus(batchQuantity);

                newBatches.push({
                  ...batchInfo,
                  Quantity: resultQuantity.toNumber(),
                });
              } else {
                const resultQuantity = batchReleased.plus(batchQuantity);

                newBatches.push({
                  ...batchInfo,
                  Quantity: resultQuantity.toNumber(),
                });
              }

              necessaryTotalDec = necessaryTotalDec.minus(batchReleased);
            }
          } else {
            newBatches.push({
              ...batchInfo,
              Quantity: batchQuantity.toNumber(),
            });
          }
        } else if (batchQuantity.greaterThan(0)) {
          newBatches.push({
            ...batchInfo,
            Quantity: batchQuantity.toNumber(),
          });
        }
      }

      itemLinesRef.current[lineIndex].BatchNumbers = newBatches;

      refreshPage(new Date());
    }
  }

  const openBinPage = ({ item, direction, loteLineIndex }) => {
    const batchNum = item.BatchNumber;
    const maxQuantity = stringToNumber(QuantityNecessaryRef.current);

    const selectedQuantity = quantityInputsRefs.current.filter(inputField => {
      return inputField.name === batchNum;
    });

    if (selectedQuantity.length > 0) {
      const qtd = stringToNumber(selectedQuantity[0].value);
      if (qtd > 0 && qtd <= maxQuantity) {
        selectedItemData.BatchNumber = batchNum;
        selectedItemData.Quantity = qtd;
        selectedItemData.StockTransferLinesBinAllocations =
          itemLinesRef.current[lineIndex].StockTransferLinesBinAllocations ||
          [];

        const unitQuantity = selectedItemData.UnitQuantity
          ? selectedItemData.UnitQuantity
          : selectedItemData._UnitQuantity;

        selectedItemData.UnitQuantity = unitQuantity;

        modalBinLocationRef.current.show({
          title:
            direction === 'from'
              ? t('BinLocationBatchFrom')
              : t('BinLocationBatchTo'),
          isReadOnly,
          data: selectedItemData,
          direction,
          batchNumber: batchNum,
          loteLineIndex,
          onConfirm: ({ direction: retDirection, binLocationsAttributed }) => {
            confirmSelectedBinLocations({
              direction: retDirection,
              binLocationsAttributed,
              selectedItemData,
              lineIndex,
            });
          },
        });
      } else {
        Toast.error(t('message.ErrorQuantity'));
      }
    }
  };

  return (
    <>
      <h1>{t('Batches')}</h1>
      <BatchSearch>
        <input
          ref={inputRef}
          placeholder={t('Search')}
          onChange={DebounceEvent(() => refresh())}
          onFocus={handleFocus}
        />

        <span>{t('BatchToggleButton')}</span>
        <Toggle
          name="Toggle"
          onClick={() => setShowBatch(state => !state)}
          size={16}
        />
      </BatchSearch>
      <Container disabled={isReadOnly}>
        <table>
          <thead>
            <tr>
              <HeaderCell>{t('Batches')}</HeaderCell>
              {!isMobile && (
                <HeaderCell contentType="number">{t('Available')}</HeaderCell>
              )}
              {!isMobile && (
                <HeaderCell contentType="number">{t('Allocated')}</HeaderCell>
              )}
              <HeaderCell contentType="number">{t('Released')}</HeaderCell>
              <HeaderCell contentType="number">{t('Selected')}</HeaderCell>
              {binActivatFrom === 'Y' && showBinPosition && (
                <HeaderCell contentType="number">
                  {t('BinLocationBatchTo')}
                </HeaderCell>
              )}
              {binActivatTo === 'Y' && showBinPosition && (
                <HeaderCell contentType="number">
                  {t('BinLocationBatchFrom')}
                </HeaderCell>
              )}
            </tr>
          </thead>

          <tbody>
            {batches.map((item, idx) => {
              const batchItem = selectedItemData.BatchNumbers.find(
                batch => batch.BatchNumber == item.BatchNumber
              );

              const batchQuantity = batchItem ? batchItem.Quantity : 0;

              const qty = new Decimal(item.Quantity || 0);
              const unitQuantity = new Decimal(selectedItemData.UnitQuantity);
              const batchQuantityConverted = unitQuantity.mul(batchQuantity);

              const batchAvaiable = qty.minus(batchQuantityConverted);

              const commitQty = item.CommitQty;

              let batchReleased = batchAvaiable - commitQty;

              if (batchReleased < 0) batchReleased = 0;

              const hidden = batchReleased === 0 && batchQuantity === 0;

              const quantity = numberToString(batchQuantity, 'quantity');

              const stlNumbersValues = getSTLNumbers({ idx });
              STLNumbers.current.push(stlNumbersValues);

              return (
                <BatchLine
                  key={idx}
                  display={hidden && !showBatch ? 'none' : 'table-row'}
                  lineColor={() => {
                    let lineColor = '#f9f9f9';

                    if (batchQuantity > 0 && batchReleased > 0) {
                      lineColor = '#fff0dc';
                    } else if (batchReleased === 0 && batchQuantity === 0) {
                      lineColor = '#ffcccc';
                    } else if (batchReleased === 0) {
                      lineColor = '#dcf0dc';
                    }
                    return lineColor;
                  }}
                >
                  <ColumnCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '5px 0',
                      }}
                    >
                      {item.BatchNumber}
                      <AuxInfo text={item.AuxInfo} />
                    </div>
                  </ColumnCell>
                  {!isMobile && (
                    <ColumnCell contentType="number">
                      {numberToString(
                        batchAvaiable / selectedItemData.UnitQuantity,
                        'quantity'
                      )}
                    </ColumnCell>
                  )}
                  {!isMobile && (
                    <ColumnCell contentType="number">
                      {numberToString(
                        commitQty / selectedItemData.UnitQuantity,
                        'quantity'
                      )}
                    </ColumnCell>
                  )}
                  <ColumnCell
                    style={{
                      cursor: isReadOnly ? 'default' : 'pointer',
                      fontWeight: 600,
                      color: '#496774',
                    }}
                    contentType="number"
                    onClick={() => {
                      if (!isReadOnly) selectTotalLine(item);
                    }}
                  >
                    {numberToString(
                      batchReleased / selectedItemData.UnitQuantity,
                      'quantity'
                    )}
                  </ColumnCell>

                  <ColumnCell contentType="number" input>
                    <input
                      name={item.BatchNumber}
                      ref={el => handleRefs(el, quantity)}
                      onBlur={e => handleOnBlur(e, item, selectedItemData)}
                      onKeyPress={e => {
                        if (charPermitted.indexOf(e.key) < 0) {
                          e.preventDefault();
                        }
                      }}
                      onFocus={handleFocus}
                      disabled={isReadOnly}
                    />
                  </ColumnCell>
                  {binActivatFrom === 'Y' && showBinPosition && (
                    <ColumnCell contentType="number">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();

                            openBinPage({
                              item,
                              direction: 'from',
                              loteLineIndex: idx,
                            });
                          }}
                        />
                        <STLNumber
                          key={STLNumbers.current[idx].name}
                          fontColor={STLNumbers.current[idx].fontColorSTLFrom}
                        >
                          {STLNumbers.current[idx].numberSTLFrom} A
                        </STLNumber>
                      </div>
                    </ColumnCell>
                  )}
                  {binActivatTo === 'Y' && showBinPosition && (
                    <ColumnCell contentType="number">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            openBinPage({
                              item,
                              direction: 'to',
                              loteLineIndex: idx,
                            });
                          }}
                        />
                        <STLNumber
                          key={STLNumbers.current[idx].name}
                          fontColor={STLNumbers.current[idx].fontColorSTLTo}
                        >
                          {STLNumbers.current[idx].numberSTLTo} B
                        </STLNumber>
                      </div>
                    </ColumnCell>
                  )}
                </BatchLine>
              );
            })}
          </tbody>
        </table>
      </Container>
      <BinLocationSelection ref={modalBinLocationRef} />
    </>
  );
}

export default forwardRef(BatchesList);

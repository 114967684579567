import React, { useContext } from 'react';
import ColumnLayout from '~/easy-components/ColumnLayout';
import Panel from '~/easy-components/Panel';
import Field from '~/easy-components/Form/UserField/Field';
import { JsonPageWizardContext } from '../context';

// import { Container } from './styles';

function FormPage({ columns = [], formRef, settings }) {
  const { executeFunction } = useContext(JsonPageWizardContext);

  return (
    <ColumnLayout labelPosition="top">
      {columns.map((column, idx) => {
        const columnWidth =
          typeof column.width === 'string' ? column.width : `${column.width}px`;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Panel width={columnWidth} key={idx}>
            {column.title && <h1>{column.title}</h1>}

            {column.fields.map(field => (
              <Field
                key={field.name}
                baseName={field.baseName}
                formRef={formRef}
                settings={settings}
                target={field}
                onChange={newValue => {
                  if (field.onChange) {
                    executeFunction(field.onChange, {
                      formData: formRef.current.getData(),
                      formRef,
                      settings,
                      form: formRef.current,
                      currentValue: newValue,
                    });
                  }
                }}
              />
            ))}
          </Panel>
        );
      })}
    </ColumnLayout>
  );
}

export default FormPage;

import styled from 'styled-components';

export const Container = styled.div`
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  min-height: 144px;

  &:not(:last-child) {
    border-bottom: 1px solid #e9edef;
  }
`;

export const Capsule = styled.div`
  border: none;
  width: 100%;
  transition: all 0.2s ease;
  @media (max-width: 500px) {
    padding: 12px;
    border-radius: 8px;
  }
`;
export const Content = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 500px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const ContentView = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;

  @media (max-width: 500px) {
    gap: 8px;
    margin-left: 28px;
    align-items: center;
    flex-direction: column;
    button {
      align-self: initial;
    }
  }
`;

export const InfoProducts = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  padding: 4px;
  gap: 8px;

  align-items: center;
  justify-content: space-between;
  color: #888;
  .quantity-content {
    display: flex;
  }
  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  gap: 8px;
  width: 100%;

  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
  }
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 50%;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }
`;

export const Essential = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 500px) {
    align-items: center;
  }
`;

export const Trace = styled.div`
  display: flex;

  text-align: end;
  align-items: center;
  padding-bottom: 8px;
  flex: 1;
  @media (max-width: 500px) {
    text-align: start;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const Description = styled.h3`
  font-size: 16px;
  font-weight: 400;
`;

export const Value = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #1d2125;
  margin-top: 4px;
  @media (max-width: 500px) {
    color: ${props => (props.setItem ? '#fff' : '#1d2125')};
    font-size: 1.2rem;
  }
`;

export const BoxSet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  align-items: flex-end;
`;
export const Checkbox = styled.input`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 20px;
  border-radius: 5px;
`;

export const DeclareFields = styled.div`
  display: flex;

  align-items: flex-end;
  max-width: 260px;
  width: 100%;
  justify-content: flex-end;

  color: #1d2125;

  @media (max-width: 500px) {
    color: ${props => (props.setItem ? '#fff' : '#1d2125')};
    max-width: 100%;
  }
`;

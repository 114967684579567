/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
} from 'react';

import { MdChevronRight } from '@react-icons/all-files/md/MdChevronRight';
import { MdChevronLeft } from '@react-icons/all-files/md/MdChevronLeft';

// eslint-disable-next-line import/no-cycle
import MasterDetail from '~/components/Pages/MasterDetail';

import Form from '~/easy-components/Form';
import Button from '~/easy-components/Button';
import Icon from '~/easy-components/Icon';
import ConfirmDialog from '~/easy-components/ConfirmDialog';
import TreatError from '~/easy-components/TreatError';

import useLocale from '~/hooks/useLocale';
import { Content, SimpleListItem } from './styles';

function Wizard(
  {
    route,
    keyField,
    onFinish,
    pages: myPages = [],
    settings,
    isReadOnly,
    isShowCancel,
    onCancel,
    showLoading,
    onChange,
    shareRoute,
    defaultData,
    executionPage,
    validateFinish,
    validatePrevious,
    executionFooterButtons,
    sideWidth,
    menus,
    onBeforeRender = () => {},
    onAfterRender = () => {},
    onBeforeEnterPage = async () => {},
    onAfterEnterPage = () => {},
    previousButtonLabel,
    nextButtonLabel,
    finishButtonLabel,
  },
  ref
) {
  const tapp = useLocale('Applications');
  const t = useLocale(shareRoute || route, settings.translations);
  const masterDetailRef = useRef();

  const pagesRef = useRef(
    myPages.map(page => ({
      isVisible: true,
      ...page,
    }))
  );

  const disablePreviousPage = settings.disablePreviousPage || false;

  let data = defaultData;

  if (
    !data &&
    settings &&
    settings.defaultData &&
    JSON.stringify(settings.defaultData) !== '{}'
  ) {
    data = settings.defaultData;
  }

  const formRef = useRef();

  const [showExecutionPage, setShowExecutionPage] = useState(false);

  const [selectedPageIdx, setPageIdx] = useState(0);

  const [formData] = useState(data);
  const [, refreshForm] = useState(null);

  function setSelectedPageIdx(idx) {
    setPageIdx(idx);
    if (onChange) onChange({ idx });
  }

  const onSearch = async () => {
    return (
      pagesRef.current
        .filter(page => page.isVisible)
        .map((page, index) => ({ ...page, index })) || []
    );
  };

  // eslint-disable-next-line no-unused-vars
  const onSelectedItem = ({ selectedItem }) => {
    return selectedItem;
  };

  const onSave = () => {};

  const onLoadExternalData = () => {
    return {};
  };

  const onGetDataToSave = () => {
    return {};
  };

  const findNextPageIndex = useCallback(
    (direction = 'next') => {
      if (direction === 'next') {
        const nextIndex = pagesRef.current.findIndex(
          (page, idx) => idx > selectedPageIdx && page.isVisible === true
        );

        return nextIndex;
      }

      const pagesToReverse = [...pagesRef.current];

      const reversedPages = pagesToReverse.reverse();

      const reversedSelectedPageIndex = reversedPages.findIndex(
        page => page[keyField] === pagesRef.current[selectedPageIdx][keyField]
      );

      const previousPage = reversedPages.find(
        (page, idx) =>
          idx > reversedSelectedPageIndex && page.isVisible === true
      );

      const previousPageIndex = pagesRef.current.findIndex(
        page => page[keyField] === previousPage[keyField]
      );

      return previousPageIndex;
    },
    [keyField, selectedPageIdx]
  );

  const nextPage = async () => {
    if (
      selectedPageIdx < pagesRef.current.filter(page => page.isVisible).length
    ) {
      const nextPageIndex = findNextPageIndex();

      await onBeforeEnterPage({
        formRef,
        pageIndex: nextPageIndex,
        page: pagesRef.current[nextPageIndex],
      });

      setSelectedPageIdx(nextPageIndex);

      masterDetailRef.current.executeSelectionItem(
        pagesRef.current[nextPageIndex]
      );
    }
  };

  const goToPage = idx => {
    setShowExecutionPage(false);
    if (pagesRef.current[idx]) {
      setSelectedPageIdx(idx);
      masterDetailRef.current.executeSelectionItem(pagesRef.current[idx]);
    }
  };

  const previousPage = async () => {
    try {
      showLoading(true);
      if (validatePrevious) {
        const formFinishData = formRef.current.getData();
        await validatePrevious({
          data: formFinishData,
          form: formRef.current,
        });
      }

      const previousIndex = findNextPageIndex('previous');

      setSelectedPageIdx(previousIndex);

      masterDetailRef.current.executeSelectionItem(
        pagesRef.current[previousIndex]
      );
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const finish = async () => {
    try {
      showLoading(true);

      const formFinishData = formRef.current.getData();

      const pagesVisibles = pagesRef.current.filter(page => page.isVisible);

      const lastPage = pagesVisibles[pagesVisibles.length - 1];

      if (lastPage.onChange) {
        await lastPage.onChange({
          data: formFinishData,
          finalize: true,
        });
      }

      if (lastPage.onValidate) {
        const isValid = await lastPage.onValidate({
          data: formFinishData,
          formRef,
        });

        if (isValid !== undefined && !isValid) {
          return;
        }
      }
      showLoading(false);

      await onFinish({ data: formFinishData, formRef, lastPage });

      showLoading(true);
      if (validateFinish) {
        await validateFinish({ data: formFinishData, formRef });
      }

      setShowExecutionPage(true);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const refresh = () => {
    refreshForm(new Date());
  };

  const startFinish = () => {
    // TODO: Traduzir

    ConfirmDialog({
      title: 'Finalizar processo',
      message: 'Deseja realmente finalizar?',
      buttons: [
        {
          label: 'Sim',
          // eslint-disable-next-line no-use-before-define
          onClick: finish,
        },
        {
          label: 'Não',
        },
      ],
    });
  };

  async function onNextPage() {
    if (
      selectedPageIdx ===
      pagesRef.current.filter(page => page.isVisible).length - 1
    )
      return startFinish();
    try {
      showLoading(true);
      const nextData = formRef.current.getData();

      const page = pagesRef.current[selectedPageIdx];

      if (page.onValidate) {
        const isValid = await page.onValidate({
          formRef,
          data: nextData,
        });

        if (!isValid) {
          return false;
        }
      }

      if (page.onChange) {
        await page.onChange({ formRef, data: nextData });
      }
      await nextPage();
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  async function onPreviousPage() {
    try {
      showLoading(true);

      const page = pagesRef.current[selectedPageIdx];

      if (page.onPrevious) {
        await page.onPrevious({ formRef });
      }
      previousPage();
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  async function setStep(step) {
    try {
      showLoading(true);
      const nextData = formRef.current.getData();

      const oldPage = pagesRef.current[step - 1];

      if (oldPage && oldPage.onChange) {
        await oldPage.onChange({ formRef, data: nextData });
      }
      goToPage(step);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
    return true;
  }

  const setPageVisible = useCallback(
    idx => {
      pagesRef.current[idx].isVisible = true;

      const currentPages = masterDetailRef.current.getList();

      const currentPageIds = new Set(currentPages.map(page => page[keyField]));

      const resultPages = [];

      const pageName = pagesRef.current[idx][keyField];

      pagesRef.current.forEach(page => {
        if (page.id === pageName || currentPageIds.has(page.id)) {
          resultPages.push(page);
        }
      });

      masterDetailRef.current.setList(resultPages);
    },
    [keyField]
  );

  const setPageInvisible = useCallback(
    idx => {
      pagesRef.current[idx].isVisible = false;

      const currentPages = masterDetailRef.current.getList();

      const newPages = currentPages.filter(
        page => page[keyField] !== pagesRef.current[idx][keyField]
      );

      masterDetailRef.current.setList(newPages);
    },
    [keyField]
  );

  const isPageVisible = useCallback(idx => {
    return pagesRef.current[idx].isVisible !== false;
  }, []);

  useImperativeHandle(ref, () => {
    return {
      refresh,
      nextPage: async () => {
        await onNextPage();
      },
      previousPage,
      finish,
      setStep,
      refreshList: props => masterDetailRef?.current?.refreshList(props),
      setPageVisible,
      setPageInvisible,
      isPageVisible,
      showExecutionPage: () => setShowExecutionPage(true),
    };
  });

  useEffect(() => {
    masterDetailRef.current.executeSelectionItem(
      pagesRef.current.filter(page => page.isVisible)[0]
    );
  }, []);

  onBeforeRender({ formRef, pageIndex: selectedPageIdx });

  useEffect(() => {
    const callAfterRender = async () => {
      // wait 200ms to render the page
      await new Promise(resolve => setTimeout(resolve, 200));

      onAfterRender({ formRef, pageIndex: selectedPageIdx });
    };

    callAfterRender();
  }, [onAfterRender, selectedPageIdx]);

  useEffect(() => {
    onAfterEnterPage({
      formRef,
      pageIndex: selectedPageIdx,
      page: pagesRef.current[selectedPageIdx],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageIdx]);

  return (
    <MasterDetail
      ref={masterDetailRef}
      formRef={formRef}
      customBarIcon={props => {
        return props.isSideOpen ? (
          <MdChevronLeft {...props} />
        ) : (
          <MdChevronRight {...props} />
        );
      }}
      showLoading={showLoading}
      settings={settings}
      isReadOnly={isReadOnly}
      title={tapp(route)}
      keyField={keyField}
      route={route}
      sideWidth={sideWidth || 350}
      onSelectedItem={onSelectedItem}
      onSearch={onSearch}
      listBackground="#627e8d"
      onRenderListItem={(item, idx, selected) => {
        return (
          <SimpleListItem selected={selected}>
            <section>
              <Icon name={item.icon} size={28} color="#fff" />
              <h1>{item.title}</h1>
            </section>
          </SimpleListItem>
        );
      }}
      onGetDataToSave={onGetDataToSave}
      onLoadExternalData={onLoadExternalData}
      onSave={onSave}
      showSearchPanel={false}
      isClickable={false}
      isShowNew={false}
      isShowSave={false}
      isShowDelete={false}
      isShowPrint={false}
      isExecutionPage={showExecutionPage}
      footerElements={() => {
        if (isReadOnly) return null;

        if (showExecutionPage && !executionFooterButtons) return null;

        if (showExecutionPage && executionFooterButtons)
          return executionFooterButtons();

        const pagesVisibles = pagesRef.current.filter(page => page.isVisible);

        const currentPage = pagesRef.current[selectedPageIdx];
        const lastPage = pagesVisibles[pagesVisibles.length - 1];
        const firstPage = pagesVisibles[0];

        return (
          <>
            {isShowCancel && (
              <Button type="button" onClick={onCancel} buttonType="Reject">
                {t('Cancel')}
              </Button>
            )}

            <Button
              disabled={currentPage.id === firstPage.id || disablePreviousPage}
              type="button"
              onClick={onPreviousPage}
            >
              {previousButtonLabel || t('Previous')}
            </Button>

            <Button
              disabled={currentPage.id === lastPage.id}
              buttonType="Emphasized"
              type="button"
              onClick={onNextPage}
            >
              {nextButtonLabel || t('Next')}
            </Button>

            <Button
              disabled={currentPage.id !== lastPage.id}
              buttonType="Emphasized"
              type="button"
              onClick={startFinish}
            >
              {finishButtonLabel || t('Finish')}
            </Button>
          </>
        );
      }}
      menus={menus}
    >
      <Form ref={formRef} data={formData}>
        {pagesRef.current.map((page, idx) => {
          return (
            <Content
              key={idx}
              isVisible={!showExecutionPage && idx === selectedPageIdx}
            >
              <page.content
                formRef={formRef}
                formData={formData}
                refresh={refresh}
                pageIsVisible={idx === selectedPageIdx}
              />
            </Content>
          );
        })}
        {showExecutionPage && executionPage && executionPage({ formRef })}
      </Form>
    </MasterDetail>
  );
}

export default forwardRef(Wizard);

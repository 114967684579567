/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { createPortal } from 'react-dom';
import { isMobile } from '~/easy-components/DeviceDetect';

import useLocale from '~/hooks/useLocale';

import Button from '~/easy-components/Button';
import PageHeader from '~/easy-components/PageHeader';
import downloadLink from '~/easy-components/Helpers/downloadLink';
import FlexSpace from '~/easy-components/FlexSpace';
import Toggle from '~/easy-components/Toggle';

import ConfirmButton from './Components/ConfirmButton';

import AttachmentService from '~/services/AttachmentService';
import {
  splitFileName,
  splitSourcePath,
} from '~/easy-components/Helpers/fileHandler';

import {
  Container,
  Content,
  ModalBody,
  ModalFooter,
  ToogleBox,
} from './styles';
import { Title } from '../Form/styles';

function DisplayModal({ fixedType = 'iframe', direction = 'left' }, ref) {
  const componentTypeRef = useRef(null);
  const confirmButtonRef = useRef();

  const [isImageFitToScreen, setIsImageFitToScreen] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isImage, setIsImage] = useState(false);
  const [newWidth, setNewWidth] = useState(0);
  const imageMaxHeight = 700;

  const [state, setState] = useState({
    open: false,
    type: fixedType,
    url: null,
    fullUrl: null,
    fileName: null,
    onConfirm: () => {},
    onCancel: () => {},
    translationRoute: '_Global',
  });
  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');

  const t = useLocale('_Global');

  function handleClose() {
    setState({
      ...state,
      open: false,
    });
  }

  function checkFileType(filePath) {
    const fileName = filePath.split('\\').pop();
    const fileExtension = fileName.split('.').pop();

    const extensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'pdf',
      'bmp',
      'tiff',
      'tif',
      'svg',
      'webp',
    ];

    if (extensions.includes(fileExtension.toLowerCase())) {
      return true;
    }

    return false;
  }

  const getDownloadToken = ({ fileName, sourcePath }) => {
    const attachmentService = new AttachmentService();

    return attachmentService.getToken({ fileName, sourcePath });
  };

  const createURL = async ({ fileName, sourcePath }) => {
    const token = await getDownloadToken({ sourcePath, fileName });

    const url = `files/${token}/${fileName}`;

    return url;
  };

  useImperativeHandle(ref, () => {
    return {
      show: async fileFullName => {
        const sourcePath = splitSourcePath(fileFullName);

        const fileName = splitFileName(fileFullName);

        const url = await createURL({ fileName, sourcePath });

        setIsImage(!fileName.includes('.pdf'));
        setIsImageFitToScreen(false);

        const showToIframe = checkFileType(url);

        if (isMobile || !showToIframe) {
          downloadLink({ url, fileName });
          return;
        }

        setState({
          url,
          fullUrl: `${process.env.REACT_APP_SERVER_URL}/${url}`,
          fileName,
          open: true,
        });
      },
      handleClose,
    };
  });

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  useEffect(() => {
    if (isImage) {
      const img = new Image();
      img.src = state.fullUrl;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };

      if (imageDimensions.width && imageDimensions.height) {
        const aspectRatio = imageDimensions.width / imageDimensions.height;
        setNewWidth(Math.round(aspectRatio * imageMaxHeight));
      }
    }
  }, [imageDimensions.height, imageDimensions.width, isImage, state.fullUrl]);

  const handleCheckboxChange = () => {
    setIsImageFitToScreen(!isImageFitToScreen);
  };

  return (
    <>
      {state.open &&
        createPortal(
          <Container direction={direction} onMouseDown={handleClose}>
            <Content
              onMouseDown={e => {
                e.stopPropagation();
              }}
            >
              <PageHeader>{t('FileViewer')}</PageHeader>
              <ModalBody type={state.type}>
                {state.fullUrl && !isImageFitToScreen ? (
                  <iframe title={state.title} src={state.fullUrl} />
                ) : (
                  <>
                    <div>
                      <img
                        src={state.fullUrl}
                        height="200"
                        width="200"
                        style={{
                          width: isImageFitToScreen ? newWidth : '100%',
                          height: isImageFitToScreen ? imageMaxHeight : '100%',
                        }}
                        alt="arquivo"
                      />
                    </div>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {isImage && (
                  <>
                    <ToogleBox>
                      <Toggle
                        name="Toggle"
                        checked={isImageFitToScreen}
                        onClick={() => handleCheckboxChange()}
                        size={16}
                      />
                    </ToogleBox>
                    <Title hideLine labelWidth={150}>
                      {t('FitImageToScreen')}
                    </Title>
                  </>
                )}
                <FlexSpace />
                <div>
                  {state.onConfirm && state.type !== 'options' && (
                    <ConfirmButton
                      ref={confirmButtonRef}
                      onClick={() => {
                        const refCurrent = componentTypeRef.current;
                        // O Close deve ser fechado antes de qualquer ação
                        handleClose();

                        refCurrent.onConfirm();
                      }}
                    >
                      {t('Confirm')}
                    </ConfirmButton>
                  )}
                  <Button
                    onClick={() => {
                      // O Close deve ser fechado antes de qualquer ação
                      handleClose();

                      if (state.onCancel) state.onCancel();
                    }}
                  >
                    {t('Close')}
                  </Button>
                </div>
              </ModalFooter>
            </Content>
          </Container>,
          el
        )}
    </>
  );
}

export default forwardRef(DisplayModal);

// @ts-check
import React, { createContext } from 'react';
import TruckService from '~/applications/CRM/services/TruckService';

/**
 * @typedef {Object} JsonPageContext
 */

/** @type {JsonPageContext} */
const defaultContext = {};

export const JsonPageContext = createContext(defaultContext);

export function JsonPageProvider({
  children,
  showLoading,
  route,
  settings,
  isReadOnly,
  dispatch,
  showReport,
  t,
  isUserPage,
  isListOpen,
}) {
  return (
    // @ts-ignore
    <JsonPageContext.Provider
      value={{
        showLoading,
        route,
        settings,
        isReadOnly,
        dispatch,
        showReport,
        TruckService,
        t,
        isUserPage,
        isListOpen,
      }}
    >
      {children}
    </JsonPageContext.Provider>
  );
}

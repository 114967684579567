import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import { RiInboxArchiveLine } from 'react-icons/ri';
import { BoxProducts, ContentQuantity } from './styles';
import { ModalCatalogContext } from '../../context';

/**
 * @typedef {Object} ProductProps
 * @property {string} imageSrc
 * @property {string} title
 * @property {string} description
 * @property {string} value
 */

/**
 * @param {ProductProps} props
 */
function ButtonSelectedItems() {
  const { selectedItems, openChosenItems, setOpenChosenItems } = useContext(
    ModalCatalogContext
  );

  return (
    <BoxProducts
      onClick={() => {
        setOpenChosenItems(!openChosenItems);
      }}
    >
      <RiInboxArchiveLine size={24} />
      <ContentQuantity>{selectedItems.length}</ContentQuantity>
    </BoxProducts>
  );
}

export default ButtonSelectedItems;

import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import Button from '~/easy-components/Button';
import { ModalCatalogContext } from '../../context';

function ButtonConfirmItems({ t, onConfirm, onClose }) {
  const { selectedItems } = useContext(ModalCatalogContext);

  async function handleOnConfirm() {
    await onConfirm(selectedItems);
  }

  return (
    <Button
      buttonType="Emphasized"
      type="button"
      onClick={e => {
        handleOnConfirm();
        onClose(e);
      }}
      key="selected-itens"
    >
      {t('Confirm')}
    </Button>
  );
}

export default ButtonConfirmItems;

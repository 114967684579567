/* eslint-disable no-const-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import {
  Chart as ChartJS,
  // CategoryScale,
  // LinearScale,
  // BarElement,
  // Title,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { readableColor } from 'polished';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import QueryService from '~/services/QueryService';
import TreatError from '~/easy-components/TreatError';
import Loader from '../Loader';
import Error from '../Error';
import { Container } from './styles';
import PluginSpaceLegend from '../PluginSpaceLegend';

ChartJS.register(ArcElement, Tooltip, Legend, PluginSpaceLegend);

function ComponentDoughnut(
  {
    data,
    widgetSettings,
    params,
    getColor,
    executeEvent,
    numberToString,
    legendPosition,
    showLabels,
  },
  ref
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState({});
  const chartRef = useRef();
  const updateTimeRef = useRef();
  const centerTextRef = useRef(null);
  const [settings, setSettings] = useState(widgetSettings);

  const getBackgroundColors = useCallback(
    lines => {
      const colors = lines.map((line, index) => {
        const color = getColor(index);
        return color;
      });

      return colors;
    },
    [getColor]
  );

  const createDatasets = useCallback(
    lines => {
      const firstLine = lines[0];

      const datasets = [];

      for (const prop in firstLine) {
        if (widgetSettings.referenceField !== prop) {
          if (prop.indexOf('_') !== 0) {
            const colors = getBackgroundColors(lines);

            datasets.push({
              label: prop,
              data: [],
              backgroundColor: colors,
              borderWidth: 2,
              borderSkipped: false,
            });
          }
        }
      }

      return datasets;
    },
    [getBackgroundColors, widgetSettings.referenceField]
  );

  const createDataInfo = useCallback(
    ({ datasets, lines }) => {
      const labels = [];

      lines.forEach(line => {
        labels.push(line[widgetSettings.referenceField]);
        datasets.forEach(dataset => {
          dataset.data.push(line[dataset.label]);
        });
      });

      return {
        labels,
        datasets,
        lines,
      };
    },
    [widgetSettings.referenceField]
  );

  const mountData = useCallback(
    response => {
      const datasets = createDatasets(response);

      const newInfo = createDataInfo({ datasets, lines: response });

      setInfo(newInfo);
    },
    [createDataInfo, createDatasets]
  );

  const run = useCallback(async () => {
    try {
      setIsLoading(true);

      setError(null);

      const response = await QueryService.execute(
        1,
        widgetSettings.query,
        params
      );

      const newSettings = await executeEvent({
        eventName: 'onGetSettings',
        data: { widgetSettings, data, params, value: response },
      });

      setSettings({
        ...widgetSettings,
        value: response,
        ...newSettings,
      });

      mountData(response);

      const centerText = await executeEvent({
        eventName: 'onSetCenterText',
        data: { widgetSettings, data, params, value: response },
      });

      centerTextRef.current = centerText;

      if (widgetSettings.updateTimeMinutes) {
        updateTimeRef.current = setTimeout(async () => {
          await run();
        }, widgetSettings.updateTimeMinutes * 1000 * 60);
      }
    } catch (e) {
      const errorDescription = TreatError.getDescription(e);
      setError(errorDescription);
    } finally {
      setIsLoading(false);
    }
  }, [data, executeEvent, mountData, params, widgetSettings]);

  useEffect(() => {
    run();

    return () => {
      clearTimeout(updateTimeRef.current);
    };
  }, [run, data]);

  useImperativeHandle(ref, () => {
    return {
      getData: () => {
        return info;
      },
    };
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const onClick = async event => {
    const elements = getElementAtEvent(chartRef.current, event);
    if (elements.length > 0) {
      const { element } = elements[0];

      const line = info.lines[element.$context.index];

      await executeEvent({
        eventName: 'onClick',
        data: line,
        chart: chartRef.current,
        charData: info.lines,
      });
    }
  };

  const { alignLabel = 'end', anchorLabel = 'end' } = settings;

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    plugins: {
      ChartDataLabels,
      datalabels: {
        align: alignLabel,
        anchor: anchorLabel,
        backgroundColor(context) {
          return `${context.dataset.backgroundColor[context.dataIndex]}30`;
        },
        display: showLabels !== false,
        color(context) {
          if (anchorLabel !== 'end') {
            const { dataIndex } = context;

            const indexBackgroundColor =
              context.dataset.backgroundColor[dataIndex];

            const textColor = readableColor(indexBackgroundColor);

            return textColor;
          }

          return context.dataset.backgroundColor;
        },
        font: {
          weight: 'bold',
        },
        formatter: numberToString,
        padding: 6,
      },
      legend: {
        display: true,
        position: legendPosition || 'top',
      },
      centerText: {
        display: true,
        text: centerTextRef.current || '',
      },
      PluginSpaceLegend,
    },
    ...settings.options,
  };

  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: async chart => {
      if (chart.config.options.plugins.centerText.display !== null) {
        const {
          ctx,
          chartArea: { left, right, top, bottom, width, height },
        } = chart;

        const { text } = chart.config.options.plugins.centerText;
        const characters = text.length;
        const centerX = left + (right - left) / 2;
        const centerY = top + (bottom - top) / 2;

        const fontSize = Math.min(width, height) / (characters + 4);

        ctx.save();
        ctx.font = `${fontSize}px sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        ctx.fillText(text, centerX, centerY);
        ctx.restore();
      }
    },
  };

  return (
    <Container>
      <Doughnut
        ref={chartRef}
        data={info}
        options={options}
        plugins={[centerTextPlugin]}
        onClick={onClick}
      />
    </Container>
  );
}

export default forwardRef(ComponentDoughnut);

import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${colors.green};
`;
export const Text = styled.h1`
  font-size: 12px;
  max-width: 50%;
  text-align: center;
`;

export const GoBackButton = styled.div`
  width: 25%;

  button {
    width: 100%;
  }
`;

/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { isMobile } from '~/easy-components/DeviceDetect';

export const colors = {
  fieldBorder: '#fff',
  fieldLabelColor: '#8197a5',
  fieldColor: '#555',
  fieldFocus: '#9cc3d4',
};

export const sizes = {
  borderRadius: '2px',
  padding: '10px 10px',
};

export const FieldContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: ${props =>
    !isMobile && props.labelPosition === 'top' ? 'column' : 'row'};
  margin-top: ${props => (props.labelPosition === 'top' ? '5px' : '0px')};

  ${props =>
    props.flexible &&
    css`
      justify-content: center;
      align-items: center;
      flex: 1;
    `}
  ${props =>
    !props.disableRenderMobile &&
    css`
      @media (max-width: 500px) {
        flex-direction: column;
        border-bottom: ${props => (props.hideLine ? '0px' : '1px solid #eee')};
        background-color: #fff;
        padding: 15px;

        ${props =>
          props.error &&
          css`
            border-bottom: 2px solid #992222;
          `}

        :focus-within {
          border-bottom: 1px solid #a8e2fa;
        }

        ${props =>
          props.readOnly &&
          css`
            background-color: #f9f9f9 !important;
          `}
      }
    `}

  input {
    ${props =>
      props.flexible &&
      css`
        flex: 1;
      `}

    width: 100%;
    background-color: #fff;
    border: ${props =>
      props.hideBorder ? 'none' : `1px solid ${colors.fieldBorder}`};

    padding: ${sizes.padding};
    color: ${colors.fieldColor};
    border-radius: ${sizes.borderRadius};

    &:disabled {
      background-color: #ebebe4;
    }

    &:focus {
      border: 1px solid ${colors.fieldFocus};
    }

    ${props =>
      props.readOnly &&
      css`
        background-color: #f5f5f5;
      `}

    ${props =>
      props.error &&
      css`
        border: 1px solid #992222;
      `}

    @media (max-width: 500px) {
      border: 0;

      &:focus {
        border: 0 !important;
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }

    @media (max-width: 500px) {
      background-color: transparent !important ;
      border: none !important;
    }
  }
`;

export const Title = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  width: ${props =>
    props.labelWidth ? `${props.labelWidth}px !important` : '130px'};
  min-width: ${props =>
    props.labelWidth ? `${props.labelWidth}px !important` : '130px'};
  color: ${colors.fieldLabelColor};
  border-bottom: ${props =>
    props.hideLine || (!isMobile && props.labelPosition === 'top')
      ? '0px'
      : `1px solid ${colors.fieldBorder}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${props => {
    if (!props.labelPosition) props.labelPosition = 'left';
    return !isMobile && props.labelPosition === 'left' ? '0' : '5px';
  }};

  @media (max-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.9rem;
  }

  .title-icon {
    margin-right: 5px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    & .required-field {
      all: inherit;
      justify-content: flex-start;
    }

    > span {
      width: auto;
      display: flex;
      align-items: center;
    }

    b {
      margin: 0 10px;
      color: #ff7777;
    }
  }
  .error {
    color: #ff7777;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 500px) {
      font-size: 0.8rem;
      padding: 0;
    }
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
    border-bottom: none;
    width: 100% !important;
    margin-bottom: 5px;
  }

  & > div {
    text-align: left;
  }
`;

export const Input = styled.input``;

export const PanelInput = styled.div`
  position: relative;
  flex: 1;
  border-radius: ${sizes.borderRadius};
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: ${sizes.borderRadius};

    border: ${props =>
      props.hideBorder ? 'none' : `1px solid ${colors.fieldBorder}`} !important;

    svg {
      margin: 5px;
    }

    input {
      border: none !important;
    }

    &:focus-within {
      border: 1px solid ${colors.fieldFocus} !important;

      input {
        border: none !important;
      }
    }

    &.disabled {
      background-color: #f5f5f5;
    }

    @media (max-width: 500px) {
      background-color: transparent !important ;
      border: none !important;
    }
  }

  .input-textarea-tooltip {
    max-height: 300px;
    max-width: 300px;
    overflow: hidden;
    white-space: pre-line;
  }
`;

export const IconButton = styled.button`
  svg {
    color: ${colors.fieldLabelColor} !important;
  }
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export const InputValueIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  margin-left: 5px;
`;

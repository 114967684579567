/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React, { useContext } from 'react';
import { deselectRow } from 'ka-table/actionCreators';
import { isMobile } from '~/easy-components/DeviceDetect';

import validateAndSelectRow from '../CustomActions/validateAndSelectRow';

import { DisplayCell, Cell, AppendButton, FakeInput } from '../styles';
import dynamicFunction from '../dynamicFunction';
import Icon from '../../Icon';
import { PageContext } from '../index';

function DisplayTimerCell({
  value,
  isModal,
  style,
  column,
  dispatch,
  rowData,
  rowKeyValue,
  onRefresh,
  cellProps,
  isFakeInput,
  readOnly,
  items,
  rawItems,
}) {
  function formatZerosInTime(hour, min) {
    const fullHour = hour.padStart(2, '0');
    const fullMinutes = min.padStart(2, '0');

    const strTime = `${fullHour}:${fullMinutes}`;

    return strTime;
  }

  function formatDateToTime(date) {
    const hour = date.getHours().toString();
    const minutes = date.getMinutes().toString();

    return formatZerosInTime(hour, minutes);
  }

  function intTimeToString(time) {
    if (time) {
      if (time instanceof Date) {
        return formatDateToTime(time);
      }

      const strValue = time.toString().padStart(4, '0');

      if (strValue.indexOf(':') !== -1) {
        const [hour, min] = time.split(':');

        return formatZerosInTime(hour, min);
      }

      const hour = strValue.slice(0, 2);

      const min = strValue.slice(-2);

      const newTime = `${hour}:${min}`;

      return newTime;
    }
    return null;
  }

  const { auxScope, setRowFieldValue, removeLine } = useContext(PageContext);

  const hasAppendButton =
    !isMobile &&
    column &&
    column.settings &&
    column.settings.appendButton &&
    column.settings.appendButton.icon &&
    intTimeToString(value);

  const appendButton = hasAppendButton ? column.settings.appendButton : {};
  // eslint-disable-next-line no-unused-vars
  const { onClick, icon, ...iconProps } = appendButton;

  const Content = () => (
    <>
      <span>{intTimeToString(value)}</span>
      {hasAppendButton && (
        <AppendButton
          onClick={async () => {
            if (appendButton && appendButton.onClick) {
              await dynamicFunction({
                functionString: appendButton.onClick,
                dispatch,
                params: {
                  ...auxScope,
                  column,
                  line: rowData,
                  items,
                  rawItems,
                  value,
                  rowKeyValue,
                  refresh: onRefresh,
                  setFieldValue: (columnKey, newValue, forceUpdate) => {
                    setRowFieldValue(
                      rowKeyValue,
                      columnKey,
                      newValue,
                      forceUpdate
                    );
                  },
                  setRowFieldValue,
                  selectRow: ({ force = false }) => {
                    dispatch(
                      validateAndSelectRow({
                        callingFromCell: cellProps,
                        forceSelection: force,
                      })
                    );
                  },
                  deselectRow: () => {
                    dispatch(deselectRow(rowKeyValue));
                  },
                  removeLine,
                },
              });
            }
          }}
        >
          <Icon name={appendButton.icon} {...iconProps} />
        </AppendButton>
      )}
    </>
  );

  return (
    <Cell align="center">
      <DisplayCell
        style={{
          justifyContent: isModal ? 'flex-start' : 'center',
          ...style,
        }}
      >
        {isFakeInput ? (
          <FakeInput
            readOnly={readOnly}
            style={{
              display: 'flex',
              justifyContent: isModal ? 'flex-start' : 'flex-end',
              ...style,
            }}
          >
            <Content />
          </FakeInput>
        ) : (
          <Content />
        )}
      </DisplayCell>
    </Cell>
  );
}

export default DisplayTimerCell;

import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #d9d9d9;
  gap: 20px;
  max-height: 68px;
  height: 100%;
`;

export const SearchInputContent = styled.input`
  width: 500px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 0 20px;
`;

import React, { useEffect, useState } from 'react';
import { MdOutlineWifiOff } from 'react-icons/md';
import useLocale from './useLocale';

const styles = {
  offline: {
    display: 'flex',
    flexDirection: ' column',
    justifyContent: ' center',
    alignItems: ' center',
    height: ' 100vh',
    gap: ' 42px',
  },
  connectionIcon: {
    animation: 'blink 1s infinite',
  },
  info: {
    fontSize: ' 20px',
  },
};

const keyframes = `
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}`;

export function useConnectionAssistant() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const t = useLocale('_Global');

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  function Offline() {
    return (
      <div style={styles.offline}>
        <p style={styles.info}>{t('ConnectionAssistant')}</p>
        <MdOutlineWifiOff size="64px" style={styles.connectionIcon} />
      </div>
    );
  }
  return { isOnline, Offline };
}

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

// @ts-check

import React, { createContext } from 'react';

/**
 * @callback CallEnterPageEvent
 * @param {Object} params
 * @param {import('@unform/core').FormHandles} params.formRef
 * @param {number} params.pageIndex
 * @returns {Promise<void>}
 */

/**
 * @typedef {Object} JsonPageWizardContext
 * @property {(functionString: string, params: object) => Promise<any>} executeFunction
 * @property {object[]} wizardPages
 * @property {CallEnterPageEvent} callEnterPageEvent
 */

/**
 * @typedef {Object} PageSetting
 * @property {string} name
 * @property {string} title
 * @property {"table" | "form" | "list"} type
 * @property {string} [icon]
 * @property {{name: string, run: 'before' | 'after', handler: string}[]} [events]
 * @property {object} [settings]
 * @property {object} [auxScope]
 */

/**
 * @typedef {Object} AddExtras
 * @property {Object} [auxScope]
 * @property {Object[]} [data]
 * @property {Object} [tableSettings]
 */

/**
 * @typedef {PageSetting & AddExtras} WizardPageSettings
 */

/** @type {JsonPageWizardContext} */
const defaultContext = {
  executeFunction: () => Promise.resolve(),
  wizardPages: [],
  callEnterPageEvent: () => Promise.resolve(),
};

export const JsonPageWizardContext = createContext(defaultContext);

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {function(string, object): Promise<any>} props.executeFunction
 * @param {CallEnterPageEvent} props.callEnterPageEvent
 * @param {object[]} props.wizardPages
 */
export const JsonPageWizardProvider = ({
  children,
  executeFunction,
  callEnterPageEvent,
  wizardPages,
}) => {
  return (
    <JsonPageWizardContext.Provider
      value={{
        executeFunction,
        wizardPages,
        callEnterPageEvent,
      }}
    >
      {children}
    </JsonPageWizardContext.Provider>
  );
};

import { useCallback, useEffect } from 'react';
import { getFunctions } from '~/easy-components/HandlerEvent/helpers';
import tableMountService from '~/applications/CRM/services/TableMountService';

export default function useOnLoadDataEvent({
  reportSettings,
  applicationsSettings: settings,
  reportData,
  params: eventParams,
  onChangeTableSettings = () => {},
}) {
  const sendEvent = useCallback(
    async ({ run = 'before', eventName, params, data, ...otherProps }) => {
      if (reportSettings && reportSettings.events) {
        const dynamicFunctions = getFunctions({
          events: reportSettings.events,
          settings,
          eventName,
          run,
        });

        let rawData = data;

        // eslint-disable-next-line no-restricted-syntax
        for (const dynamicFunction of dynamicFunctions) {
          const dynamicFunctionProps =
            settings && settings.dynamicFunctionProps;

          // eslint-disable-next-line no-await-in-loop
          const response = await dynamicFunction({
            ...dynamicFunctionProps,
            ...params,
            ...otherProps,
            data: rawData,
          });

          rawData = response || rawData;
        }

        return rawData;
      }

      return data;
    },
    [reportSettings, settings]
  );

  const onBeforeLoadData = useCallback(async () => {
    await sendEvent({
      eventName: 'onRender',
      run: 'before',
      data: reportData,
      params: {
        ...eventParams,
        applicationsSettings: settings,
        settings: reportSettings,
        setSettings: async newSettings => {
          const inputTableSettings = await tableMountService.execute({
            data: reportData,
            settings: newSettings,
          });

          onChangeTableSettings(inputTableSettings);
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, reportSettings, sendEvent]);

  useEffect(() => {
    if (!reportSettings || Object.keys(reportSettings).length === 0) {
      return;
    }

    onBeforeLoadData();
  }, [onBeforeLoadData, reportSettings]);
}

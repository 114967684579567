import React, { useContext } from 'react';
import DebounceEvent from '~/easy-components/DebounceEvent';
import { SearchContainer, SearchInputContent } from './styles';
import ButtonSelectedItems from '../ButtonSelectedItems';
import { ModalCatalogContext } from '../../context';
import ButtonFilters from '../ButtonFilters';

function HeaderInput({ t }) {
  const { onLoadData } = useContext(ModalCatalogContext);

  function handleSearch(e) {
    const filter = e.target.value;

    onLoadData(filter);
  }
  return (
    <SearchContainer>
      <ButtonFilters />
      <SearchInputContent
        type="text"
        placeholder={t('Search')}
        onChange={DebounceEvent(e => handleSearch(e))}
      />

      <ButtonSelectedItems />
    </SearchContainer>
  );
}

export default HeaderInput;

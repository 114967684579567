import React from 'react';
import { Container, Checkbox } from './styles';

/**
 * @callback OnFilterChange
 * @param {boolean} checked
 */

/**
 * @typedef {Object} FilterProps
 * @property {string} title
 * @property {boolean} isChecked
 * @property {OnFilterChange} onFilterChange
 */

/**
 * @param {FilterProps} props
 */
function Filter({ title, isChecked, onFilterChange }) {
  return (
    <Container>
      <Checkbox
        type="checkbox"
        checked={isChecked}
        onChange={e => {
          onFilterChange(e.currentTarget.checked);
        }}
      />

      <span>{title}</span>
    </Container>
  );
}

export default Filter;

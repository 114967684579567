export const createIntelisense = auxIntelisenses => {
  const intelisenses = [];

  // IAxiosConfig
  intelisenses.push(`
      interface IAxiosConfig {
        url: string,
        method: string
        baseURL: string,
        headers: object,
        params: object,
        data: object,
        timeout: number
        withCredentials: boolean
        auth: object,
        responseType: string
        responseEncoding: string
        maxContentLength: number,
        maxBodyLength: number,
        maxRedirects: number
        httpAgent: object,
        httpsAgent: object,
        proxy: object
      }
    `);

  // IAxios
  intelisenses.push(`
      interface IAxios {
         request(config:IAxiosConfig): Promise<any>,
         get(url:string, config:IAxiosConfig): Promise<any>,
         delete(url:string, config:IAxiosConfig): Promise<any>,
         head(url:string, config:IAxiosConfig): Promise<any>,
         options(url:string, config:IAxiosConfig): Promise<any>,
         post(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
         put(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
         patch(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
         postForm(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
         putForm(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
         patchForm(url:string, data:IAxiosConfig, config:IAxiosConfig): Promise<any>,
      };
    `);

  // IServiceLayerData
  intelisenses.push(`
       interface IServiceLayerData {
         url: string,
         data: object,
         method: "POST" | "GET" | "DELETE" | "PUT",
         header: ?object,
         page: number,
         size: number,
         timeout: number
       };
    `);

  // IShowModalCamera
  intelisenses.push(`
    interface IShowModalCameraReturn {
        image: string,
        file: File
       };
    `);

  // IShowModalMap
  intelisenses.push(`
    interface IShowModalMap {
        title: string,
        points: {
          origin: {
            latitude: number,
            longitude: number
          },
          destination: {
            latitude: number,
            longitude: number
          },
          waypoints: {
            latitude: number,
            longitude: number
          }[]
        }
       };
    `);

  // IEmailSettings
  intelisenses.push(`
  interface IEmailSettings {
    body: string,
    from: string,
    toEmail: string,
    copy: string,
    subject: string,
    formatters: object
  };
  `);

  // IShowModalEmailTemplates
  intelisenses.push(`
    interface IShowModalEmailTemplates {
        title:string,
        /**
         * Código do template de email.
         * Caso não seja informado o modal utilizará as configurações do emailSettings
        */
        template?: string,
        /**
         * Utilize para enviar as configurações do email caso não utilize o template
        */
        emailSettings?: IEmailSettings,
        /**
         * Dado utilizado para substituir as variáveis do template
        */
        data?: object,
        /**
         * Informe se deseja visualizar o modal antes do envio
        */
        preview?: boolean,
        onClose(): void,
        onSendEmail(): Promise<void>
       };
    `);

  // IShowModalEmailTemplates
  intelisenses.push(`
    interface IShowModalReportSettings {
        /**
         * Código do hash do relatório.
        */
        hash: string,
        data?: object,
        onClose(): void
       };
    `);

  // IShowModalEmailTemplates
  intelisenses.push(`
    interface IShowModalUserPageSettings {
        /**
         * Código do hash da página do usuário.
        */
        hash: string,

        /**
         * Dado para ser preenchido na página.
        */
        data?: object,
        onClose(): void
       };
    `);

  // IShowModalOnConfirmParam
  intelisenses.push(`
    interface IShowModalOnConfirmParam {
        code: string,
       };
    `);
  // IShowModalEditorSettings
  intelisenses.push(`
    interface IShowModalEditorSettings {
        title: string,
        code?: string,
        language: 'javascript' | 'sql' | 'html',
        /**
         * A função confirm recebe o objeto IShowModalOnConfirmParam
        */
        onConfirm(data: IShowModalOnConfirmParam): Promise<void>,
       };
    `);

  // IShowModalEditorSettings
  intelisenses.push(`
    interface IShowModalCatalogSettings {
        title: string,
        onLoadData: (filter: string) => Promise<any>,
        /**
         * A função confirm recebe o objeto IShowModalOnConfirmParam
        */
        onGetFilters: () => Promise<any>,
        onConfirm(data: IShowModalOnConfirmParam): Promise<void>,
       };
    `);

  // IShowModalCamera
  intelisenses.push(`
    interface IShowModalCamera {
        title: string,
        facingMode: 'environment' | 'user',
        onConfirm(data: IShowModalCameraReturn): Promise<void>
       };
    `);

  // IShowModalFields
  intelisenses.push(`
    interface IShowModalFields {
        title: string,
        data: ?object,
        fields: object[],
        settings: ?object,
        onOpen(): Promise<any>,
        onConfirm(data: object, formRef: object): Promise<any>,
        onClose(): Promise<any>,
        onValidate(data: object): Promise<any>,
        isShowClearFields: ?boolean,
        isShowConfirm: ?boolean,
        footer: ?object[],
        auxInfo: ?object,
        auxScope: ?object,
       };
    `);

  // IField
  intelisenses.push(`
  interface IField {
      setVisible(status: boolean): void,
      setReadOnly(status : boolean): void,
      setRequired(status : boolean): void
     };
  `);

  // IForm
  intelisenses.push(`
  interface IForm {
      getFieldRef(fieldName: string): IField,
      getFieldValue(fieldName: string): any,
      setFieldValue(fieldName: string, value: any): void,
      getData(): object,
      setData(data: object): void
     };
  `);

  // IConsole
  intelisenses.push(`
  interface IConsole {
      log(message: string): void,
      error(string): void,
      warm(string): void
     };
  `);

  // IToast
  intelisenses.push(`
  interface IToast {
    success(message: string),
    info(message: string),
    warn(message: string),
    error(message: string)
  }
  `);

  // IRequestSettings
  intelisenses.push(`
  interface IRequestSettings {
    url: string,
    method: string,
    data: object,
    largeData: object,
    timeout: number,
    file: File,
    eventAlias: string,
  }
  `);

  // Geolocation Return
  intelisenses.push(`
  interface IGeolocationReturn {
    latitude: number,
    longitude: number
  }
  `);

  // Geolocation Return
  intelisenses.push(`
  interface IJSON {
    parse(json: string): object,
    stringify(data:any): string
  }
  `);

  // INICIO SCOPE
  intelisenses.push(`
      interface IScope {
    `);

  // ShowPage
  intelisenses.push(`
      /**
       * Abre a página desejada
      */
      showPage(
        pageRoute:string, 
        externalData: object|number|string
      ): void;
    `);

  // ShowPage
  intelisenses.push(`
      /**
       * Abre o relatório desejado
      */
      showReport(
        reportCode:string, 
        reportData:object
      ): void;
    `);

  // showLoading
  intelisenses.push(`
    /**
     * Exibe a informação de carregando ou processando
    */
    showLoading(
      status:boolean
    ): void;
  `);

  // ExecuteQueryByCode
  intelisenses.push(`
    /**
     * Executa a consulta com base no seu código de consulta
    */
    executeQueryByCode(
      queryCode:string,
      data:object
    ): void;
  `);

  // ExecuteScalar
  intelisenses.push(`
    /**
     * Execute um comando sql que retornará a primeira linha de resposta como um objeto. 
     * Caso não tenha retorno, NÃO será retornado null, será retornado um objeto vazio {}
     */
      executeScalar(sql: string, dataToReplace: object): Promise<object>;
  `);

  // ExecuteQuery
  intelisenses.push(`
      /**
      * Execute um comando sql
      */
      executeQuery(sql: string, dataToReplace: object): Promise<object[]>;
  `);

  // ServiceLayer
  intelisenses.push(`
      /**
     * Execute um requisição para a service Layer
     */
      serviceLayer(data:IServiceLayerData): Promise<any>;
  `);

  // Form
  intelisenses.push(`
      /**
      * Formulário da página
      */
      form: IForm;
      
      /**
      * Formulário da princípal
      */
      mainForm: IForm;

      /**
      * Objeto do formulário (Deprecated - Utilize a função getData do form ou mainForm)
      */
      formData: object;

      /**
       * Objeto dinâmico
       */
       data: object;
   `);

  // Console
  intelisenses.push(`
      /**
      * Envie textos para o terminal de logs
      */
      console: IConsole;
   `);

  // Toast
  intelisenses.push(`
      /**
      * Envie mensagem para o popup de notificações
      */
      toast: IToast;
   `);

  // Requests Axios, ExternalApi, Request
  intelisenses.push(`
      /**
      * Manipule requisiçòes com Axios
      * Informações: https://axios-http.com/docs/api_intro
      * Em alguns casos podem ocorrer problemas de CORS, utilize a função externalApi nesses casos.
      */
      axios: IAxios;

      /**
      * Realiza chamadas externas no sistema utilizando servidor
      * Utilizado para evitor o problema de CORS entre sistemas
      */
      externalApi(settings: IAxiosConfig): Promise<any>;

      /**
      * Realiza chamadas para rotas internas do sistema
      * Utilize essa função para realizar chamadas dos métodos internos do sistema
      */
      request(settings: IRequestSettings): Promise<any>;
  `);

  // Errors
  intelisenses.push(`
      /**
      * Exibe a mensagem de erro
      */
      showError(errorMessage: string): void,

      /**
      * Trata a exception e exibe a mensagem de erro
      */
      treatError(error: object): void
  `);

  // Geolocation
  intelisenses.push(`
      /**
      * Obtém a Longitude e Latitude do usuário com base na geolocalização do navegador
      */
      getGeoLocation(): IGeolocationReturn
  `);

  // ShowModal
  intelisenses.push(`
    /**
     * Exibe o modal com os campos informados
     */
    showModalFields(settings:IShowModalFields),

    /**
     * Exibe o modal para captura de imagem
     */
    showModalCamera(settings:IShowModalCamera),

    /**
     * Exibe o modal do google maps
     */
    showModalMap(settings:IShowModalMap),

    /**
     * Exibe o modal de envio de emails
     */
    showModalEmailTemplates(settings:IShowModalEmailTemplates),

    /**
     * Exibe o modal com relatório
     */
    showModalReport(settings:IShowModalReportSettings),

    /**
     * Exibe o modal com a página do usuário
     */
    showModalUserPage(settings:IShowModalUserPageSettings)

    /**
     * Exibe o modal de edição para javascript, sql e htnl
     */
    showModalEditor(settings:IShowModalEditorSettings)

    /**
     * Exibe o modal para seleção de produtos
     */
    showModalCatalog(settings:IShowModalCatalogSettings)
  `);

  // Diversos
  intelisenses.push(`
      JSON: IJSON,

      /**
       * Trabalha o objeto de data com base na biblioteca date-fns
       * Veja mais em https://date-fns.org/docs/Getting-Started
       */
      dateFns: object,
      dateToString(date: Date, format: string): string,
      stringToDate(date: string): Date,
      stringToNumber(value:string): number,
      /**
       * Funçào de tradução padrão do sistema utiliza as traduçòes globais do sistema
       */
      t(name: string): string
      numberToString(value: number, type: 'integer' | 'quantity' | 'measure' | 'percent' | 'value' | 'price' | 'rate', digits?: number): string,
      fireEvent(field: IField, eventName: string): void,
  `);

  if (auxIntelisenses) {
    intelisenses.push(auxIntelisenses);
  }

  // FIM SCOPE
  intelisenses.push(` 
    }

    const scope:IScope;
  `);

  return intelisenses.join('\n');
};
